<script>
export default {
  name: 'BgSpiralCoralWeb',

  props: {
    w: {
      type: [Number, String],
      default: null,
    },
    h: {
      type: [Number, String],
      default: null,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g>
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M136,185.6c-0.3-0.6-0.6-1.3-0.9-1.9
    c-0.2-0.5-0.4-1-0.6-1.4c-0.3-0.6-1.2-2.2-2.1-3.1c3.5-1,6.8-2.3,9.8-3.7c0.2,0.4,0.6,1.1,1.1,2.1c0.2,0.4,0.4,0.7,0.6,1.1
    c0.6,1,0.9,2.3,1,3.2C142.3,183.4,139.3,184.6,136,185.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M114.5,189.6c-0.8-3.8-1.3-5.7-1.6-6.7
    c4.6-0.4,9.1-1.1,13.2-2c0.4,1.1,1.2,3.2,1.5,4.4c0.2,0.7,0.5,1.3,0.7,1.7c0.1,0.2,0.2,0.4,0.3,0.6
    C124.2,188.5,119.5,189.2,114.5,189.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M100.2,190.3c-0.1-0.6-0.5-2.4-0.5-3.1
    c0.1-0.7,0-1.6,0-2.4c0-0.4,0-0.7,0-1c0-0.1,0-0.2,0-0.4c2.2,0,4.4,0,6.6-0.1c0.1,0.7,0.3,1.4,0.3,1.7c0,0.2,0,0.4,0.1,0.7
    c0.1,0.4,0.2,1,0.3,2c0,0.7,0.1,1.3,0.2,1.8c0,0.2,0.1,0.5,0.1,0.7C104.9,190.2,102.5,190.3,100.2,190.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94,185.9c0,0.6,0.1,0.8,0.2,1.1
    c0.1,0.2,0.2,0.6,0.4,1.5c0.2,0.9,0.1,1.4,0.1,1.7c-1.3,0-2.6-0.1-3.9-0.2c-0.2-0.3-0.3-0.8-0.3-1.2c0-0.2,0-0.6,0-1
    c0-1-0.1-2.5,0-3.3c0-0.5,0-1-0.1-1.5c1.2,0.1,2.4,0.2,3.6,0.2C93.9,184,94,185.2,94,185.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.2,189.2c0.1-0.6,0.1-2.2,0.1-3.8
    c0-0.4,0-0.7,0-0.9c0-0.7,0.1-1.8,0.2-2.5c0.5,0.1,1.1,0.1,1.6,0.2c0.1,0.5,0.4,1.5,0.3,2.3c-0.1,0.9-0.1,3.6-0.1,4.9
    C81.6,189.4,80.9,189.3,80.2,189.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M79,189.1C79,189.1,79,189.1,79,189.1
    c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.3,0-0.6,0c-0.1,0-0.2,0-0.3,0c-0.8-0.1-1.5-0.2-2.3-0.3c-1-0.5-0.8-2.8-0.8-3.7
    c0-0.2,0-0.3,0-0.3v-3.2c0-0.1,0-0.1,0-0.2c0.4,0.1,0.9,0.1,1.3,0.2c0,0,0,0.1,0,0.1c0.2,1.7,0.2,3.4,0.2,5c0,0.8,1.6,0.6,1.6-0.1
    c0-0.8,0-1.7,0-2.5c0.3,0.1,1.4-0.1,1.4-0.5c0-0.5,0-1.1-0.1-1.6c0.3,0,0.6,0.1,0.9,0.1c-0.1,0.6-0.2,1.8-0.2,2.5
    c0,0.2,0,0.5,0,0.9c0,1.1,0,3.2-0.1,3.8C79.5,189.2,79.3,189.1,79,189.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.3,187.7c0-0.6-0.1-1.5-0.1-2
    c0-0.6,0.1-1.5,0.2-2.5l0.1-0.8c0-0.4,0.1-1.3,0.1-2c1,0.2,2,0.3,3,0.5c-0.1,0.5-0.2,1.5-0.2,2.4c0,0.7,0,1.4,0,2
    c0,0.4,0,0.8,0,1.1c0,0.5-0.3,1.3-0.5,1.7C71.1,188,70.2,187.9,69.3,187.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.2,185.3c0.1-1.3,0.4-3.9,0.5-4.6
    c0-0.5,0.4-1.5,0.8-2.2c0.7,0.2,1.5,0.3,2.2,0.4c-0.3,1.2-1,4.7-1,6c0,0.5-0.1,0.8-0.2,0.9C59.6,185.7,58.9,185.5,58.2,185.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M12.8,146.3c-0.3-0.5-0.5-1-0.8-1.4
    c1-0.2,5.7-1.3,6.7-1.5c0.3-0.1,0.8-0.2,0.9-0.6c0-0.4-0.4-0.6-0.7-0.5c-1.2,0.2-6.1,1.4-7.3,1.6c0,0-0.1-0.1-0.1-0.1
    c2.2-0.6,4.4-1,6.4-2c0.3-0.1,0.4-0.3,0.4-0.5c0.3,0.9,0.6,1.8,0.9,2.7c-0.6,0.3-2.6,1.1-4.7,2.1C13.7,146.3,13.2,146.4,12.8,146.3
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M30.5,49.6c-0.2-0.1-0.4-0.2-0.5-0.3
    c-0.3-0.2-0.6-0.4-1.6-0.7c-1-0.3-1.6-0.6-2-0.7c0.7-1.1,1.4-2.1,2.1-3.1c0.7,0.4,1.4,0.7,1.5,0.8c0.2,0.2,1.5,0.7,2.2,1l1.8,0.1
    c-0.9,1.2-1.7,2.4-2.6,3.6C31.2,50,30.9,49.7,30.5,49.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M35.1,37.8c0.4,0.7,2.6,2.6,3,3.4
    c0.2,0.5,1.7,0,1.6-0.4c-0.5-1-2.8-3-3.3-3.9c0.7-0.5,1.4-1,2.1-1.5c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0.4,0.4,0.7
    c0.1,0.2,0.3,0.5,0.5,0.8c0.3,0.5,0.9,1.7,1.2,2.3c-1.4,1.2-2.7,2.5-3.9,3.9c-0.5-0.5-1.2-1.1-1.6-1.6c-0.4-0.4-0.4-0.6-0.5-0.7
    c-0.1-0.2-0.2-0.4-0.5-0.6c-0.3-0.2-0.5-0.6-0.7-1C34,38.8,34.5,38.3,35.1,37.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M51.2,28.7l2.7,2.9c-1,0.3-1.9,0.7-2.7,1
    l-1.8-3.2C50,29.2,50.6,28.9,51.2,28.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M62.6,24.7c0.6,0.4,1.3,1,1.5,1.3
    c0.3,0.4,0.6,1.1,0.8,1.9c0.1,0.3,0.3,0.6,0.5,0.8c-1.6,0.3-3.1,0.6-4.5,0.9l-0.1-0.8L58.6,26C60,25.6,61.3,25.1,62.6,24.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M77.9,17.9c-0.1-1.1-0.3-2.7-0.6-4.2
    c-0.3-1.4-0.4-2.4-0.2-3.2C80.3,10.2,84,9.9,88,9.8c0.1,0.8,0.1,1.5,0.1,1.6c-0.2,0.3,0.2,4.3,0.8,6c-3.7,0.1-7.4,0.5-10.8,1.2
    C78,18.4,78,18.2,77.9,17.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M160.4,27.7c-0.1,0.3-0.2,0.5-0.4,0.7
    c-0.2,0.2-0.4,0.6-0.5,1c-0.7-0.5-1.4-1-2.1-1.5l0.6-1.3C158.8,26.9,159.6,27.3,160.4,27.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M176.8,39.1c0.7,0.7,1.3,1.5,2,2.2
    c-0.3,0.5-0.8,1.2-1.2,1.4c-0.4,0.1-0.6,0.3-0.7,0.3c-0.1,0.1-0.1,0.1-0.3,0.1c-0.3,0.1-0.9,0.4-1.4,0.6c-0.6-0.7-1.1-1.3-1.7-2
    c0.5-0.3,1.1-0.8,1.4-1.1C175.3,40.1,176.3,39.3,176.8,39.1L176.8,39.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M77.1,179.1c0.1-0.6,0.2-1.3,0.2-1.9
    c-0.1-0.6-0.1-1.7,0-2.5c2.6,0.5,5.2,0.9,8,1.2c0,0.7,0,1.6-0.2,2.3c-0.2,0.6,0,1.3,0.2,1.9C82.6,179.8,79.9,179.5,77.1,179.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.8,175.7c0.3-1,0.8-3.5,0.5-5
    c-0.1-0.5,0-0.8,0.2-1c0.1-0.1,0.1-0.1,0.2-0.2c2.8,1.1,5.7,2,8.6,2.9c0,0.5,0,1-0.2,1.4c-0.2,0.7-0.5,2.6-0.6,3.6
    C64.7,176.9,61.8,176.3,58.8,175.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M43.8,170.9c0.7-1.2,2.3-4,2.3-5.8
    c0-1.1,0.3-1.7,0.5-1.9c2.1,1.5,4.3,2.8,6.7,3.8c0,0,0,0.1,0,0.1c-0.5,2.3-0.9,3.8-1.2,4.2c-0.3,0.5-0.6,1.5-0.5,2.6
    C48.8,173.1,46.2,172.1,43.8,170.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M5.2,118.1c0.8,0.1,2,0.1,3.3-0.1
    c1-0.1,1.7-0.3,2.2-0.4c0.6-0.1,1.1-0.2,1.8-0.2c0.6,0,1.3-0.1,2-0.2c0,1.5,0.1,3,0.2,4.4c-1.4,0.4-3.8,1.1-5.1,1.2
    c-1.5,0.2-3.5,0.8-4.6,1.2C5,122.1,5,120.1,5.2,118.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M6.3,109.8c0.6-0.3,1.5-0.6,2.6-0.7
    c0.8,0,1.2-0.1,1.6-0.1c0.5,0,0.8-0.1,1.5,0c0.8,0,1.9,0,2.6,0c0,0.9-0.1,1.8-0.1,2.8c-1.8,0.4-6.2,1.2-6.6,1.2
    c-0.4,0-1.5,0.4-2.2,0.7C5.9,112.4,6.1,111.1,6.3,109.8C6.3,109.8,6.3,109.8,6.3,109.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M57.9,31.7c0.4,0.8,1.1,2.1,1.3,2.4
    c0.1,0.2,0.5,1.1,0.6,1.9c-2.3,0.9-4.5,1.8-6.5,2.8c-0.2-0.5-0.5-0.9-0.5-1.2c-0.2-0.5-1.9-2.1-2.8-3C52,33.6,54.7,32.6,57.9,31.7z
    "
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.8,19.9c0,0.4,0.2,1,0.4,1.5
    c0.4,1,0.5,2,0.7,2.9c0.1,0.6,0.2,1.9,0.2,2.6c-1.3,0.1-2.5,0.2-3.7,0.3c-0.2-0.1-0.5-0.3-0.7-0.5c-0.5-0.5-0.8-1-0.8-1.5
    c0-1.5-0.1-1.7-0.3-2.4c-0.2-0.5-0.6-1.2-0.8-1.6C75.4,20.6,77.1,20.2,78.8,19.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M91.4,18.8c0.1,0,0.2,0,0.4,0
    c0,0.2,0.1,0.5,0.2,0.9c0.3,1.1,0.4,1.9,0.4,2.5c0,0.2,0,0.3,0,0.4c0,0.2,0,0.4-0.1,1.2c-0.1,0.9-0.8,2.2-1.1,2.7
    c-1.6,0-3.2,0-4.8,0.1c-0.2-0.4-0.9-1.5-0.7-2.3c0.2-0.8,0.1-1.7,0-2.5c0-0.2-0.1-0.5-0.1-0.7c0-0.6-0.3-1.4-0.6-2
    C87.1,18.8,89.2,18.8,91.4,18.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.6,19.3c0,0.4,0.1,0.9,0.2,1.4
    c0.1,0.5,0.1,1.1,0.2,1.5c0.1,0.8,0,1.4-0.1,2.1c0,0.2-0.1,0.5-0.1,0.8c-0.1,0.8-0.3,1.4-0.4,1.7c0,0,0,0.1,0,0.1
    c-1.4-0.1-2.7-0.2-4.1-0.3c-0.2-0.4-0.4-1-0.3-1.3c0.3-0.8,0.4-2.5,0.4-3v-1.7c0,0,0-0.1,0-0.2c0-0.4,0-1-0.1-1.5
    C98.7,19.1,100.2,19.2,101.6,19.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M155.7,28.3c-0.5,0.8-1.4,2.2-2,2.8
    c-0.4,0.4-0.6,0.9-0.7,1.3c-1.8-1.2-3.7-2.2-5.6-3.1l0.6-5.5C150.6,25.2,153.2,26.7,155.7,28.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M171,146.9c-0.4-0.3-1.2-1-2-1
    c-0.5,0-0.8-0.3-1.1-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.9-1.2,1.7-2.3,2.4-3.5c0.4,0.2,1,0.4,1.6,0.5c0.6,0.1,1.2,0.3,1.6,0.5
    C173,143.8,172,145.3,171,146.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M167.6,151.7c-0.4-0.2-1.4-0.6-2.2-1.1
    c-0.5-0.3-1-0.5-1.3-0.7c1.2-1.4,2.4-2.9,3.6-4.4c0.3,0.3,0.7,0.6,1.3,0.7c0.7,0.1,1.5,0.7,1.8,1
    C169.8,148.7,168.7,150.2,167.6,151.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M161.8,159.1c-0.3,0.4-0.7,0.8-1,1.2
    c-0.8,0.9-1.6,1.7-2.4,2.5c-0.8,0.1-2.1,0.1-2.4-0.4c-0.3-0.5-1.5-1.8-2.2-2.6c1.9-1.5,3.7-3,5.4-4.7c0.3,0.4,0.7,0.8,1.2,1
    c0.7,0.3,1.4,1.1,1.8,1.5c0.1,0.2,0.2,0.3,0.3,0.3C162.4,158.2,162.2,158.6,161.8,159.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M151.1,168.8l-3.2,2.1c-1,0.6-2.1,1.2-3.1,1.7
    c-0.6-0.2-1.7-0.6-2.2-1.7c-0.4-1.1-1.1-2.3-1.5-3c0-0.1-0.1-0.1-0.1-0.2c2.6-1.2,5.1-2.6,7.4-4.1c0.1,0.4,0.3,0.8,0.8,1.1
    c1.1,1,2.2,3,2.5,3.6C151.5,168.5,151.3,168.7,151.1,168.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M124.4,179.4c-1.1-0.6-2.5-1.6-2.3-3
    c0.1-1,0.1-1.8,0-2.5c4.1-0.9,8-1.9,11.7-3.2c0.2,1.2,0.8,3.1,1.8,5.8C132.1,177.7,128.4,178.6,124.4,179.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.1,181.2
    C102.1,181.1,102.1,181.1,102.1,181.2c-0.3-0.5-0.3-1-0.1-1.8c0.4-1.3,0.5-2.4,0.4-3c1.8-0.1,3.8-0.2,5.7-0.4
    c1.7-0.1,3.3-0.3,4.9-0.5l1.5,5.3C110.5,181,106.4,181.2,102.1,181.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M93.9,180.9c-2.7-0.2-5.4-0.4-8.3-0.7
    c-0.2-0.5-0.4-1.2-0.2-1.9c0.2-0.7,0.2-1.6,0.2-2.3c2.4,0.3,5,0.4,7.7,0.5c0.2,1,0.5,2.4,0.6,2.7C94,179.7,94,180.3,93.9,180.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76.9,177.2c0.1,0.5,0,1.2-0.2,1.8
    c-2.9-0.4-5.8-0.9-8.9-1.5c0.1-1.1,0.4-3,0.6-3.6c0.1-0.4,0.2-0.9,0.2-1.4c2.6,0.8,5.4,1.5,8.2,2.1
    C76.8,175.4,76.8,176.5,76.9,177.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M59,170.8c0.3,1.5-0.3,3.9-0.5,4.8
    c-1.4-0.3-2.8-0.6-4.2-1c-0.8-0.2-1.6-0.4-2.4-0.6c0-1,0.2-2.1,0.5-2.5c0.6-0.8,1.1-3.5,1.3-4.4c0,0,0,0,0,0c0.5,0.2,1,0.4,1.5,0.6
    c1.4,0.6,2.8,1.1,4.2,1.6c0,0-0.1,0-0.1,0.1C59,169.8,58.9,170.2,59,170.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M37.9,167.4c0.2-1,0.7-2.3,1.7-3.1
    c1.5-1.4,2.9-3.7,3.2-4.2c1.2,1.1,2.4,2.1,3.6,3c-0.3,0.3-0.6,1-0.6,2.1c0,1.8-1.5,4.5-2.3,5.7C41.5,169.8,39.6,168.6,37.9,167.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M49.6,34.8c1.2,1.1,2.7,2.5,2.8,2.9
    c0.1,0.2,0.3,0.7,0.6,1.2c-0.5,0.2-1,0.5-1.4,0.8c-1.8,1-3.5,2.2-5.1,3.6c-0.2-0.4-0.5-0.9-1.2-1.6c-0.8-0.8-1.7-1.8-2.3-2.5
    C45.1,37.5,47.3,36.1,49.6,34.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76.3,28.4c0.7-0.1,1.3-0.1,1.9-0.2
    c0.4,0,0.7-0.1,1.1-0.1c0,0,0,0,0,0c0.4,0.3,0.6,1.6,0.8,2.4c-2.1,0.4-4.2,0.8-6.3,1.3l-0.3-3.1C74.4,28.6,75.3,28.5,76.3,28.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.2,24.2c-0.1-0.9-0.3-1.8-0.7-2.9
    c-0.2-0.6-0.3-1.1-0.3-1.5c1.8-0.3,3.6-0.6,5.4-0.7c0.2,0.6,0.5,1.4,0.6,2c0,0.2,0,0.5,0.1,0.7c0.1,0.8,0.2,1.6,0,2.4
    c-0.2,0.8,0.3,1.9,0.7,2.4c-1.9,0.1-3.7,0.1-5.5,0.3C80.4,26.1,80.3,24.8,80.2,24.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.3,22.2c0-0.5-0.1-1-0.2-1.6
    c-0.1-0.4-0.1-0.9-0.2-1.3c0.9,0.1,1.8,0.2,2.7,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,1.5-0.3,5.8-0.5,7.3c-0.1,0.7,1.5,0.4,1.6-0.2
    c0.2-1.4,0.7-5.8,0.5-7.2c0.7,0.1,1.5,0.2,2.2,0.3c-0.1,0.3-0.1,0.7-0.1,1.2c0,0.4,0,0.9,0,1.4c0,0.2,0,0.4,0,0.7
    c0,1-0.1,2.2-0.4,2.8c-0.2,0.5-0.4,0.9-0.4,1.2c-1.9-0.2-3.8-0.4-5.6-0.6c0,0,0,0,0,0c0.1-0.3,0.4-0.9,0.4-1.8c0-0.3,0-0.5,0.1-0.8
    C102.3,23.7,102.4,23,102.3,22.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M117.3,21.9c0,0.4,0,1.2-0.1,1.5
    c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.6-0.2,1.2c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.7-0.3,1.4-0.8,2.5c0,0,0,0.1-0.1,0.1
    c-1.7-0.3-3.4-0.6-5.2-0.9c0.3-0.5,0.5-1.2,0.6-2l0.1-0.5c0.1-0.8,0.1-0.8,0.1-1.5c0-0.4,0-0.8,0-1.2c0-0.4,0-0.9,0-1.4
    c0-0.3,0-0.5,0-0.7C113.4,21.1,115.3,21.5,117.3,21.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M120.8,25.5c0-0.3,0-0.6,0.1-0.9
    c0-0.4,0.1-0.8,0-1.1c0-0.2,0-0.5,0.1-0.8c0.9,0.2,1.8,0.4,2.8,0.7c0,0.8,0,2-0.1,2.5c-0.1,0.4-0.1,0.8-0.1,1.1
    c0,0.3,0,0.6-0.1,0.8c0,0.1-0.1,0.4-0.1,0.7c-0.1,0.6-0.3,1.5-0.6,1.8c0,0-0.1,0.1-0.1,0.1c-0.9-0.2-1.9-0.5-2.8-0.7
    c0.3-0.7,0.8-2.1,0.9-2.8c0.1-0.3,0.1-0.5,0.1-0.6C120.8,26,120.8,26,120.8,25.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M138.1,27.3c0.9,0.3,1.8,0.5,2.6,0.8
    c-0.2,0.7-0.4,1.4-0.5,1.6c-0.1,0.2-0.2,0.6-0.4,1.1c-0.2,0.5-0.3,1.1-0.5,1.5c-0.2,0.6-0.4,1.4-0.5,2.2c-0.1,0.5-0.2,1-0.3,1.3
    c-0.1,0.2-0.1,0.3-0.2,0.5c-0.9-0.4-1.8-0.9-2.7-1.3c0.3-0.3,0.6-0.7,0.7-1c0.4-0.8,0.6-1.5,0.8-2.6c0.1-0.5,0.2-0.9,0.3-1.3
    c0.1-0.4,0.3-0.9,0.3-1.3C137.8,28.3,138,27.7,138.1,27.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M143.6,29.1c0.8,0.3,1.6,0.6,2.3,1
    c-0.2,1-0.4,2-0.5,2.2c-0.1,0.2-0.2,0.5-0.3,1c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.6-0.5,1.5-1,2.1c-0.2,0.2-0.3,0.3-0.4,0.5
    c-0.2,0.3-0.5,0.7-1.2,1.4c0,0-0.1,0.1-0.1,0.1c-0.6-0.3-1.1-0.6-1.7-0.9c0.1-0.1,0.2-0.3,0.3-0.4c0.5-0.8,0.6-1.2,0.8-2l0-0.1
    c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.8,0.5-1.7,0.7-2.7c0.1-0.7,0.5-1.3,0.7-1.9C143.5,29.5,143.6,29.3,143.6,29.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M160.4,31.5c0,0.8-0.5,1.9-0.8,2.4
    c-0.3,0.4-1.2,1.6-1.7,2.2c-1.5-1.2-3-2.4-4.6-3.5c0.1-0.4,0.3-0.9,0.6-1.3c0.6-0.7,1.5-2.1,2-2.9
    C157.5,29.4,158.9,30.5,160.4,31.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M171.1,41C171.1,41,171.1,41,171.1,41
    c-0.1,1.1-1.3,2-1.7,2.2c-0.5,0.3-1.2,0.8-1.9,1.2c-0.3,0.2-0.6,0.3-0.8,0.5l0-0.1l-0.1,0.1c-1.2-1.5-2.5-2.9-3.9-4.3
    c0.3,0,0.6-0.1,0.7-0.2c0.2-0.3,2.1-2.6,3.1-3.8C168.1,37.9,169.6,39.4,171.1,41z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M183.3,59.4c-0.8,0.3-2.2,0.9-3.6,1.6
    c-1.4,0.7-2.1,0.8-2.4,0.7c-0.9-1.8-1.8-3.6-2.9-5.4c0.7-0.5,1.9-1.3,2.6-1.7c0.7-0.4,1.9-1.6,2.4-2.9
    C180.9,54.2,182.2,56.8,183.3,59.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M188.5,99c-0.3,0-0.6,0-1-0.1
    c-0.3,0-0.6-0.1-0.9-0.1c-0.5,0-1.1,0-1.5-0.1c0.1-1.5,0.2-3,0.2-4.4c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2
    c0.8-0.3,2-0.5,2.8-0.5C188.9,95.4,188.7,97.2,188.5,99z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M186.9,109.2c-0.4,0.5-1.1,0.9-1.7,0.8
    c-0.6-0.1-1.2,0-1.6,0.3c0.4-2,0.7-4,1-5.9c0.2,0,0.4,0,0.6,0c0.9,0,1.9,0.1,2.4,0.2c0.1,0,0.1,0,0.1,0
    C187.5,106.1,187.2,107.6,186.9,109.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M183.8,120.6c-0.4,0.1-1,0.1-1.6,0
    c-0.5-0.2-0.9-0.3-1.2-0.3c0.6-1.9,1.2-3.7,1.6-5.5c0.5-0.1,1.1-0.1,1.7,0c0.5,0.1,1,0.2,1.3,0.3
    C185,116.8,184.4,118.7,183.8,120.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M180.1,130.1c-0.4-0.1-1-0.3-1.5-0.5
    c-0.5-0.2-0.9-0.3-1.3-0.4c0.7-1.4,1.3-2.8,1.8-4.2c0.3,0.1,0.6,0.1,1,0.2c0.5,0.1,1.1,0.6,1.6,0.9
    C181.3,127.5,180.7,128.8,180.1,130.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M174.1,141.9c-0.5-0.2-1.2-0.4-1.8-0.5
    c-0.5-0.1-1-0.3-1.4-0.5c1.4-2.2,2.7-4.3,3.9-6.5c0.4,1,0.8,1.6,1.1,1.6c0.3,0.1,0.7,0.4,1,0.6C176,138.5,175.1,140.2,174.1,141.9z
    "
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M121.6,165.4c1.2,1.6,1.6,3.4,1.9,5.4
    c-0.2,0-0.5,0.1-0.7,0.1c-0.5-2.5-1.4-5-2.3-7.4c0,0,0,0,0,0c0.5-0.2,0.8-0.4,1.1-0.5c0.6-0.2,1.2-0.4,1.9-0.6
    c0,0.2,0.1,0.5,0.4,1.2c0.3,0.6,0.5,0.8,0.7,1c0.3,0.3,0.5,0.5,0.7,1.6c0.2,1.1,0.2,1.3,0.3,1.5c0,0.2,0,0.4,0.2,1.3
    c0.2,0.8,0,1.1-0.1,1.2c0,0,0,0.1,0,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.3-2-0.8-4-2-5.7C122.7,164.2,121.2,164.8,121.6,165.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M18,96.2c1.2-0.3,2.9-0.7,3.8-0.4
    c1.1,0.3,2.2,0.5,2.8,0.5c-0.5,2.3-1,4.4-1.4,6.6c-0.4,0-0.9,0.1-1.4,0.1c-0.9,0-3.3-0.3-4.5-0.5C17.4,100.3,17.7,98.2,18,96.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M48.8,45.1l9.7,7.1c-2.1,1.7-4,3.3-5.6,4.9
    c-1.6,0.4-6.3,0.9-7.1,0c-0.8-0.8-3.2-2.8-4.5-3.9C43.5,50,46,47.3,48.8,45.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M88.9,27.8l-0.1,1.5c-2.8,0.3-5.6,0.7-8.5,1.3
    c-0.1-0.7-0.3-1.8-0.7-2.4C82.6,27.9,85.7,27.8,88.9,27.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M119.5,31L119.5,31c-3.3-0.7-6.3-1.2-9-1.6
    l0-0.2C113.5,29.7,116.5,30.3,119.5,31z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M132.5,25.7c0.3,0.1,0.5,0.2,0.8,0.2
    c-0.4,2.5-1.1,4.9-2.5,7c-0.2-0.1-0.4-0.1-0.6-0.2c1.2-2.1,2.5-4.4,2.4-6.9C132.6,25.8,132.5,25.8,132.5,25.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M144,41.8c-0.2,1.5-0.8,5-1.8,6.1
    c-0.9,1-1.9,2.8-2.4,3.8c-2.3-1-4.8-1.9-7.5-2.7c0.3-1.4,1.2-5.5,1.9-6.4c0.6-0.9,1.5-3.5,2-4.9C138.8,38.9,141.4,40.2,144,41.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M151.9,33.3c0.6,0.4,1.2,0.8,1.8,1.2
    c-0.1,0.2-0.4,0.8-0.6,1.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.3-0.4,0.6-0.4,1c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.4-0.1,0.9-0.3,1.7
    c-0.4,1.1-0.6,1.7-1,2.4c-0.2,0.4-0.9,0.7-1.3,1c-0.4-0.3-0.8-0.6-1.2-0.9c0,0,0,0,0,0c0.1-0.2,0.5-0.9,0.9-1.6
    c0.2-0.4,0.4-0.8,0.5-0.9c0.2-0.3,0.3-0.8,0.4-1.3c0.1-0.5,0.3-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.5,0.4-0.8,0.7-1.7
    C151.7,34.2,151.8,33.7,151.9,33.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M158.1,140c0,0,2,1.3,3,2.3
    c0.7,0.6,1.4,1,2.1,1.3c0.5,0.2,1,0.4,1.5,0.8c0.7,0.4,1,1,1,1.3c0,0,0,0.1,0,0.1c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0-0.3,0-0.4,0
    c-0.2-0.1-1.7-0.7-2.5-1.4c-0.6-0.5-1.1-0.8-1.6-1.1c-0.3-0.2-0.6-0.3-0.9-0.6c-0.7-0.4-1.3-0.8-2-1.1c-0.3-0.1-0.5-0.3-0.8-0.4
    c-0.3-0.2-0.5-0.3-0.7-0.5c0.7-0.7,1.3-1.4,1.9-2.1L158.1,140L158.1,140z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M156,156.1c-0.1-0.3-0.3-0.6-0.4-0.9
    c-0.2-0.4-0.4-0.9-0.6-1.2c-0.3-0.5-0.8-1-1.4-1.6c-0.5-0.5-1.1-1-1.7-1.8c-0.2-0.3-0.4-0.5-0.6-0.6c-0.8-1-0.8-1-1.9-1.7l-0.1,0
    c0.3-0.3,0.6-0.5,0.9-0.8c0.1,0.1,0.1,0.2,0.3,0.2c0.2,0.1,0.4,0.2,0.5,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.3,0.4,0.6,0.9,1.7,1.8
    c0.9,0.8,1.6,1.1,2.3,1.3c0.4,0.2,0.8,0.3,1.2,0.6c0.9,0.6,1.6,1.1,2,1.5C157.7,154.6,156.9,155.4,156,156.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M153.4,158.2c-0.6,0.4-1.2,0.9-1.8,1.3
    c-0.5-0.2-1.2-0.5-2.1-1.3c-0.8-0.6-0.8-0.8-0.9-1.2c-0.1-0.3-0.1-0.7-0.6-1.5c-0.5-0.9-0.7-1.2-1-1.4c-0.2-0.3-0.5-0.6-1-1.6
    c-0.3-0.5-0.5-0.9-0.8-1.1c0.6-0.4,1.1-0.8,1.6-1.2c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.8,0.5,1.3,1.1c0.6,0.7,0.6,0.7,0.8,1
    c0.1,0.2,0.4,0.5,1,1.3c0.7,1,1,1.2,1.2,1.4c0.2,0.2,0.3,0.2,0.6,0.9c0.1,0.2,0.3,0.5,0.4,0.7C153,157.3,153.3,157.9,153.4,158.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M145,157.2c0.4,0.9,0.8,1.6,1.3,2.2
    c0.4,0.5,0.7,1.1,1.1,1.8c0.2,0.3,0.2,0.6,0.3,0.8c-0.8,0.5-1.6,0.9-2.4,1.3c-0.2-0.1-0.4-0.2-0.6-0.4c-0.3-0.4-0.3-0.7-0.4-1.1
    c-0.1-0.5-0.2-1.2-0.7-2.4c-0.5-1.1-0.9-1.7-1.2-2.2c-0.3-0.5-0.6-1-1.1-2c-0.1-0.3-0.3-0.6-0.4-1c0.6-0.3,1.1-0.7,1.7-1
    c0.4,0.3,0.9,0.7,1.5,1.4c0.8,1,0.8,1.1,0.8,1.3C144.7,156.2,144.7,156.5,145,157.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M138.9,155.6c0.2,0.3,0.5,0.7,0.6,1.2
    c0.1,0.7,0.4,1.2,0.7,1.7c0.2,0.3,0.4,0.7,0.6,1.2c0.4,0.9,0.8,1.5,1.2,2.2c0.2,0.3,0.4,0.7,0.7,1.1c0.3,0.5,0.4,1,0.3,1.5
    c-0.8,0.4-1.7,0.8-2.6,1.2c-0.2-0.1-0.3-0.2-0.5-0.4c-0.4-0.5-0.4-0.7-0.5-1.2c0-0.4,0-0.8-0.3-1.6c-0.3-0.9-0.4-1.4-0.6-2
    c-0.2-0.5-0.4-1.1-0.7-2.1c-0.4-1.2-0.5-1.7-0.7-1.9C137.7,156.2,138.3,155.8,138.9,155.6C138.8,155.6,138.8,155.6,138.9,155.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M134.1,168c-0.1-0.3-0.2-0.8-0.4-1.4
    c-0.4-1.3-0.6-1.7-0.7-1.9c-0.2-0.3-0.2-0.5-0.4-1.8c-0.2-1.2-0.6-2.1-0.9-2.7c-0.2-0.4-0.3-0.7-0.3-0.8c0.4-0.2,0.8-0.4,1.3-0.6
    c0,0,0,0.1,0,0.1c0,0.3-0.1,0.6,0.3,1.6c0.3,0.8,0.4,1.3,0.5,1.7c0.1,0.5,0.2,0.9,0.6,1.7c0.3,0.7,0.2,0.9,0.2,1.1
    c0,0.3,0,0.6,0.5,1.4c0.3,0.5,0.5,1,0.5,1.3C134.8,167.8,134.4,167.9,134.1,168z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M131.3,168.9c0-0.2-0.1-0.4-0.1-0.8
    c0-0.5,0-1-0.1-1.6c-0.2-1.3-0.3-1.4-0.6-2.1c-0.1-0.2-0.2-0.5-0.4-0.9c-0.3-0.9-0.4-1.2-0.5-1.5c0-0.3-0.1-0.6-0.3-1.2
    c-0.1-0.2-0.1-0.3-0.1-0.5c0.6-0.2,1.1-0.5,1.7-0.7c0,0.2,0.2,0.5,0.3,0.8c0.3,0.6,0.7,1.4,0.9,2.6c0.2,1.4,0.3,1.6,0.5,1.9
    c0.1,0.3,0.3,0.7,0.7,1.9c0.2,0.6,0.3,1.1,0.4,1.4C132.9,168.4,132.1,168.6,131.3,168.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M126.1,161.9c0.4,0.4,0.8,1,0.8,1.3
    c0,0.5,0.2,0.8,0.4,1.1c0.2,0.2,0.3,0.5,0.5,0.9c0.2,0.5,0.4,1.1,0.6,1.7c0.2,0.6,0.5,1.4,0.9,2.4c0,0.1,0,0.1,0.1,0.2
    c-1.1,0.3-2.1,0.5-3.2,0.8c0.1-0.2,0.2-0.6,0-1.3c-0.2-0.8-0.2-1-0.2-1.2c0-0.3,0-0.5-0.3-1.6c-0.3-1.3-0.5-1.5-0.8-1.8
    c-0.2-0.2-0.4-0.4-0.6-0.9c-0.4-0.8-0.4-1-0.4-1.1c0,0,0,0,0,0c0.7-0.2,1.3-0.4,2-0.7C125.9,161.7,126,161.8,126.1,161.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M109.2,171.7c-0.1-0.4-0.2-0.8-0.2-1.3
    c-0.2-1-0.2-1.1-0.2-1.6c0-0.2,0-0.3,0-0.6c0-0.9-0.2-1.9-0.3-2.5c0.9-0.1,1.9-0.2,2.8-0.4c0.2,0.7,0.4,1.6,0.5,2l0.1,0.3
    c0.2,0.7,0.5,1.8,0.5,2.5c0,0.4,0.1,1.1,0.2,1.6c0,0.2,0.1,0.5,0.1,0.7c-1.1,0.1-2.1,0.2-3.2,0.3
    C109.3,172.4,109.2,172.1,109.2,171.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M103.8,173.2c0-0.2,0-0.5,0.1-0.8
    c0-0.5,0.1-1.1,0.1-1.9c0.1-1.1-0.1-1.7-0.2-2.3c-0.1-0.3-0.1-0.6-0.2-1c-0.1-0.5,0-0.8,0-1.1c0,0,0,0,0,0c0.7,0,1.3-0.1,2-0.1
    c0.1,0.6,0.4,2.3,0.5,2.9c0,0.3,0.1,0.6,0.2,1.1c0.1,0.7,0.3,1.6,0.3,2c0,0.3,0,0.7,0.1,1.1C105.8,173.1,104.8,173.1,103.8,173.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.3,173.3c-1.6,0.1-3.2,0.1-4.8,0.2
    c0-0.1,0-0.2,0-0.3c0-0.4,0.1-0.9,0.1-1.4c0.1-1.4,0-3.9-0.1-5.4c1.3,0,2.6-0.1,4-0.2c0.2,0,0.4,0,0.6,0c-0.1,0.2,0,0.8,0.1,2
    c0.1,1.1,0.2,2.4,0.2,3.5C101.4,172.3,101.3,172.9,101.3,173.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M90.8,173.5c-1.1,0-2.3-0.1-3.4-0.1
    c0.1-0.8,0.4-1.8,0.6-2.7c0.3-1,0.5-1.9,0.6-2.5c0.1-0.9,0-1.7-0.1-2.2c1.1,0.1,2.2,0.3,3.3,0.3c-0.1,0.3-0.1,0.9-0.2,1.7
    c-0.1,0.9-0.1,1.8-0.3,2.5c-0.1,0.4-0.1,0.8-0.2,1.1C91,172.3,90.9,173,90.8,173.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M84.4,173.3c-1.1-0.1-2.2-0.2-3.3-0.3l0.5-2.5
    l1.7-5.5c0.8,0.2,1.7,0.4,2.6,0.6c-0.2,0.6-0.8,2.5-1,3.8c-0.1,0.4-0.1,0.9-0.1,1.4C84.6,171.6,84.6,172.6,84.4,173.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M60.4,168.3c0.3-1.1,1.3-4.3,1.5-4.7
    c0.1-0.1,0.6-0.6,1-1.1c1.4-1.4,3.4-3.4,3.8-4.8c1.3,0.9,2.7,1.7,4.1,2.4c-0.9,1.6-3.1,6-3.6,6.8c-0.4,0.7-1.3,2.3-2.1,2.8
    C63.5,169.3,61.9,168.8,60.4,168.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M41.2,156.6c1.2-1.3,4-4.3,6-5.7
    c2.1-1.5,4.4-4.8,5.3-6.2c2,2.5,4.4,5,6.9,7.3l-8,10.1c-0.4,0.3-1.3,1-2.1,1.2C46.3,161.5,43.7,159.3,41.2,156.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M28.4,134.6c1.8-1,5.8-3.3,7.7-4.3
    c1.7-0.9,4.5-3.1,5.8-4.4c0.3,0.7,0.7,1.4,1.1,2.2c0.6,1.9,1.5,3.8,2.5,5.9c-0.2,0.1-0.4,0.3-0.7,0.6c-1.4,1.1-3.9,3.1-7.1,5
    c-2.9,1.8-4.7,2.7-5.7,3.2C30.7,140.2,29.6,137.5,28.4,134.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M120.2,31.1c-0.1,0-0.2,0-0.3-0.1l0,0
    C120,31.1,120.1,31.1,120.2,31.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M135.9,37.6c-0.4,1.3-1.3,4-1.9,4.8
    c-0.7,1-1.6,5-2,6.5c-2.8-0.9-5.8-1.7-8.8-2.4l1.7-12.9C128.4,34.6,132.1,35.9,135.9,37.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M156.8,51.6c1.7,1.8,3.2,3.6,4.6,5.5
    c-1.1,0-2.6,0.5-3.4,1.3c-1,0.9-4.1,2.5-5,2.9c-0.8-1.1-1.6-2.1-2.4-3c-0.5-0.6-1.1-1.1-1.8-1.6c0.4-0.8,1.1-2.2,2.1-3.2
    c1.1-1.1,2.7-3,3.5-4C155.2,50.1,156,50.8,156.8,51.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M155.5,35.8c0,0.9-0.1,1.9-0.1,2.1
    c-0.1,0.4-0.4,0.9-0.6,1.2c-0.2,0.2-0.4,0.8-0.6,1.3c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.2,0.7-0.4,1c-0.1,0.5-0.3,0.9-0.5,1.2
    c-0.3,0.4-0.8,1.2-1.3,1.6c-0.5-0.5-1.1-0.9-1.6-1.4c-0.1-0.1-0.2-0.1-0.3-0.2c0.5-0.2,1.1-0.6,1.3-1c0.5-0.8,0.7-1.3,1.1-2.5
    c0.3-0.8,0.3-1.3,0.4-1.7c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.2-0.6,0.4-0.9c0.1-0.2,0.3-0.5,0.3-0.7c0.1-0.4,0.4-0.9,0.5-1.1
    C154.5,35,155,35.4,155.5,35.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M159.9,39.6c0.1,0.1,0.2,0.1,0.2,0.2
    c0,0.1,0,0.2,0,0.4c0,0.6-0.3,0.8-0.6,1.1c-0.1,0.1-0.3,0.3-0.4,0.5c-0.4,0.5-0.7,1.3-0.9,1.8c-0.1,0.2-0.1,0.3-0.2,0.4
    c-0.1,0.3-1.1,2-1.4,2.6c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.5-0.5,1-0.7,1.3c-0.6-0.6-1.2-1.2-1.9-1.8c0.6-0.4,1.8-1.3,2.1-1.9
    c0.2-0.4,0.3-0.6,0.5-0.8c0.2-0.3,0.4-0.5,0.8-1.1C157.4,42,159.1,40.4,159.9,39.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M163.6,43.4c-0.1,0.4-0.3,0.6-0.5,0.8
    c-0.2,0.2-0.5,0.6-0.8,1c-0.2,0.2-0.4,0.6-0.4,0.6c-0.1,0.1-0.2,0.3-0.5,0.7c-0.2,0.4-0.6,1-0.8,1.2c-0.4,0.3-0.7,0.8-1,1.3
    c-0.2,0.3-0.3,0.6-0.4,0.7c-0.1,0.1-0.2,0.2-0.3,0.4c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.5-0.5-0.7-0.7
    c0.3-0.2,0.7-0.7,1.6-1.9c0.4-0.6,0.6-0.8,0.7-1c0.2-0.2,0.3-0.4,0.7-1c0.8-1.1,1.2-1.6,1.4-2.2c0.2-0.4,0.6-1.2,0.9-1.8
    C162.8,42.5,163.2,43,163.6,43.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M171.2,53.4c0,0.3-0.1,0.6-0.3,0.9
    c-0.2,0.4-0.3,0.5-0.4,0.6c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.7,0.5-1.1,0.7c-0.3,0.2-0.5,0.3-0.7,0.4
    c-0.2,0.1-0.5,0.5-0.9,0.9c-0.4,0.4-0.8,0.8-1,0.9c-0.3,0.2-0.7,0.8-1.1,1.3c-0.2-0.2-0.4-0.5-0.6-0.7c0.2-0.1,0.3-0.2,0.5-0.4
    l0.2-0.2c0.5-0.6,0.7-0.9,1.6-1.7c0.4-0.3,0.6-0.5,0.8-0.7c0.3-0.3,0.5-0.4,1-1c0.5-0.5,1.3-1.2,1.8-1.6
    C170.9,53,171,53.2,171.2,53.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M184,96c-0.2,0.1-0.5,0.2-0.8,0.2
    c-0.4,0-0.8,0.1-1.1,0.2c-0.4,0.1-0.7,0.2-1.1,0.2c-0.6,0.1-1.3,0.2-2.1,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-1.1,0.3-1.4,0.2-3.1-0.5
    l-0.1,0c0.1-0.7,0.2-1.5,0.3-2.2c0.5,0,1.4,0,2.9,0l0.9,0c0.9,0,0.9,0,1.3-0.1c0.1,0,0.2-0.1,0.5-0.1c0.9-0.3,2-0.7,3.1-1.4
    c0.1-0.1,0.2-0.1,0.3-0.1C184,93.8,184,94.9,184,96z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M177.2,101.5c0.8-0.1,1.2-0.2,1.5-0.2
    c0.4,0,0.7,0,1.3-0.2c0.6-0.1,1.1-0.2,1.5-0.3c0.5-0.1,1-0.1,1.5-0.3c0.3-0.1,0.5-0.2,0.7-0.3c-0.1,1.5-0.3,3-0.5,4.5
    c-0.3,0.2-0.7,0.4-1.1,0.6c-0.6,0.3-1.2,0.3-2.1,0.5c-0.3,0-0.6,0.1-1,0.1c-1.3,0.2-2.1,0-3.3-0.3c-0.3-0.1-0.6-0.2-1-0.3
    c-0.4-0.1-0.7-0.2-0.9-0.2c-0.2-0.1-0.4-0.1-0.5-0.1c0-0.1,0.1-0.2,0.1-0.3c3.2,0.2,6.3-0.3,9.3-1.4c1-0.4,0.1-1.1-0.7-0.8
    c-2.7,1-5.6,1.4-8.4,1.2c0.1-0.6,0.3-1.3,0.4-1.9C174.7,101.7,176.1,101.7,177.2,101.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M179.1,121.5c-0.4,0.1-1.2,0.3-2.9,0.2
    c-1.7-0.1-1.9-0.2-2.2-0.3c-0.2-0.1-0.5-0.2-1.4-0.3c-0.7-0.1-1.2-0.2-1.6-0.2c-0.6-0.1-1-0.2-2.2-0.2c0,0,0,0,0,0
    c0.3-1,0.6-2,0.9-3.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.5,0c0.8,0,2,0,3.1-0.1c0.8-0.1,1.8,0,2.7,0.1c0.6,0,1.2,0.1,1.7,0.1
    c0.9,0,1.8,0.4,2.2,0.6C179.8,119.4,179.5,120.5,179.1,121.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M175.2,130.7c-0.2,0-0.5-0.2-1-0.3
    c-0.3-0.1-0.6-0.3-1-0.4c-0.9-0.3-1.4-0.5-1.9-0.7c-0.4-0.1-0.7-0.3-1.3-0.5c-0.6-0.2-1-0.3-1.4-0.4c-0.6-0.2-1.2-0.3-2.1-0.8
    c-0.1-0.1-0.2-0.1-0.3-0.2c0.4-0.9,0.8-1.8,1.2-2.7c0.1,0,0.2,0.1,0.3,0.1c0.8,0.3,1.7,0.6,2.5,1.1c1.1,0.7,1.4,0.7,1.9,0.7
    c0.2,0,0.5,0,1,0.1c1.4,0.3,3,1.1,3.5,1.4C176.1,129,175.7,129.9,175.2,130.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M171.9,136.9c-0.3,0.1-0.7-0.1-1.3-0.4
    c-0.3-0.1-0.5-0.3-0.9-0.4c-0.5-0.2-0.8-0.3-1.1-0.4c-0.4-0.1-0.8-0.2-1.7-0.9c-1.3-0.9-1.6-1.2-2.2-2c-0.1-0.1-0.2-0.3-0.4-0.5
    c-0.2-0.2-0.4-0.3-0.5-0.4c0.2-0.4,0.5-0.8,0.7-1.2c0.5,0.4,1.4,1.1,2.4,1.4c0.9,0.3,1.2,0.4,1.8,0.5c0.3,0.1,0.7,0.2,1.4,0.3
    c1.4,0.4,2.6,1.3,3,1.8C172.7,135.5,172.3,136.2,171.9,136.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M139.3,153.1c-0.2-0.4-0.5-0.8-0.8-1.2
    c-0.4-0.5-0.8-1.1-0.9-1.5c-0.3-1-0.9-2-1.3-2.5c2.4-1.2,4.5-2.5,6.1-3.8c0,0,0.1-0.1,0.1-0.1c0.7,0.5,1.5,1.9,2,2.9
    c0.5,0.9,0.6,1.1,0.8,1.1c0,0,0,0,0,0c0.1,0,0.4,0.4,0.7,0.9C143.9,150.3,141.6,151.8,139.3,153.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M123.7,160.1c0-0.8-0.5-1.8-0.8-2.4
    c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.5-1-2.9-1.4-4c2-0.5,4.1-1.1,6-1.7c0.7,1,1.9,3.6,3.2,6.1C128.2,158.5,126,159.3,123.7,160.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.7,164.7c0.5-1,0.1-2.3,0-2.5l0.6-5.8
    c2.4-0.3,4.9-0.6,7.4-1.1c0.2,0.5,0.4,1.4,0.7,2.6c0.2,1,1,3.8,1.4,5.1C109.6,163.8,106.2,164.3,102.7,164.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M84.9,163.3c0.4-0.8,1.3-2.7,3-7.4
    c2.3,0.6,4.8,1,7.7,1c0.5,1-0.7,3.4-1.2,4.3l-0.4,3.6C90.7,164.6,87.7,164.1,84.9,163.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M57.3,148.2c1.3,1,2.7,2.1,4.4,3.4
    c5.8,4.3,10.6,7.7,15.6,10.1l0,0C69.3,158.6,62.6,153.7,57.3,148.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M39.8,115.6c-0.4-2.5-0.5-4.9-0.5-7.3
    c0.2,3.6,0.6,7.2,1.3,10.6c-0.3-1.1-0.5-2.1-0.7-3C39.9,115.8,39.9,115.6,39.8,115.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M23.7,117.2c3.1-1.2,11.5-2.8,14.7-3.2
    c0.8-0.1,1.3-1,0.2-0.9c-3.4,0.4-11.8,2.1-15,3.2c-0.1-1-0.1-2-0.2-3c1.4-0.1,4.7-0.3,7.4-0.7c3-0.5,6.4-0.5,7.2-0.5
    c0.1,1,0.2,2,0.3,3.1c-5.1-0.4-9.8,1.5-14.5,3.1C23.8,118,23.8,117.6,23.7,117.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M24.6,102.5c1.7,0,7.3,0,8.5,0.3
    c1.1,0.2,3.5-1.3,4.7-2.5c0.1-0.1,0.1-0.1,0.2-0.2c0.1,2,0,3.8,0,5.5c0,1,0,2,0,3c-1.7,0-7.3,0.1-9.7,0.3c-1.9,0.2-3.8-0.4-4.6-1
    C23.9,106.1,24.2,104.3,24.6,102.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.8,53.8l0.4,0.7c-0.4,0.3-0.9,0.6-1.3,1
    c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.3-0.3-0.5-0.5c0.4-0.4,0.9-0.7,1.3-1.1L58.8,53.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M61.4,52.6c0,0,0-0.1-0.1-0.1
    c0-0.1-0.1-0.3-0.3-0.4c0,0,0-0.1-0.1-0.1c0.4-0.3,0.8-0.6,1.2-0.9c0.2,0.2,0.3,0.3,0.3,0.4c0,0.1,0,0.2,0.1,0.4
    c0,0.2,0.1,0.3,0.1,0.5c-0.4,0.2-0.7,0.5-1.1,0.7C61.5,52.9,61.5,52.7,61.4,52.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M64.9,49.9c-0.1-0.1-0.1-0.1-0.1-0.2
    c-0.1-0.1-0.1-0.2-0.3-0.3c0.4-0.2,0.7-0.5,1.1-0.7l0.2,0.2l0.1,0.4l0.5,1c-0.4,0.2-0.8,0.4-1.3,0.7C65.2,50.6,65.1,50.1,64.9,49.9
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.8,46.5l0.3,0.9l0.6,0.8
    c-0.7,0.3-1.3,0.6-2,0.9c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.3-0.3-0.7c-0.1-0.1-0.1-0.2-0.2-0.2
    C68.5,47.1,69.1,46.8,69.8,46.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M73.1,46.8c-0.1-0.1-0.1-0.3-0.2-0.5
    c-0.1-0.2-0.1-0.4-0.2-0.6c0,0-0.1-0.2-0.1-0.3c0.4-0.1,0.8-0.3,1.2-0.4l0.3,0.8l0.1,0.7l0.1,0.3c-0.3,0.1-0.6,0.2-0.9,0.3
    C73.2,47.1,73.1,46.9,73.1,46.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76,46.1c0-0.1,0-0.1-0.1-0.2
    c0-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.4-0.2-0.8c0.4-0.1,0.8-0.2,1.2-0.3c0,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.7,0,0.9c0,0.1,0,0.2,0,0.3
    c-0.3,0.1-0.5,0.1-0.8,0.2C76,46.3,76,46.2,76,46.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.5,44.6c0-0.1,0-0.2,0-0.3
    c0-0.1,0-0.2,0-0.3c0.3,0,0.6-0.1,0.9-0.1c0.1,0.3,0.2,0.6,0.2,0.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0
    c-0.3,0.1-0.6,0.1-0.8,0.2l0-0.2C78.6,45.4,78.6,44.8,78.5,44.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M81.2,43.6c0.4,0,0.8-0.1,1.3-0.1
    c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.1,0.5-0.1,1.1c-0.4,0.1-0.7,0.1-1.1,0.2l0,0l0-0.6C81.3,44.2,81.3,43.8,81.2,43.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M174.1,58.2c0.5,0.9,0.9,1.7,1.4,2.6
    c0,0.2-0.1,0.4-0.3,0.7c-0.8,1.4-1.4,2-2.1,2.4c-0.6,0.4-0.8,0.5-1.2,0.8l-0.5,0.3c-0.4,0.2-0.9,0.4-1.3,0.5
    c-0.4-0.7-0.8-1.4-1.3-2.1c0.6-0.3,1.2-0.7,1.6-1.1c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.7-0.6,1.1-1c0.4-0.4,0.8-0.8,0.9-0.9
    c0.3-0.2,0.6-0.7,1-1.3c0.1-0.2,0.2-0.4,0.3-0.5C174,58.3,174,58.2,174.1,58.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M180,71.3c-0.1,0.3-0.1,0.6-0.2,0.6
    c-0.3,0.3-1.1,1-1.6,1.2c-0.3,0.1-0.8,0.3-1.2,0.6c-0.5,0.2-0.9,0.5-1.2,0.6c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.2-0.9,0.4-1.3,0.4
    c-0.3-1-0.6-2-0.9-3c0.4,0,0.9,0.1,1.1,0l0.1,0c0.5-0.1,1.3-0.4,1.9-0.7c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.2,0.8-0.4,1.1-0.6
    c0.2-0.2,1.2-0.5,1.7-0.7C179.6,70.2,179.8,70.7,180,71.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M164.2,126.1l-5.4-3.7
    c0.9-1.9,1.6-3.8,2.3-5.9c0.9-0.2,2.5-0.5,3.8-0.4c1.4,0.1,2.7-0.1,3.5-0.1c0,0,0.1,0,0.1,0C167.1,119.6,165.7,123,164.2,126.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M146.3,148.6c-0.3-0.5-0.7-0.9-0.9-1
    c-0.1-0.1-0.3-0.5-0.6-0.9c-0.7-1.3-1.4-2.4-2.1-2.9c1.8-1.6,4.2-3.9,6.8-7c1,0.9,2.9,2.8,3.5,3.5c0.3,0.3,0.6,0.5,0.9,0.7
    c0.3,0.2,0.5,0.3,0.6,0.4C152.1,143.9,149.3,146.3,146.3,148.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M130.7,157.4c-1.1-2.4-2.4-4.9-3.2-6.1
    c3.1-1,5.9-2.1,8.4-3.3c0.3,0.5,1,1.5,1.3,2.5c0.2,0.5,0.6,1.1,1,1.6c0.3,0.4,0.6,0.8,0.7,1.2C136.3,154.8,133.6,156.2,130.7,157.4
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M123.3,160.2c-3.3,1.1-6.6,2-10.1,2.8
    c-0.4-1.3-1.2-4.1-1.4-5.1c-0.3-1.2-0.5-2.1-0.7-2.6c3.3-0.6,6.6-1.3,9.8-2.1c0.4,1.1,1.3,3.6,1.4,4c0,0.1,0.1,0.3,0.3,0.6
    C122.9,158.5,123.4,159.5,123.3,160.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M79.1,161.1c0.8-1.9,2.4-6.2,2.4-8
    c1,0.6,2,1.1,3.1,1.6c0,0.2-0.1,0.5-0.1,0.7c-0.2,0.7,1.4,0.5,1.6-0.1c0,0,0,0,0,0c0.5,0.2,1,0.4,1.5,0.5c-1,2.6-2.4,6.3-3,7.4
    C82.7,162.6,80.9,162,79.1,161.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M68.9,155.2c-2-1.4-4.1-3-6.4-4.7
    c-0.5-0.4-0.9-0.7-1.4-1c0.6-0.6,1.4-1.5,1.7-2.2c0.3-0.7,3.2-3.1,4.8-4.3c0.7,0.5,1.4,1.1,2.1,1.6c1.7,1.2,3.2,2.4,4.7,3.5
    C72.2,151.1,69.7,154.4,68.9,155.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M50.2,61.9l0.5,0.4c-0.3,0.3-0.5,0.6-0.8,1
    l-0.5-0.4C49.8,62.6,50,62.2,50.2,61.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M51.8,60l0.6,0.5c-0.1,0.2-0.3,0.3-0.5,0.5
    l-0.6-0.4C51.5,60.4,51.7,60.2,51.8,60z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M53.8,58l0.6,0.6c-0.3,0.3-0.6,0.5-0.9,0.8
    L53,58.7C53.3,58.5,53.5,58.2,53.8,58z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M57.1,54.9c0.2,0.2,0.4,0.5,0.5,0.6
    c0,0,0.1,0.1,0.1,0.2c-0.4,0.3-0.7,0.6-1.1,0.9L56,55.9C56.4,55.6,56.7,55.2,57.1,54.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M67.6,51.1c0.7,0.8,1.8,2.3,2.2,2.9
    c0.4,0.6,0.8,1.5,1.2,2.4c0.2,0.5,0.4,1,0.6,1.4c0.5,1,1.2,2.1,1.8,2.8l0.9,1.2c-0.3,0.2-0.6,0.4-0.9,0.6l-0.3,0.1L70.7,64
    c-0.6-0.2-2.2-0.8-2.5-1.3c-0.1-0.2-0.4-0.5-0.6-0.9c-0.6-0.8-1.3-1.9-1.5-2.3c-0.3-0.6-1.7-2.8-2-3.3c-0.2-0.3-1-1.6-1.5-2.2
    C64.2,52.9,65.8,51.9,67.6,51.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.3,47.1c0.1,0.3,0.3,0.7,0.6,1.1
    c0.9,1.3,1.1,2,1.3,2.7c0,0.2,0.1,0.3,0.2,0.5l0.1,0.2c0.2,0.7,0.2,0.7,0.2,2.2c0,1.4,0.6,3.2,0.8,4c-0.7,0.3-1.5,0.7-2.2,1
    c-0.1,0-0.6,0-1.1-0.3c-0.4-0.3-0.9-0.8-1-1.8c0-0.7,0-1.1,0-1.4c0.1-0.7,0.1-1-0.7-2.5c-0.4-0.8-0.8-1.4-1.1-1.9
    c-0.5-0.8-0.7-1.2-0.8-1.7c-0.1-0.3-0.2-0.7-0.3-0.9C75.7,47.9,77,47.5,78.3,47.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M82.8,43.9c0.1-0.1,0.1-0.3,0-0.5
    c0.3,0,0.7-0.1,1-0.1c0.1,0.3,0.2,0.6,0.2,0.6c0,0.1,0,0.4-0.1,0.5c0,0,0,0,0,0.1c-0.4,0.1-0.8,0.1-1.2,0.2
    C82.8,44.4,82.8,44,82.8,43.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M89.5,43.3l0,0.8c-0.3,0-0.5,0-0.8,0l0-0.8
    C89.1,43.3,89.3,43.3,89.5,43.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M91.7,43.3L91.7,44c-0.2,0-0.4,0-0.6,0
    l0.1-0.7C91.3,43.3,91.5,43.3,91.7,43.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.2,43.5l0.1,0.5c-0.3,0-0.5,0-0.8,0l0-0.5
    C93.7,43.4,93.9,43.4,94.2,43.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M105.7,44.6c0.2,0,0.4,0.1,0.6,0.1l-0.1,0.6
    c-0.2,0-0.5-0.1-0.7-0.1c0.1-0.2,0.2-0.3,0.2-0.4C105.7,44.7,105.7,44.7,105.7,44.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M110.5,46l0.2,0.2c-0.4-0.1-0.7-0.2-1.1-0.2
    l0.9-0.5L110.5,46z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M113.7,45.9l0,0.2l-0.1,0.8
    c-0.4-0.1-0.9-0.2-1.3-0.3c0-0.1,0.3-0.5,0.6-0.9C113.1,45.8,113.4,45.8,113.7,45.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M120.1,47.2l-0.1,0.3l-0.4,1.2
    c-0.4-0.1-0.7-0.2-1.1-0.4c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.3,0.2-0.5,0.2-0.7C119.4,47,119.7,47.1,120.1,47.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M123.3,47.9l-0.1,0.6
    c-0.2,0.4-0.4,0.8-0.5,0.9c-0.1,0-0.2,0.1-0.3,0.2c-0.4-0.2-0.9-0.3-1.3-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.4-0.6,0.4-0.9
    c0-0.1,0.1-0.2,0.1-0.4C122.3,47.7,122.8,47.8,123.3,47.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M125.3,48.6l0.1-0.1c0.4,0.1,0.9,0.2,1.3,0.3
    c-0.1,0.4-0.1,0.9-0.2,1c-0.1,0.2-0.3,0.5-0.3,0.5l-0.5,0.8c-0.5-0.2-0.9-0.4-1.4-0.6l0.3-0.2l0.1-0.1c0,0,0.2-0.4,0.4-0.8
    C125.2,49.1,125.3,48.7,125.3,48.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M129.1,49.4c0.7,0.2,1.4,0.4,2,0.6
    c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.6-0.4,0.8c-0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.4,0.8-0.6,1.2c-0.6-0.3-1.2-0.6-1.8-0.9
    c0.1-0.2,0.3-0.4,0.3-0.5c0.1-0.2,0.6-1.2,0.6-1.2L129.1,49.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M133.8,50.9c0.7,0.2,1.4,0.5,2.1,0.7
    c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.5-0.5,1c-0.2,0.4-0.4,1-0.5,1.1c-0.2,0.2-0.5,0.7-0.6,1c-0.7-0.5-1.5-0.9-2.3-1.3
    c0.5-0.4,1.2-1,1.3-1.2c0.3-0.4,0.5-1.1,0.6-1.4C133.7,51.2,133.7,51.1,133.8,50.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M144.8,55.6l-0.4,1.3
    c-0.1,0.1-0.7,0.9-1.1,1.3c-0.4,0.4-0.9,0.7-1.4,0.9c-0.4,0.2-0.9,0.8-1.2,1.1c-0.5-0.4-1.1-0.9-1.6-1.3c0.6-0.3,1.4-0.9,1.8-1.4
    c0.3-0.3,0.6-0.6,0.8-0.8c0.3-0.3,0.6-0.5,0.8-0.9c0.2-0.3,0.5-0.8,0.7-1.1C143.8,55,144.3,55.3,144.8,55.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M148.6,58.2c0.4,0.3,0.7,0.7,1.1,1
    c0.3,0.3,0.6,0.7,0.9,1.1c0,0.3-0.1,0.6-0.2,0.7c-0.2,0.4-1,1-1.9,1.7c-0.9,0.6-1.5,0.8-2.8,1.2c-0.3,0.1-0.5,0.1-0.7,0.2
    c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2-0.1-0.3-0.4-0.4c-0.2-0.2-0.5-0.4-0.7-0.7c0.6-0.3,1.2-0.6,1.3-0.7c0.3-0.1,3.3-3.4,3.5-3.6
    C148.3,58.2,148.4,58.2,148.6,58.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.9,153.5l0.2-1c0.5,0,1.1,0,1.6,0l0,1.6
    l0,1c-0.6,0.1-1.2,0.1-1.8,0.2l0-0.6L101.9,153.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99.6,155.4c-0.3,0-0.6,0.1-0.9,0.1
    c-0.4,0-0.8,0-1.2,0.1l0-0.3l-0.1-2.5l0.1-0.5c0.8,0.1,1.6,0.1,2.4,0.2v2L99.6,155.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M95.4,154.6l-0.4,1c-0.6,0-1.1,0-1.7-0.1
    c0-0.5,0-1.1,0-1.4c0-0.4,0.1-1.8,0.1-2c0-0.1,0-0.3,0.1-0.5c0.6,0.1,1.2,0.2,1.8,0.3L95.4,154.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.1,129.4c-0.3-0.5-0.5-0.9-0.8-1.4
    c0.3-0.1,0.6-0.3,0.9-0.4c0.5-0.2,0.7-0.3,1.7-0.6l0.1,0c0.7-0.2,1-0.3,1.3-0.5c0.1-0.1,0.2-0.1,0.4-0.2l0.4-0.2
    c0.1,0.3,0.3,0.6,0.4,0.9c-0.1,0-0.2,0.1-0.4,0.2c-0.4,0.2-0.9,0.4-1.2,0.6c-0.3,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.6,0.3
    c-0.4,0.1-0.7,0.5-1,0.7c-0.1,0.1-0.2,0.2-0.3,0.3C58.3,129.5,58.2,129.5,58.1,129.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M56.7,126.9c0.5-0.3,1.5-0.9,1.6-1
    c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.2,0.4-0.3,1.1-0.6c0.7-0.3,0.9-0.4,1.1-0.4c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.4-0.2
    c0.2,0.5,0.4,0.9,0.6,1.4l-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.5,0.3-1.2,0.5l-0.1,0c-0.9,0.2-1.2,0.3-1.7,0.6
    c-0.3,0.1-0.6,0.3-0.9,0.4C57,127.4,56.9,127.2,56.7,126.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M42.4,79c-0.7,2.3-1.2,4.7-1.7,7.1L40.5,86
    C41,83.6,41.7,81.3,42.4,79z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M74.4,49.3c0.2,0.5,0.4,0.9,0.9,1.8
    c0.3,0.5,0.6,1.1,1,1.9c0.8,1.5,0.8,1.7,0.7,2.3c0,0.3-0.1,0.8,0,1.5c0.1,1.4,0.9,2,1.4,2.2c0.2,0.1,0.3,0.1,0.5,0.2
    c-0.6,0.3-1.2,0.6-1.7,0.9c0.1-0.1,0.1-0.1,0.1-0.1c-2-3.5-4.3-6.8-6.2-10.4c1-0.4,2-0.8,3-1.1C74.2,48.7,74.3,49,74.4,49.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M120.7,50.4l-1.3,1.8l0,0.1
    c-0.3,0.9-0.8,2.4-1,2.7c-0.2,0.3-1,1.5-1.4,2.2c-0.8-0.5-1.7-0.9-2.7-1.3c0-0.9,0.1-1.9,0.1-2c0.1-0.2,0.8-1.8,1.3-2.9l0.3-2
    C117.6,49.4,119.2,49.9,120.7,50.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M154.3,65.9c-0.2,0.5-0.5,0.9-0.6,1
    c-0.2,0.1-1.1,0.7-1.8,1c-0.7,0.3-1.3,0.5-2.7,0.7c-0.3,0-0.5,0.1-0.6,0.1c-0.4-0.7-0.9-1.4-1.4-2.1c0.4-0.1,1-0.3,1.7-0.6
    c0.4-0.2,0.7-0.4,0.9-0.5c0.4-0.2,0.6-0.3,1-0.7c0.3-0.3,0.4-0.3,0.6-0.4c0.1-0.1,0.3-0.1,0.5-0.3c0.3-0.2,0.7-0.5,1-0.8
    C153.3,64.1,153.8,65,154.3,65.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M153.9,128.6
    C153.9,128.6,153.9,128.6,153.9,128.6c-0.4,0-0.9,0-1.4,0c-0.2,0-0.4,0-0.5,0c-0.4,0-0.7,0-1.2-0.2c-0.5-0.2-0.7-0.3-1.3-0.8
    l-0.3-0.3c-0.4-0.3-0.6-0.4-0.8-0.5c-0.2-0.1-0.3-0.2-0.7-0.5c0,0,0,0,0,0c0.3-0.5,0.6-1,0.9-1.5c0.3,0.3,0.6,0.6,0.8,0.8
    c0.3,0.2,0.7,0.4,1.4,0.6c0.5,0.2,1.6,0.5,1.8,0.5c0.1,0,0.7,0.1,1.1,0.2c0.7,0.2,1,0.2,1.1,0.3c0,0,0,0,0,0
    C154.5,127.6,154.2,128.1,153.9,128.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M146.5,138.1c-0.2-0.3-0.5-0.6-0.9-0.8
    c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-0.4-0.2-1.1-1c-0.5-0.6-0.7-0.8-0.9-1.1c-0.2-0.2-0.3-0.5-0.7-0.9c-0.1-0.1-0.2-0.3-0.3-0.3
    c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.5,0.8-1,1.2-1.5c0.5,0.4,1.4,1.1,1.7,1.7c0.4,0.8,0.9,1.1,1.2,1.2c0.1,0.1,0.2,0.1,0.2,0.1
    c0.1,0.1,0.7,0.8,1.2,1.4C147.5,137,147,137.6,146.5,138.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M143,141.7c-0.5,0.5-1,0.9-1.5,1.3
    c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2-0.3-0.4-0.7-0.5-0.9c-0.1-0.3-0.2-0.4-0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.9c-0.3-0.7-0.6-1-1-1.4
    c-0.2-0.2-0.5-0.5-0.8-0.9c-0.1-0.2-0.2-0.3-0.3-0.4c0.8-0.8,1.6-1.5,2.4-2.3c0.3,0.3,0.6,0.7,0.8,1c0.1,0.2,0.4,0.4,0.7,0.7
    c0.2,0.2,0.5,0.4,0.5,0.5c0.1,0.3,1,1.7,1.2,2.2L143,141.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M136,146.5c0-0.1-0.1-0.2-0.1-0.3
    c-0.1-0.4-0.2-0.7-0.4-1.1c-0.2-0.4-0.4-0.6-0.5-0.7c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.4-0.4-0.8-0.6-1.2
    c-0.1-0.2-0.2-0.3-0.3-0.4c0.6-0.5,1.2-0.9,1.8-1.4c0.3,0.6,0.8,1.2,0.8,1.4c0.1,0.2,0.5,0.7,1.2,1.4c0.1,0.1,0.4,0.9,0.4,1.1
    l0,0.1c0,0.1,0,0.5,0.1,1C137.3,145.9,136.7,146.2,136,146.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M131.3,143.6c0,0,0.7,1.2,0.8,1.4
    c0.1,0.1,0.2,0.4,0.4,0.7c0.3,0.4,0.6,1,0.7,1.2c0.1,0.1-0.1,0.7-0.3,1.1c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1-0.2-0.1-0.4-0.2-0.5
    c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.2-0.3-0.4-0.4-0.5c0-0.3-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1-0.3-0.4-1.1-0.4-1.1
    l0-0.1l-0.1,0c0,0-0.1,0-0.2-0.1C130.3,144.1,130.8,143.8,131.3,143.6L131.3,143.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M127.2,146.4c0.1,0.2,0.6,1.1,0.9,1.3
    c0.1,0.1,0.3,1.1,0.3,1.4c0,0.2,0,0.3,0.1,0.4c0,0,0,0,0,0c-0.4,0.1-0.8,0.3-1.3,0.4c-0.1-0.2-0.3-0.6-0.5-0.9
    c-0.2-0.3-0.3-0.4-0.6-0.8L126,148c-0.4-0.6-0.7-0.8-0.7-0.8l-0.4-0.2c0.7-0.3,1.3-0.7,2-1C127.1,146.2,127.2,146.4,127.2,146.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M123.5,147.8c0.3-0.2,0.7-0.3,1-0.5l0.6,0.3
    c0,0,0.2,0.1,0.6,0.7l0.2,0.3c0.2,0.3,0.3,0.5,0.6,0.8c0.2,0.2,0.3,0.5,0.4,0.7c-0.3,0.1-0.7,0.2-1,0.3c0,0,0,0,0,0
    C125.4,149.3,124.6,148.3,123.5,147.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M121.3,151.8l-0.2-0.1l-0.3-0.6l-0.5-1.9
    c0.3-0.1,0.6-0.3,1-0.4l0.7,1.1l0.6,1.1l0,0.5C122.1,151.5,121.7,151.7,121.3,151.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M118.4,150.8l0.5,1.2l0,0.4
    c-0.3,0.1-0.6,0.1-0.9,0.2l-0.4-0.1l-0.2-0.6l-0.1-1l-0.2-0.8c0.3-0.1,0.7-0.2,1-0.3L118.4,150.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M116.1,151.1l0.1,1.1v0.7
    c-0.2,0-0.4,0.1-0.6,0.1v-0.8l-0.3-0.7l-0.1-0.9c0.2-0.1,0.4-0.1,0.7-0.2L116.1,151.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M114.2,151l0.3,1.3l-0.1,1.1
    c-0.3,0.1-0.5,0.1-0.8,0.1l-0.2-0.2l-0.2-1l-0.1-1C113.5,151.1,113.9,151,114.2,151L114.2,151z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M112.2,152.5l0.2,1l-0.2,0.2
    c-0.2,0-0.4,0.1-0.6,0.1c0-0.2-0.2-0.6-0.2-0.7v-1.2c0-0.1,0-0.2,0-0.3c0.2,0,0.5-0.1,0.7-0.1L112.2,152.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M110.4,153.5l0,0.6c-0.2,0-0.4,0.1-0.6,0.1
    c-0.1-0.2-0.2-0.6-0.2-0.7v-1.6c0.2,0,0.4-0.1,0.6-0.1l0,0.2L110.4,153.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M104.1,154v-1.6c0.4,0,0.9,0,1.3-0.1
    c0,0,0,0,0.1,0l0.2,0.2c0,0.5,0.1,1.5,0.1,1.6c0,0.1,0,0.4,0.1,0.6c-0.6,0.1-1.1,0.2-1.7,0.2L104.1,154z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M106.1,154.2c0-0.2-0.1-1.6-0.1-1.7l0-0.1l0,0
    c0.6-0.1,1.2-0.1,1.8-0.2l0,1.6l0.1,0.7c-0.6,0.1-1.1,0.2-1.7,0.2C106.2,154.5,106.1,154.2,106.1,154.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M108.1,153.7l-0.1-1.7c0.4,0,0.8-0.1,1.2-0.2
    v1.6l0,0.1c0,0,0.1,0.4,0.2,0.7c-0.4,0.1-0.8,0.1-1.2,0.2L108.1,153.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M122.3,149.6l-0.7-1l0.1,0
    c0.2-0.1,0.3-0.1,0.5-0.2c1,0.5,1.8,1.5,2.2,2.5c0,0,0,0,0,0c-0.5,0.1-1,0.3-1.5,0.4l0-0.4L122.3,149.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M138.1,144.4l0-0.1c-0.2-0.6-0.4-1.1-0.5-1.3
    c-0.9-0.9-1-1.2-1.1-1.3c-0.1-0.2-0.5-0.9-0.9-1.5c0.6-0.5,1.2-1.1,1.8-1.6c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.4,0.6,0.7,0.8,0.9
    c0.4,0.4,0.6,0.7,0.9,1.3c0.2,0.6,0.4,0.8,0.5,1c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.3,0.4,0.7,0.6,1c-0.8,0.6-1.7,1.2-2.6,1.8
    C138.2,144.9,138.2,144.5,138.1,144.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M146.8,127.8c0.1,0.1,0.3,0.3,0.6,0.6
    c0.3,0.2,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.4,0.7,0.6c0.2,0.1,0.4,0.3,0.6,0.5c0.4,0.4,0.9,0.7,1.4,0.9c0.1,0.1,0.2,0.1,0.3,0.2
    c0.3,0.2,0.6,0.5,0.8,0.8c-0.6,0.8-1.1,1.5-1.7,2.2c-0.3-0.3-0.8-0.8-0.9-1c-0.2-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.3-0.8-0.7
    c-0.2-0.3-0.4-0.4-0.5-0.6c-0.3-0.3-0.5-0.5-1-1.1c-0.4-0.5-0.4-0.6-0.4-0.7c0-0.1,0-0.2-0.2-0.4
    C146.1,128.8,146.4,128.3,146.8,127.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M160.1,81.7c-0.4,0.6-0.9,1.2-1.1,1.3
    c-0.4,0.2-1.2,0.5-1.8,0.6c-0.7,0.2-1.6,0.1-2.5-0.1c-0.4-0.1-0.7-0.2-0.9-0.3c-0.2-0.9-0.4-1.9-0.7-2.9l0.4,0.1l0,0c0,0,1,0,2-0.3
    l0.3-0.1c0.8-0.2,0.9-0.2,1.6-0.5c0.6-0.2,1.4-0.7,1.9-1C159.6,79.6,159.8,80.6,160.1,81.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M162.3,103.3c-0.5,0.3-1.1,0.7-1.3,0.7
    l-0.3,0.1c-0.4,0.1-1.1,0.3-1.4,0.3c-0.2,0-0.6,0-1,0c-0.4,0-0.8,0-1.1,0c-0.4,0-1.5,0.2-2.1,0.3c0.1-0.8,0.2-1.6,0.2-2.4l0.5,0.2
    l0.1,0c0,0,0.8,0.1,1.5-0.1c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.5,0,0.8-0.1c0.3,0,0.4,0,0.5-0.1c0.2,0,0.4,0,0.8-0.2
    c0.1-0.1,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0,0,0.2,0,0.5,0c0.1,0,0.2,0,0.2,0.1c0,0.5,0,0.9-0.1,1.4l-0.2-0.5L162.3,103.3z
    "
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M155.5,95.9c0.3,0.2,0.6,0.3,0.9,0.3
    c0.8,0,1.1,0,2.3-0.2c1.1-0.2,1.4-0.3,1.9-0.5l0.2-0.1c0.3-0.1,0.9-0.3,1.4-0.5c0.1,0.7,0.1,1.4,0.1,2c-0.6,0.4-1.8,1.1-2.6,1.4
    c-0.8,0.3-1.7,0.2-2.4,0.1c-0.2,0-0.4,0-0.5,0c-0.6,0-1,0-1.3,0.1C155.5,97.8,155.5,96.8,155.5,95.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M155.1,105c0.5-0.1,1.7-0.3,2.1-0.3
    c0.3,0,0.7,0,1.1,0c0.4,0,0.7,0,1,0c0.4,0,1-0.2,1.5-0.3l0.3-0.1c0.2-0.1,0.8-0.4,1.3-0.7c0,0.6-0.1,1.3-0.2,1.9
    c-0.3,0.2-0.6,0.3-0.9,0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.5,0.2-1.2,0.3-1.5,0.3c-0.2,0-0.4,0-0.6,0c-0.3,0-0.7,0-1,0.1
    c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.3,0l-0.4,0c-0.5,0-1.4-0.2-2-0.3C155,105.8,155.1,105.4,155.1,105z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M162.4,101.3c-0.3-0.1-0.7-0.2-0.9,0
    c-0.2,0.1-0.4,0.2-0.7,0.3c-0.1,0-0.2,0.1-0.4,0.1c-0.3,0.1-0.4,0.1-0.6,0.1c-0.1,0-0.3,0-0.6,0.1c-0.3,0-0.6,0-0.8,0.1
    c-0.3,0-0.6,0-1,0.1c-0.5,0.1-1.2,0.1-1.4,0.1l-0.6-0.3c0.1-0.9,0.1-1.9,0.1-2.8c0.3-0.1,0.6-0.2,1.3-0.1c0.2,0,0.3,0,0.5,0
    c0.8,0.1,1.7,0.2,2.6-0.2c0.8-0.3,1.9-0.9,2.5-1.3C162.4,98.7,162.4,100,162.4,101.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M160.7,95.1l-0.2,0.1
    c-0.5,0.2-0.7,0.3-1.8,0.5c-1.2,0.2-1.5,0.2-2.3,0.2c-0.3,0-0.6-0.2-0.9-0.4c0-0.5-0.1-1-0.1-1.5c0-0.1-0.1-0.9-0.2-2
    c0.2,0,0.4-0.1,0.8-0.1c0.6,0,1.3-0.1,1.9-0.1c0.4,0,0.8-0.1,1-0.1c0.6,0,1.2-0.2,1.7-0.4l0.1,0c0.3-0.1,0.7-0.5,1-0.9
    c0.2,1.4,0.3,2.8,0.4,4.2C161.7,94.8,161.1,95,160.7,95.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M161.7,89.9c-0.3,0.4-0.8,0.9-1.1,1l-0.1,0
    c-0.5,0.2-1.1,0.4-1.6,0.4c-0.2,0-0.6,0-1,0.1c-0.6,0.1-1.3,0.1-1.9,0.1c-0.4,0-0.6,0-0.8,0.1c-0.1-0.8-0.2-1.8-0.4-2.9
    c0.6,0.1,1.3,0.3,1.7,0.2c0.3,0,0.5,0,0.7-0.1c0.3,0,0.6,0,1-0.1l0,0c0.8-0.1,1.3-0.3,2.1-0.6c0.4-0.1,0.7-0.6,1-1
    C161.4,88.1,161.6,89,161.7,89.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M154.9,106.6c0.6,0.2,1.5,0.4,2.1,0.3l0.4,0
    c0.2,0,0.3,0,0.4-0.1c0,0,0.1,0,0.2-0.1c0.2-0.1,0.5-0.1,0.8,0c0.2,0,0.4,0,0.6,0c0.3,0,1.1-0.1,1.6-0.3c0.1,0,0.2-0.1,0.4-0.1
    c0.2-0.1,0.5-0.1,0.7-0.3c-0.1,0.6-0.1,1.2-0.2,1.7c-0.3,0.1-0.6,0.3-0.8,0.3c-0.3,0.1-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.1-0.6,0.2
    c-0.4,0.1-1.3,0.1-1.9,0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-1,0-1.7-0.1c-0.4-0.1-0.7-0.1-0.9,0C154.7,107.9,154.8,107.2,154.9,106.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M160.8,112.8c-0.3,0.2-0.6,0.4-1,0.5
    c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0.1-0.7,0.1-1,0.1c-0.5,0-1.5,0-2.4-0.1l-0.2,0c-0.8,0-1.7-0.1-2-0.2c-0.1,0-0.2,0-0.3,0
    c0.2-0.8,0.4-1.6,0.6-2.4c0.7,0.2,1.4,0.4,1.6,0.3c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0.1,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1-0.1
    c0.2-0.1,0.4-0.1,0.7-0.1c0.5-0.1,0.8-0.2,0.9-0.3c0.2,0,0.7,0.4,1.3,0.8C161,111.9,160.9,112.3,160.8,112.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M152.6,115.8l0.1,0.1l0.1,0.1
    c0,0,1.1,0.5,1.8,0.5c0.3,0,0.5,0,0.6,0c0.3,0,0.5,0,0.7,0c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.3,0,0.4,0
    c0.4,0,1,0.1,1.4,0c0.2-0.1,0.4-0.1,0.6-0.2c-0.3,0.8-0.5,1.5-0.8,2.3c-0.6,0.1-1.1,0.3-1.2,0.3c-0.1,0-0.2,0-0.5,0
    c-0.5,0-1.1,0-1.4,0H154c-0.8,0-1-0.1-2.1-0.8c-0.1,0-0.1-0.1-0.2-0.1C152.1,117.4,152.3,116.6,152.6,115.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M150.8,120.3c0.4,0.1,0.8,0.3,1,0.4
    c0.4,0.2,0.9,0.4,1.4,0.4l0.2,0c0.5,0.1,1.2,0.1,1.9,0.1c0.7,0,1.1,0,1.6,0c0.4,0,0.7,0,1,0c-0.2,0.4-0.3,0.8-0.5,1.2
    c0,0-0.1,0-0.1,0c-2.3,0.4-4.5,0.2-6.6-0.7c-0.1,0-0.2,0-0.4,0C150.4,121.3,150.6,120.8,150.8,120.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M158,120.9c-0.3,0-0.6,0-1.2,0
    c-0.5,0-0.9,0-1.6,0.1c-0.6,0-1.3,0-1.8-0.1l-0.2,0c-0.5,0-1-0.2-1.3-0.4c-0.2-0.1-0.7-0.3-1-0.5c0.2-0.5,0.4-1,0.6-1.5
    c0,0,0.1,0,0.1,0.1c1.1,0.7,1.4,0.9,2.3,0.9h1.8c0.3,0,1,0,1.4,0c0.2,0,0.4,0,0.5,0c0.1,0,0.6-0.1,1-0.2
    C158.5,119.7,158.2,120.3,158,120.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M156.9,123.2
    C156.9,123.2,156.9,123.2,156.9,123.2c-0.3,0.5-0.5,1-0.8,1.5h-1.5c-0.4,0-2.3-0.1-3-0.4c-0.3-0.1-0.5-0.2-0.8-0.3
    c-0.4-0.1-0.8-0.3-1.4-0.5c-0.1,0-0.1-0.1-0.2-0.1c0.2-0.3,0.3-0.6,0.5-0.9C152.1,123.4,154.5,123.6,156.9,123.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M159.8,116c-0.3,0.1-0.6,0.3-0.9,0.3
    c-0.4,0.1-0.9,0.1-1.3,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.1-0.5-0.2-0.7-0.1c-0.2,0-0.4,0-0.7,0
    c-0.2,0-0.4,0-0.7,0c-0.6,0-1.5-0.4-1.6-0.5l-0.2-0.3c0.2-0.6,0.4-1.2,0.6-1.8c0.1,0,0.2-0.1,0.4,0c0.4,0.1,1.2,0.1,2.1,0.2l0.2,0
    c0.9,0.1,1.9,0.1,2.4,0.1c0.3,0,0.7-0.1,1.1-0.2c0.2,0,0.3-0.1,0.5-0.1c0.3-0.1,0.6-0.2,0.8-0.3C160.4,114.2,160.1,115.1,159.8,116
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M161.2,111.1c-1.3-0.9-1.5-0.8-1.5-0.7
    c-0.1,0.1-0.5,0.2-0.8,0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.5,0-0.7,0c-0.2,0.1-0.4,0-0.6,0
    c-0.2,0-0.3,0-0.4,0c-0.1,0-0.8-0.1-1.4-0.3c0.1-0.5,0.2-1,0.3-1.6c0.2,0,0.5-0.1,0.9,0c0.7,0.1,1.3,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0
    c0.7,0,1.6,0,2-0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4-0.1,0.6-0.2C161.6,109.2,161.4,110.1,161.2,111.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M161.2,86.7c-0.3,0.4-0.7,1-1,1.1
    c-0.7,0.3-1.2,0.4-2,0.5l0,0c-0.4,0.1-0.7,0.1-1,0.1c-0.2,0-0.4,0-0.7,0.1c-0.3,0.1-1.1-0.1-1.7-0.2c-0.2-1.4-0.5-3-0.8-4.7
    c0.2,0.1,0.5,0.2,0.7,0.2c0.9,0.2,1.9,0.3,2.7,0.1c0.7-0.2,1.6-0.5,1.9-0.7c0.2-0.1,0.7-0.7,1.1-1.1
    C160.6,83.6,160.9,85.2,161.2,86.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M157.3,79.2c-0.7,0.3-0.8,0.3-1.5,0.5
    l-0.3,0.1c-0.9,0.2-1.8,0.2-1.9,0.2l-0.6-0.2c-0.3-1.3-0.7-2.5-1.1-3.8c0.5,0,1,0,1.6,0c1.2,0,1.7-0.1,2.2-0.3
    c0.4-0.1,1.1-0.5,2.2-1.2l0.1,0c0,0,0,0,0,0c0.4,1.2,0.8,2.5,1.2,3.8C158.7,78.5,157.8,79,157.3,79.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M157.8,74.1c0,0-0.1,0.1-0.1,0.1l-0.1,0
    c-1.3,0.8-1.9,1.1-2.2,1.2c-0.5,0.2-0.9,0.3-2.1,0.3c-0.6,0-1.2,0-1.7,0c-0.3-1-0.7-2-1.1-2.9c0.6,0,1.4,0.1,2.1,0
    c0.3,0,0.5,0,0.7,0c0.3,0,0.4,0,0.8-0.2c0.2-0.1,0.4-0.2,0.7-0.3c0.5-0.1,1-0.3,1.3-0.7c0.1-0.2,0.3-0.3,0.5-0.4
    C157.1,72.1,157.4,73.1,157.8,74.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M144.7,65.6c7.9,8.3,9.3,28.3,9.4,28.5
    c1.1,14.1-3.4,27.8-12.6,38.6c-9.2,10.8-22,17.3-36.2,18.4c-11.3,0.9-22.2-2.7-30.8-10c-8.6-7.3-13.8-17.6-14.7-28.8
    c-0.7-9,2.1-17.7,8-24.5c5.8-6.8,14-11,23-11.7c14.7-1.1,27.7,9.9,28.8,24.6c0.3,4.4-0.7,8.5-2.7,12.1l-0.1,0l0.1,0
    c-3.4,6-9.6,10.3-16.9,10.8c-9.3,0.7-17.5-6.3-18.2-15.6c-0.3-3.6,0.8-7,3.2-9.8c0.5-0.6,1.1-1.2,1.8-1.7c-2.6,2.4-4.2,5.8-4.2,9.6
    c0,8.9,7.3,16.2,16.2,16.2c11.1,0,20.1-9,20.1-20.1c0-8.2-4-15.5-10.2-20.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.2-0.1-0.5-0.1
    c-4.1-2.9-9.1-4.6-14.5-4.6c-14.4,0-26.6,9.9-30.1,23.3c-0.2,0.2-0.4,0.5-0.1,0.6c-0.5,2.3-0.8,4.7-0.8,7.1
    c0,21.3,17.3,38.7,38.7,38.7c26.5,0,48.1-21.5,48.1-48c0.8-2-0.3-9.7-1.5-16.2c-1-5.1-3.8-9-7.4-11.9c0,0,0,0,0,0c1-2,2.1-4,3.2-6
    C144.2,65.1,144.5,65.3,144.7,65.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M87.6,154.4c-0.5-0.1-0.9-0.3-1.3-0.5
    c0.2-1.4,0.4-2.8,0.4-4.2c0.4,0.1,0.8,0.3,1.2,0.4C87.8,151.3,87.7,154,87.6,154.4C87.7,154.4,87.6,154.4,87.6,154.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.2,150.7c0.3-0.5,0.7-1.2,0.9-1.6
    c0.2-0.3,0.6-1,0.9-1.5c0.2,0.1,0.4,0.2,0.6,0.3l-0.9,2.5l-0.6,0.9C80.8,151.1,80.5,150.9,80.2,150.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M85.2,149.1c0,0.1-0.1,0.1-0.1,0.2
    c0,1.4-0.1,2.7-0.3,4c-0.6-0.3-1.1-0.5-1.7-0.8c0.3-0.8,0.6-2.1,0.7-2.3c0-0.2,0.3-0.9,0.5-1.5C84.6,148.9,84.9,149,85.2,149.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M70.4,143.4C70.3,143.4,70.3,143.4,70.4,143.4
    c0.4-0.6,1-1.5,1.2-1.8c0.1-0.2,0.4-0.5,0.7-0.8c0.4,0.4,0.8,0.7,1.2,1.1l-1.1,1.9c-0.3,0.2-0.5,0.4-0.8,0.5
    C71.2,144,70.8,143.7,70.4,143.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M68.6,142.1c-0.3-0.2-0.5-0.4-0.8-0.6
    c0.7-1,1.6-1.9,2.6-2.5c0.2,0.2,0.4,0.4,0.6,0.6c-0.4,0.5-0.8,0.9-0.9,1c-0.2,0.1-0.8,0.9-1,1.2C69,141.7,68.8,142,68.6,142.1
    C68.6,142.1,68.6,142.1,68.6,142.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M66,139.8c0.5-0.4,1.4-1.1,1.7-1.3
    c0.3-0.2,1-0.7,1.4-1.1c0.2,0.2,0.4,0.5,0.6,0.7c-1.1,0.6-2.1,1.5-2.9,2.5C66.5,140.4,66.3,140.1,66,139.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M65.2,132.1l-0.1,0.4c-0.3,0.1-1.4,0.5-2,0.6
    c-0.7,0.2-1.6,0.7-1.6,0.7l-0.3,0.2c-0.2-0.3-0.4-0.6-0.6-0.9l1.3-1c0.5-0.3,1.2-0.8,1.4-0.8c0.2,0,0.8-0.4,1.1-0.6
    C64.6,131.2,64.9,131.7,65.2,132.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M61.3,134.4l0.3-0.2c0,0,0.8-0.5,1.5-0.6
    c0.7-0.2,2.1-0.7,2.1-0.7l0.1,0l0.1-0.3c0.2,0.3,0.4,0.6,0.6,0.9c-0.3,0.2-0.7,0.4-1.2,0.7c-0.4,0.3-1,0.6-1,0.6
    c-0.2,0.1-0.9,0.7-1.3,1l-0.4-0.4C61.8,135.1,61.5,134.7,61.3,134.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M63.8,135.1c0.1,0,0.4-0.2,1.1-0.7
    c0.4-0.2,0.9-0.6,1.2-0.7c0.3,0.5,0.7,1,1,1.4l-0.2,0.4c-0.5,0.2-1.3,0.6-1.5,0.8c-0.1,0.1-0.5,0.4-0.7,0.6l-1.1,0.7
    c-0.4-0.5-0.8-0.9-1.2-1.4C63.1,135.7,63.7,135.2,63.8,135.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M64,137.8l1.1-0.6c0.1-0.1,0.6-0.5,0.8-0.7
    c0.1-0.1,0.8-0.4,1.4-0.7l0.1,0l0.2-0.4c0.2,0.3,0.5,0.6,0.8,0.9l-0.2,0.3c0,0-1.2,0.9-1.8,1.2c-0.4,0.2-1,0.4-1.5,0.6
    C64.5,138.3,64.2,138.1,64,137.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M66.3,138.3c0.7-0.3,1.8-1.2,1.9-1.3l0.2-0.3
    c0.1,0.2,0.3,0.3,0.4,0.5c-0.4,0.4-1.1,0.9-1.3,1c-0.3,0.2-1.3,0.9-1.8,1.4c-0.3-0.3-0.5-0.5-0.8-0.8
    C65.4,138.7,65.9,138.5,66.3,138.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76.7,144.4l-0.9,1.6
    c-0.2,0.3-0.5,0.7-0.7,0.9c-0.5-0.4-1-0.8-1.5-1.1c0.3-0.4,0.9-1.2,1.2-1.7c0.2-0.3,0.4-0.5,0.5-0.7
    C75.8,143.7,76.2,144,76.7,144.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76.6,148.1l0.5-0.9l0.6-1.4l0.4-0.4
    c0.1,0.1,0.2,0.2,0.4,0.2l-0.7,1.6l-0.7,1.2C76.9,148.3,76.8,148.2,76.6,148.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76.1,146.2l0.9-1.6c0.3,0.2,0.6,0.4,0.8,0.6
    l-0.4,0.4l-0.6,1.5l-0.5,0.8c-0.3-0.2-0.6-0.5-0.9-0.7c0,0,0,0,0,0C75.7,146.9,75.9,146.5,76.1,146.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.1,147.4l0.7-1.6c0.3,0.2,0.6,0.4,0.9,0.5
    l-1.8,2.7c-0.2-0.1-0.4-0.3-0.5-0.4L78.1,147.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M54.3,122.4c0.4,0,1-0.1,1.3-0.2l0.1,0
    c0.5-0.2,1.1-0.4,2-0.5c0.7,0,1.1-0.1,1.5-0.1c0.1,0,0.3,0,0.5-0.1c0.2,0,0.4,0,0.6-0.1c0,0.1,0.1,0.2,0.1,0.4
    c-0.6,0.1-2,0.3-2.3,0.4c-0.2,0.1-0.3,0.1-0.3,0.2c0,0-0.1,0.1-0.8,0.2c-1,0.2-1.9,0.5-2.2,0.6C54.6,122.9,54.5,122.7,54.3,122.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M46,72.4c1,0.7,2.7,1.8,4,2
    c1.7,0.4,2.9,0.8,4.2,2.9c0.5,0.8,1.4,1,2.6,0.7c0.6-0.2,1.3-0.5,1.9-0.9c-0.6,0.8-1.2,1.6-1.7,2.4c-1,1.5-1.8,3.3-2.5,5.2
    c-1.5-0.6-5.7-2.2-6.6-2.2c-0.6,0-2.8-1.5-4.3-2.8c0.6-2.1,1.2-4.1,2-6.1C45.7,73.3,45.8,72.9,46,72.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.4,51.7c-0.1-0.4-0.6-1.5-0.7-1.6l-1.2-4.9
    c1.6,0,3.2,0.1,4.8,0.2c0.2,0.9,0.5,2.6,0.5,3.1c0,0.4,0,1.3,0.1,2.1c0,0.6,0.1,1.1,0.1,1.2c0,0.3,0.4,1.3,0.6,2
    c-1.3,0.1-2.6,0.2-4,0.4C94.6,53.5,94.5,52,94.4,51.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M100.3,44l0,0.5c-0.4,0-0.7-0.1-1.1-0.1
    l0.1-0.4C99.6,43.9,100,43.9,100.3,44z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M110.8,56.5c0.2,0.1,0.5,0.2,0.7,0.3l-0.6,2.1
    c0,0-0.3,4-0.5,4.7c-0.1,0.3-0.2,1.3-0.4,2.7c-0.3,2.2-0.8,5.3-1.2,6.7c-0.5,1.7-1.4,4.5-1.8,5.6c-0.5-0.3-1-0.6-1.6-0.8
    c0.1,0,0.2-0.1,0.2-0.2c2.9-5.7,3.5-12,3.9-18.2c0.4-0.1,1-0.3,1-0.5C111.1,57.3,111,57.9,110.8,56.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M131.6,55.4c-0.1,0.4-0.2,0.8-0.3,0.9
    c-0.1,0.1-0.5,1.1-1.1,2.5c-0.3,0.7-0.6,1.3-0.7,1.5c-0.1,0.3-1.3,1.8-2.2,2.9c0,0,0,0-0.1,0c-1.3-0.6-2.5-1.2-3.6-1.7
    c1.2-1.7,3-4.5,3.1-4.6c0.1-0.2,1.4-1.7,2.4-2.8C129.9,54.5,130.8,54.9,131.6,55.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M137.1,58.9l-0.7,2.2l-3.1,4.9
    c-1-0.5-1.9-1-2.9-1.4c0.9-1.2,2.4-3,2.5-3.2c0.1-0.1,1.7-2,2.9-3.4C136.2,58.3,136.6,58.6,137.1,58.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.8,55.1c0.1,0.7,0.2,2.2,0.2,4.3
    c0,1.6,0.2,2.6,0.4,3.4c0.1,0.7,0.3,1.3,0.2,2.1c-0.1,1.7-0.7,7.7-0.7,7.8l-0.3,3.4c-0.5-0.2-1.1-0.3-1.7-0.5
    c0.1-1.1,0.5-4,0.5-4.6c0-0.2,0-0.8-0.1-1.7c0-1.9-0.1-5.1-0.4-7.7c-0.4-2.7-1.2-5.3-1.6-6.4C99.5,55.1,100.7,55.1,101.8,55.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M96,55.4c0.3,1.6,1.2,5.5,1.3,6.8
    c0,0.3,0,0.6,0.1,1c0.1,2.1,0.3,5.6,0.7,6.8c0.3,1.1-0.4,4.1-0.6,5c-0.4-0.1-0.9-0.1-1.3-0.2c0.1-1.3,0.4-4.9,0.1-7.4
    c-0.1-1-0.2-1.9-0.2-2.8c-0.1-2.1-0.2-4.1-1.2-5.9c-0.8-1.4-1.3-2.3-1.5-2.9c0.1,0,0.2,0,0.3,0C94.4,55.6,95.2,55.5,96,55.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M92.8,55.8c0.2,0.6,0.7,1.5,1.6,3
    c1,1.8,1.1,3.7,1.2,5.8c0,0.9,0.1,1.9,0.2,2.8c0.3,2.5,0.1,6.1-0.1,7.4c-0.5-0.1-1-0.1-1.5-0.1c-0.3-3.3-0.2-6.6-0.6-9.9
    c-0.4-2.9-2.1-5.4-2.6-8.3c0-0.1-0.2-0.2-0.4-0.2C91.3,56.1,92.1,55.9,92.8,55.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M98.4,69.9c-0.3-1.2-0.5-4.8-0.6-6.8
    c0-0.4,0-0.7-0.1-1c-0.1-1.3-0.9-5.2-1.3-6.8c0.5,0,1-0.1,1.5-0.1c0.4,1.1,1.2,3.7,1.6,6.5c0.3,2.6,0.4,5.8,0.4,7.7
    c0,0.9,0,1.5,0.1,1.7c0.1,0.4-0.2,2.7-0.5,4.4c-0.6-0.2-1.2-0.3-1.8-0.4C98,74.1,98.7,71.1,98.4,69.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.2,72.7c0-0.1,0.6-6.1,0.7-7.8
    c0-0.8-0.1-1.5-0.2-2.2c-0.2-0.8-0.4-1.8-0.4-3.4c0-2-0.1-3.5-0.2-4.3c1.3,0,2.4,0.1,3.6,0.2c-0.1,1.3-0.3,4.1-0.3,5.4
    c0,5.3-0.1,8.5-0.2,9.6c-0.2,1.4-1.4,5.4-1.7,6.5c-0.5-0.2-1-0.4-1.5-0.6L102.2,72.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M113.3,57.5c0,0.3,0,1-0.2,2.6
    c-0.2,1.1-0.3,2.5-0.4,4c-0.2,2.3-0.4,4.7-0.8,6.2c-0.5,2.2-2.6,7.9-3.2,9.3c-0.5-0.3-1-0.6-1.4-0.9c0.4-1.1,1.4-3.9,1.9-5.7
    c0.4-1.4,0.8-4.5,1.2-6.7c0.2-1.3,0.4-2.4,0.4-2.6c0.2-0.7,0.5-4.6,0.5-4.7l0.6-2C112.4,57.1,112.9,57.3,113.3,57.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M123.6,63.9c-0.3,0.8-1.2,2.9-2.2,4.6
    c-0.6,1-0.7,1.6-0.9,2.3c-0.2,0.8-0.4,1.6-1.3,3.2c-0.9,1.5-1.6,2.8-2.2,3.8c-0.6,1-1,1.7-1.2,2.1c-0.2,0.5-2.1,2.1-3,2.9
    c-0.3-0.3-0.6-0.5-0.9-0.8c0.5-0.5,1.5-1.6,1.8-2.3c0.3-0.8,1.5-4.5,1.8-5.6c0.1-0.5,0.9-2.5,1.6-4.5c1-2.7,1.7-4.5,1.7-4.7
    c0.1-0.4,0.4-2.4,0.5-3.4C120.5,62.4,122,63.1,123.6,63.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M115.7,86c1.7-0.8,7-3.5,8.9-5.4
    c2.1-2.1,4.9-7.4,6-9.5l0.2-0.4c0.5-0.9,0.6-2.3,0.6-3.2c1.2,0.5,2.3,1.1,3.4,1.7c-0.2,0.4-1.3,3.7-3.1,6.1
    c-0.8,1.1-1.7,2.4-2.5,3.5c-1.1,1.5-2,2.9-2.5,3.4c-0.5,0.5-2.6,1.8-4.4,2.9c-1.5,0.9-2.8,1.7-3.2,2.1c-0.6,0.5-1.7,0.4-2.4,0.3
    C116.5,87,116.1,86.5,115.7,86z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M118.1,60.7c0.3,0.2,0.6,0.5,0.9,0.7
    c-0.1,1-0.5,3.2-0.5,3.6c-0.1,0.3-0.9,2.6-1.7,4.7c-0.8,2.1-1.5,4-1.6,4.5c-0.3,1-1.5,4.7-1.8,5.5c-0.2,0.6-1.2,1.6-1.7,2.2
    c-0.4-0.4-0.9-0.8-1.4-1.1c0.3-0.1,0.7-0.3,0.9-0.7c0.3-0.7,1.9-5,2.1-5.8c0.1-0.3,0.5-1.3,0.9-2.5c0.7-1.9,1.5-4,1.6-4.5
    c0.2-0.7,1.1-5,1.6-7C117.6,60.3,117.9,60.5,118.1,60.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M116.1,80.1c0.1-0.3,0.6-1.1,1.2-2
    c0.6-1,1.4-2.3,2.2-3.8c0.9-1.7,1.2-2.6,1.4-3.3c0.2-0.6,0.3-1.2,0.9-2.2c0.9-1.7,1.8-3.8,2.2-4.7c0.7,0.3,1.4,0.7,2.1,1
    c0.8,0.4,1.6,0.7,2.5,1.1c0,0-0.1,0-0.1,0.1c-2.6,2.2-2.9,5.9-4.9,8.6c-2.7,3.5-6.5,6-9.7,8.9c0,0,0,0-0.1,0.1
    c-0.2-0.2-0.4-0.4-0.7-0.6C113.8,82.5,115.8,80.7,116.1,80.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M114.8,84.4c2.8-2.6,5.9-4.9,8.6-7.6
    c1.2-1.3,2.3-2.7,3.2-4.2c1.1-1.9,1-4.1,2.8-5.6c0.1-0.1,0.2-0.2,0.2-0.3c0.5,0.2,1,0.4,1.4,0.7c0,0.9-0.1,2.3-0.6,3.2l-0.2,0.4
    c-1.1,2-3.9,7.3-5.9,9.4c-1.9,1.9-7.4,4.6-8.9,5.4c-0.3-0.4-0.6-0.8-1-1.1C114.6,84.5,114.7,84.5,114.8,84.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M119.5,87.5c0.4-0.3,1.7-1.2,3.2-2.1
    c1.9-1.2,3.8-2.4,4.4-3c0.6-0.6,1.5-1.9,2.6-3.5c0.8-1.1,1.7-2.4,2.5-3.5c1.7-2.2,2.8-5.2,3.1-6c0.3,0.1,0.6,0.3,1,0.5
    c0.7,0.4,1.4,0.8,2.1,1.3c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.6-0.2,1.1-0.6,1.6c-1,1-3.2,3.9-4.7,6c-0.6,0.8-1.1,1.5-1.4,1.9
    c-0.6,0.7-1.8,1.5-2.9,2.1c-0.9,0.6-1.8,1.1-2.2,1.6c-0.2,0.2-0.4,0.5-0.6,0.8c-0.8,1.1-1.7,2.4-2.9,3c-1.1,0.6-3.4,1.2-4.5,1.5
    c-0.5-0.9-1-1.7-1.6-2.6C117.9,88,118.9,88,119.5,87.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M144.6,77.4c0,1.1,0,2.2-0.1,2.4
    c-0.3,0.2-4.6,3.3-6.1,4.4c-0.7,0.5-1.4,1.4-2.2,2.2c-1,1.1-2.1,2.3-3.1,3c-0.5,0.3-0.9,0.6-1.2,0.8c-0.8,0.6-1.3,0.9-2.9,1.9
    c-1.6,1-3.9,1.8-5.1,2.3c-0.6,0.2-0.9,0.3-1,0.4c-0.1,0.1-0.6,0.1-1,0.2c-0.5,0.1-0.9,0.1-1.2,0.2c-0.3-0.8-0.5-1.5-0.9-2.3
    c1.5-0.3,4.1-1.1,6-2.4c0.7-0.5,1.5-1,2.4-1.6c2.5-1.7,5.3-3.5,6.7-4.7c1.3-1,2.2-2.3,3.1-3.6c0.4-0.6,0.9-1.3,1.4-1.9
    c1.3-1.6,2.4-3.3,2.9-4C143.2,75.5,144,76.4,144.6,77.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M146.9,83.1c0.1,0.8,0.3,1.5,0.4,2.1
    c-0.7,1-1.8,2.6-2.3,3c-0.1,0.1-0.3,0.3-0.6,0.5c-1.4,1.2-4.3,3.8-6.1,5c-0.6,0.4-1.2,0.8-1.9,1.3c-1.9,1.3-4,2.9-6.2,3.5
    c-2.2,0.7-6.1,1.8-8.1,2.4c-0.1-0.7-0.2-1.4-0.3-2c1.5-0.2,4-0.9,4.6-1.3c0.3-0.2,1.4-0.8,2.6-1.4c2-1.1,4.8-2.5,6.3-3.5
    c1.3-0.9,3-2.6,4.7-4.2c1.4-1.4,2.7-2.7,3.7-3.4c1.4-1,2.4-2.5,2.9-3.3C146.7,82.2,146.8,82.6,146.9,83.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M147.3,100.5l-0.5,0.6c-0.5-0.1-3-0.4-5.9,0.8
    c-0.6,0.2-1.2,0.5-1.7,0.7c-2.2,0.9-3.5,1.4-5.4,2.7c-2.3,1.5-3.9,2.5-6.2,3c-0.8,0.2-1.7,0.4-2.7,0.6c-1.5,0.4-3.1,0.7-3.9,0.8
    c0.1-0.3,0.2-0.5,0.3-0.8c0.2,0,0.4-0.1,0.5-0.1c7.1-4.1,16.3-13,25.1-10.2c0.2,0,0.4,0,0.7-0.1c-0.1,0.3-0.1,0.4-0.2,0.5l-0.2,0.1
    v0.3C147.3,99.8,147.3,100.2,147.3,100.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M147.8,97.7c-9.5-2.7-18.6,5.7-26.3,10.3
    c0.3-1.3,0.5-2.6,0.5-3.9c1.2-0.2,2.9-0.5,3.8-1.2c1.5-1.1,5.9-3.5,9.6-5.1c1.2-0.5,2.2-1,3.2-1.5c2.2-1,4.1-1.9,5.9-2.4
    c1.5-0.4,2.6-0.7,3.4-0.9C148.2,95.4,148,96.8,147.8,97.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M145.7,111.9c-0.3,0.3-1,0.4-1.9,0.3
    c-1.8-0.2-3.5-0.1-5.2,0c-0.4,0-0.8,0-1.3,0.1c-0.7,0-1.2,0.1-1.7,0.2c-1,0.1-1.8,0.2-3.8,0l-0.9-0.1c-2-0.3-2.4-0.4-4-0.1
    c-0.3,0.1-0.7,0.1-1,0.2c-1.5,0.3-3,0.5-3.8,0.2c-0.4-0.1-1.3-0.3-2.1-0.4c0.1-0.2,0.2-0.4,0.3-0.6c1.2,0.2,3.2,0.4,5.5-0.5
    c2.2-0.9,2.7-1,4.7-1.6c0.7-0.2,1.6-0.5,2.8-0.8c4.2-1.3,4.8-1.3,7.1-1.5l0.9-0.1c2.3-0.2,4.4,0.4,5.2,0.7
    C146.4,109.2,146,110.6,145.7,111.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M119.8,112.8c0.9,0.4,2.4,1.2,5,1.9
    c1.7,0.4,2.7,0.5,3.7,0.7c1.2,0.1,2.4,0.3,5,0.9c2,0.5,3,0.7,3.7,0.8c1,0.2,1.2,0.2,2.6,0.9c1.5,0.8,2.5,1.3,3.1,1.7
    c-0.8,1.6-1.6,3.1-2.6,4.5c-0.3-0.2-0.9-0.6-1.7-1.2c-1.1-0.9-2.1-1.3-3-1.8c-0.9-0.4-1.8-0.8-2.9-1.7c-2.2-1.6-2.3-1.6-4.6-2.6
    l-0.6-0.2c-1.4-0.6-2-0.8-2.5-0.9c-0.5-0.2-1-0.3-2.2-0.9c-1.3-0.6-2.5-1-3.3-1.3C119.5,113.3,119.7,113.1,119.8,112.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M133.8,131.7c-0.6-1.2-2-3.9-2.8-5.2
    c-0.4-0.6-0.7-1.3-1-1.9c-0.5-1.1-1.1-2.3-2.2-3.6c-1.2-1.4-2.6-2.4-3.8-3.2c-0.7-0.5-1.2-0.9-1.7-1.3c-0.8-0.7-1.6-1.2-2.2-1.5
    c-0.4-0.2-0.7-0.4-0.9-0.6c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c1.1,0.7,2.9,1.8,4.1,2.4c2.1,1.1,4.8,2.9,6,4.4
    c0.6,0.7,0.8,1.2,1.1,1.7c0.4,0.7,0.7,1.4,1.7,2.4c0.7,0.7,1.2,1.3,1.6,1.7c0.6,0.7,1,1.2,1.7,1.7c0.4,0.3,0.6,0.6,0.8,0.9
    C135.4,130.2,134.6,130.9,133.8,131.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M116.7,117.4c0,0.9,0.3,1.7,0.9,2.2
    c1.6,1.3,1.6,1.3,3.4,3.7c1,1.4,1.3,2,1.7,2.9c0.3,0.6,0.6,1.4,1.3,2.7c0.8,1.3,1.3,2.2,1.8,2.8c0.5,0.8,0.8,1.3,1.1,2
    c0.3,0.9,0.3,2.4,0.3,3.2c-0.7,0.4-1.4,0.8-2.1,1.2c0-0.6,0-1.6-0.3-3c-0.5-2-0.6-2.4-1.3-3.7c-0.2-0.4-0.4-0.9-0.8-1.6
    c-1.3-2.9-1.7-3.6-3.1-5.7c-0.8-1.2-1.4-2-1.9-2.7c-0.4-0.6-0.8-1.1-1.1-1.6c-0.3-0.5-0.6-1-0.8-1.4
    C116.1,118.1,116.4,117.8,116.7,117.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M113.7,120.4c0.4,0.6,1.1,1.5,1.8,2.2l0.2,0.3
    c0.8,1,1.1,1.3,2.8,4.6c0.4,0.8,0.7,1.5,1,2c0.8,1.6,1,1.8,1.4,3.8c0.3,1.1,0.4,1.8,0.5,2.3c0.1,0.6,0.2,1,0.5,1.8
    c0.2,0.6,0.3,1.6,0.4,2.4c-0.8,0.4-1.6,0.7-2.4,1.1c0-0.8,0.1-1.8,0-2.8c-0.2-2.3-0.9-4.1-1.7-6.2l0-0.1c-0.6-1.5-0.7-1.8-1.3-3
    c-0.3-0.5-0.6-1.3-1.1-2.3c-1-2.1-2.2-3.9-3-5C113,121,113.4,120.7,113.7,120.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M110.8,122.5c0.8,1.4,2,3.5,2.4,4.4
    c0.2,0.5,0.3,0.5,0.4,0.6c0.1,0.1,0.3,0.6,1.1,3.1c0.8,2.8,1,3.4,1.1,4.1c0.1,0.5,0.2,1,0.5,2.2c0.6,2.3,0.7,3.6,0.7,4.3
    c0,0.2,0,0.4,0,0.5c-1,0.3-2.1,0.7-3.2,0.9c-0.3-1-0.7-2.9-0.7-4.4c0.1-1.2-0.3-2.1-0.7-3.2c-0.4-1-0.8-2.2-0.9-3.8
    c-0.1-1.4-0.1-1.9,0-2.2c0.1-0.5,0-0.6-0.6-2.1c-0.6-1.5-1.4-2.7-1.9-3.4C109.6,123.2,110.2,122.9,110.8,122.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M107.2,124.3c0.3,0.5,0.6,1.3,0.9,2.1
    c0.3,0.9,0.5,1.9,0.7,3c0.2,1.1,0.5,2.4,0.9,3.9c0.4,1.4,0.6,2.3,0.7,3.2c0.2,1,0.3,1.8,0.7,2.8c0.5,1.3,0.3,2.9,0.1,3.8
    c-0.8,0.2-1.6,0.3-2.4,0.4c-0.3-1.2-0.8-3.3-0.8-5.1c0-1.7-0.2-2.5-0.5-3.5c-0.2-0.7-0.4-1.5-0.6-2.8c-0.4-2.6-0.5-2.9-0.7-3.8
    c-0.1-0.2-0.1-0.4-0.2-0.7c-0.2-0.9-0.5-1.8-0.8-2.5C105.9,124.9,106.6,124.6,107.2,124.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.5,125.8c0.1,0.4,0.2,0.9,0.3,1.5
    c0.5,2.2,0.5,2.2,0.9,4.1c0.1,0.6,0.2,1.3,0.4,2.2c0.2,1,0.3,1.8,0.4,2.3c0.4,1.8,0.4,1.8,0.4,3.6c0,1.4-0.1,3.3-0.2,4.5
    c-1.1,0.1-2.1,0.1-3.2,0.1c0.1-0.8,0.3-1.9,0.5-2.9c0.4-2.1,0.1-5.7-0.1-8.3c-0.1-1.6-0.1-2.2-0.1-2.8c0-0.6,0-1-0.2-2.3
    c-0.2-1-0.2-1.6-0.2-2C101.8,125.9,102.2,125.8,102.5,125.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99.5,126.1c0.2,1,0.5,2.2,0.7,2.9
    c0.1,0.5,0.1,0.8,0,1.4c-0.1,0.7-0.2,1.8-0.2,4.1c0,3-0.2,4.1-0.4,5.2c-0.1,0.4-0.2,0.8-0.2,1.4c-0.1,1.1-0.6,2.3-1,3
    c-1.5-0.1-3-0.3-4.5-0.6c2.7-4.2,3.9-8.9,5.1-13.6c0.2-0.8-1.4-0.6-1.6,0.1c-1.2,4.6-2.3,9.2-5,13.2c-0.6-0.1-1.2-0.3-1.8-0.5
    c0.7-1.3,2.1-3.8,3.2-6.3c1.1-2.6,1.6-4.7,2-6.3c0.2-0.6,0.3-1.2,0.5-1.8c0.3-0.9,0.4-1.6,0.6-2.3
    C97.7,126.1,98.6,126.2,99.5,126.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M84.8,140.6c0.6-0.4,1.7-1.3,3-2.8
    c1.6-1.7,2.5-5.3,3-7.7c0.3-1,0.5-1.9,0.6-2.2c0.4-0.7,0.9-2,1.4-2.9c0.5,0.2,1,0.4,1.6,0.5c-0.3,0.5-0.6,1.3-0.9,2.6
    c-0.2,0.6-0.3,1.1-0.4,1.5c-0.5,1.7-0.8,2.9-1.5,4.7c-0.9,2.2-1.5,3.2-2.1,4.4l-0.1,0.1c-0.4,0.8-1.4,2-2,2.8
    C86.5,141.3,85.6,141,84.8,140.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.9,138.6c4-4.3,7.5-9,10.7-13.9
    c-0.7,1.1-1.8,3.1-2.6,4.8c-0.5,1-0.7,1.7-0.9,2.2c-0.3,0.9-0.6,1.6-1.6,3.3c-1.1,1.9-3,3.7-3.9,4.5
    C82,139.2,81.5,138.9,80.9,138.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M90.6,124.1c-3.1,5-6.6,9.7-10.5,14
    c-0.6-0.4-1.2-0.8-1.8-1.2c0.7-0.4,1.6-1.2,2.2-2.4c1.1-1.9,2-3,3.8-5.1l0.6-0.8c1.7-2,3.5-4.6,4.2-5.7
    C89.6,123.4,90.1,123.7,90.6,124.1C90.7,124,90.6,124.1,90.6,124.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M74.3,133.6c0.4-0.7,1.3-1.9,2.8-3.4
    c1.7-1.7,2.2-2.5,3-3.5c0.3-0.4,0.6-0.8,1.1-1.4c1.3-1.7,1.7-2,2.7-2.9l0.4-0.4c0.9-0.9,1.5-1.5,1.7-1.8c0.5,0.5,1,1,1.5,1.5
    c-0.2,0.4-0.7,1-1.5,1.7c-0.5,0.5-1,0.8-1.4,1.2c-1,0.8-2,1.5-3,3.5c-0.3,0.7-0.6,1.2-0.8,1.7c-0.7,1.5-0.9,2-2.1,3
    c-0.9,0.8-2.1,1.7-2.8,2.2C75.4,134.6,74.8,134.1,74.3,133.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M68.8,126.9c0.8-0.4,2.8-1.6,3.9-2.5
    c0.3-0.2,0.5-0.4,0.7-0.5c0.9-0.6,1.4-1,3.5-2.1c2.3-1.1,6-3.5,6.9-4.2c0.3,0.5,0.7,1,1,1.4c-0.5,0.2-1.4,0.8-3,1.9
    c-0.5,0.3-1,0.6-1.4,0.9c-1.6,1-2.7,1.7-3.9,3.1c-0.5,0.6-0.8,1.1-1.1,1.5c-0.6,0.9-1,1.4-1.9,1.8c-1,0.4-2.4,0.9-3.1,1.1
    C69.9,128.6,69.4,127.8,68.8,126.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M65.7,120.6c0.5-0.6,2.3-2.4,5.3-3.1
    c1.6-0.3,2.4-0.4,3.1-0.5c0.9-0.1,1.6-0.2,3.5-0.8c2-0.6,3.7-1.3,4.7-1.7c0.2,0.4,0.4,0.8,0.6,1.2c-1.1,0.8-5.8,4.1-7.2,4.5
    c-0.7,0.2-1.1,0.3-1.5,0.4c-0.6,0.1-1.2,0.3-2.3,0.7c-1.2,0.4-2,0.8-2.7,1c-0.4,0.1-0.7,0.3-1,0.4c-0.5,0.2-1.1,0.1-1.5,0.1
    C66.3,122.1,66,121.4,65.7,120.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M64.1,114.4l0-0.2c0.9-0.3,3-1.1,3.5-1.1
    c0.7,0,2.9-0.3,5.4-1.1c1.6-0.5,2.2-0.6,3-0.7c0.4-0.1,0.8-0.1,1.5-0.3c1.3-0.3,2.7-0.7,3.4-1c0.1,0.8,0.3,1.6,0.6,2.4
    c-0.9,0.3-2.5,0.8-3.8,1.2c-0.4,0.1-0.8,0.2-1.2,0.3c-1.3,0.3-2.4,0.6-4.2,1.3c-1.6,0.6-3.5,1.1-5.7,1.3c-0.6,0.1-1.6-0.3-2.1-0.5
    C64.3,115.5,64.2,115,64.1,114.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M63.7,107l0.2-1.1c0.7,0,3.7,0,5.3,0.3
    c0.6,0.1,1.1,0.2,1.5,0.3c0.8,0.2,1.4,0.3,2.3,0.3c1.1,0.1,5.8,0.4,7.6,0.5c0,0.3,0,0.5,0,0.8c0,0.2,0,0.3,0.1,0.5
    c-1.1,0-4.1,0-5.2,0.1c-0.3,0-0.5,0-0.8,0.1c-1.2,0.1-2.7,0.2-4.5,0.2c-0.9,0-1.5,0-1.9,0c-0.6,0-0.9,0-1.5,0
    c-0.9-0.1-2.5-0.5-3.1-0.7C63.6,107.9,63.6,107.4,63.7,107z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.9,103.5c-0.5-0.3-1.2-0.8-2.1-1.4
    c-0.8-0.5-1.9-0.9-3.1-1.2c-1.2-0.4-2.5-0.8-3.1-1.3c-1.1-0.9-5.3-2.4-6.5-2.8c0.3-0.7,0.7-1.5,1.1-2.2c0.6,0.4,1.8,1,3.3,1.7
    c1.3,0.5,2,1,2.9,1.6c0.7,0.5,1.6,1,2.9,1.7c2.2,1.1,4.1,2.1,5,2.5C81.2,102.5,81.1,103,80.9,103.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M82.1,100.4c-0.6-0.6-1.5-1.4-2.1-2.1
    c-1-1-3.9-3.2-4.9-3.9c-0.4-0.3-1.1-0.6-1.7-1c-0.8-0.4-1.6-0.9-1.8-1.1C71.4,92,70.4,91,70,90.5c0.5-0.6,1-1.2,1.5-1.8
    c1.1,0.9,4.6,3.5,5.8,4.8c0.6,0.6,1.1,1.1,1.5,1.5c0.7,0.6,1.3,1.2,2.1,2.2c0.9,1,1.5,1.6,1.9,1.9C82.6,99.5,82.3,100,82.1,100.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M84.6,96.8c-0.5-1.1-1.6-3.3-2.1-3.6
    c-0.5-0.4-2.9-3.8-3.5-5c-0.4-0.9-2.1-3-2.8-3.8c0.9-0.6,1.8-1.2,2.7-1.7c0.9,1.1,2.8,3.4,3.6,4.8c0.4,0.8,0.5,1,0.6,1.2
    c0.1,0.3,0.2,0.6,1.4,2.3c1.1,1.7,1.8,3.1,2.2,3.9C86,95.5,85.3,96.1,84.6,96.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M90.1,78.9c0.3,1,1,3.2,1.2,4.9
    c0.3,2.5,0.7,5.5,1,6.2c0.2,0.4,0.3,1.6,0.3,2.5c-0.5,0.1-1,0.2-1.5,0.3c0.2-0.8,0.3-2.2-0.4-3.8c-0.9-2.1-2.4-7.6-3-9.7
    C88.6,79.1,89.4,79,90.1,78.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.6,87.1c0-0.9-0.1-1.7-0.1-2.5
    c-0.1-0.8-0.2-1.7-0.2-2.6c0-1.2-0.2-2.6-0.3-3.4c1,0,2,0.1,3,0.2c-0.1,0.9-0.2,2.8-0.3,3.3c-0.1,0.7-0.3,4.9-0.3,6.3
    c0,1-0.1,2.8-0.2,3.9c-0.5,0-1-0.1-1.5,0C94.7,91,94.6,88.3,94.6,87.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.4,80.2c-0.1,0.6-0.5,2.7-0.6,4.7
    c-0.1,1.3-0.3,2.2-0.6,3.2c-0.2,0.7-0.4,1.4-0.4,2.2c-0.1,1.1-0.4,2.5-0.5,3.3c-0.6-0.3-1.2-0.5-1.8-0.7c0.2-1.1,0.6-2.9,0.6-3.8
    c0.1-1.2,0.5-4.1,0.8-5.6c0.2-1.1,0.3-3,0.4-3.9C101,79.7,101.7,79.9,102.4,80.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M113.3,88.7c0.3,1.1,0.3,2.7-0.2,3.3
    c-0.3,0.4-1.1,1.1-2,1.9c-1,0.9-2,1.7-2.5,2.4c-0.8,1-2.1,2.4-2.7,3.1c-0.2-0.6-0.5-1.1-0.9-1.6l1.8-1.2l0,0c1.5-3.4,4-8.6,4.8-9.9
    C112.3,87.3,112.8,88,113.3,88.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M117.3,98.4c-0.1,0.4-0.3,0.8-0.4,0.9
    c-0.1,0.1-0.3,0.1-0.6,0.2c-0.6,0.2-1.7,0.5-3.5,1.2c-1.3,0.5-1.9,0.8-2.3,1c-0.4,0.2-0.6,0.3-1.1,0.5c-0.6,0.2-1.6,0.4-2.5,0.6
    c0-0.4-0.1-0.8-0.2-1.2c0.5-0.2,1.1-0.5,2-1.1c0.9-0.6,1.4-1.1,1.9-1.6c0.3-0.3,0.7-0.7,1.3-1.2c0.5-0.5,0.8-0.8,1.1-1
    c0.4-0.4,0.6-0.6,1.4-0.8c0.8-0.2,1.5-0.7,2-1C116.8,96,117.1,97.2,117.3,98.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M116.9,107.4l-1.4,0.8c-0.4-0.1-2.1-0.4-4-0.7
    c-0.9-0.1-1.4-0.2-1.8-0.3c-0.5-0.1-0.8-0.2-1.5-0.2c-0.6,0-1.2-0.1-1.7-0.1c0.2-0.6,0.3-1.1,0.4-1.7c0.5-0.1,1.1-0.2,1.7-0.4
    c0.6-0.2,1-0.3,1.3-0.4c0.6-0.2,0.9-0.4,2.3-0.2c1.1,0.1,1.6,0.3,2,0.4c0.4,0.1,0.8,0.2,1.6,0.2c0.7,0,1.2,0.1,1.6,0.1
    C117.3,105.7,117.2,106.5,116.9,107.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M112.9,114.7c-0.4,0-1.2-0.2-2-0.9
    c-0.7-0.7-1.4-1.2-2-1.6c-0.4-0.3-0.7-0.5-1-0.7c-0.5-0.4-0.9-0.7-1.2-0.9c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.2-0.7-0.4-1.1-0.5
    c0.3-0.4,0.5-0.7,0.7-1.1c1,0.2,2.4,0.5,3.6,0.9c0.9,0.3,1.2,0.4,1.5,0.5c0.4,0.1,0.5,0.1,1.5,0.7c1,0.5,1.8,1.1,2.4,1.4
    C114.2,113.1,113.6,113.9,112.9,114.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.1,120.9l-0.6-1.8c0-0.1,0.1-0.5,0-1.4
    c-0.1-0.7-0.4-1.5-0.6-2.1c-0.1-0.3-0.2-0.6-0.3-0.7c-0.1-0.3-0.2-0.7-0.4-1.4c0.4-0.1,0.7-0.2,1.1-0.3c0.2,0.3,0.4,0.6,0.6,0.9
    c0.1,0.2,0.2,0.3,0.3,0.4c0.2,0.4,0.2,0.4,0.4,0.9c0.1,0.2,0.2,0.4,0.3,0.8c0.4,1.1,0.7,3.3,0.8,4.5
    C102.2,120.8,101.6,120.9,101.1,120.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M96.4,118.4c0.1,0.4,0.2,0.7,0.2,1
    c0,0.4-0.2,1-0.4,1.4c-0.6-0.1-1.1-0.2-1.7-0.4c0.1-0.6,0.2-1.9,0.3-2.9c0-0.5,0-0.9,0-1.3c0-0.5,0-0.9,0.1-1.5
    c0.1-0.6,0.2-1.1,0.2-1.5c0.3,0.1,0.6,0.2,1,0.2v3.2C96.1,117.4,96.2,118,96.4,118.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M89.1,117.4c0.2-0.8,0.4-1.8,0.7-2.1
    c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.4,0.6-0.6,1-1.5c0.4-0.7,0.8-1.7,1-2.2c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.7,0.5,1.1,0.8
    c-0.2,0.4-0.4,0.9-0.5,1.4l-0.1,0.2c-0.2,0.9-0.3,1-0.8,1.7l-1.8,2.4C89.8,118,89.5,117.7,89.1,117.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M84.8,111c0.3-0.3,0.6-0.6,0.9-0.6
    c0.6,0,0.9-0.1,1.2-0.1c0.1,0,0.2,0,0.4-0.1c0.5-0.1,2.1-1,2.8-1.5c0.2,0.5,0.5,1,0.8,1.4c-0.4,0.5-1.4,1.4-1.6,1.6
    c-0.1,0.1-0.3,0.1-0.6,0.1c-0.4,0-0.8,0-1.3,0.2c-0.6,0.3-1.6,0.4-2,0.4C85.1,112,84.9,111.5,84.8,111z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M83.9,106.2c0-0.6,0.1-1.2,0.2-1.8
    c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0,0.5,0.1,0.9,0.1c0.7,0.1,1.7,0.2,2,0.3c0.3,0.1,1.1,0.2,1.8,0.3c0,0.4,0,0.7,0,1.1c0,0,0,0,0,0.1
    c-0.5,0.2-1.2,0.4-1.5,0.4c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0-1,0c-0.9-0.1-2-0.1-2.7-0.1C84,106.6,83.9,106.4,83.9,106.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M87.1,98.1c0.3-0.3,0.5-0.5,0.8-0.8
    c0,0,0,0.1,0.1,0.1c0.3,0.6,0.8,1.7,1.3,2.2c0.6,0.5,2,1.6,2,1.6l0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.4-0.6,0.8-0.8,1.2
    c-0.3-0.1-0.6-0.3-1-0.4c-0.5-0.2-1-0.4-1.2-0.5c-0.4-0.3-1.4-0.7-1.6-0.8l-0.7-1.1L87.1,98.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M91.5,108.5c1.2,2.4,3.8,3.9,6.6,3.8
    c-0.1,0-0.1,0-0.2,0c-1.7,0.1-3.4-0.4-4.8-1.5C92.4,110.1,91.9,109.3,91.5,108.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M93.9,94.5c0.5,0.1,1.2,0.6,1.6,0.9
    c0,0.2,0.1,0.9,0.2,1.4c0.1,0.5,0.1,0.5,0.1,1.1c0,0.3,0.1,1,0.2,1.6c-0.2,0-0.3,0-0.5,0c-0.5,0-1,0.1-1.4,0.3
    c-0.5-1-1.2-2.5-1.2-2.7c-0.1-0.2-0.6-1.5-0.9-2.1C92.5,94.8,93.2,94.6,93.9,94.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99.3,95.1l0.9,0.4c0.3,0.2,0.6,0.3,0.8,0.5
    l-0.7,1.3c-0.1,0.2-0.8,1.5-0.9,1.8c0,0.1-0.3,0.6-0.5,1.1c-0.3-0.2-0.7-0.4-1.1-0.5l0.5-1.6l0-1.9c0-0.2,0.2-0.9,0.4-1.4
    C98.9,94.9,99.1,95,99.3,95.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M104.5,100.6c-0.2,0.2-0.8,0.6-1.1,0.8
    c-0.3,0.1-0.6,0.5-0.8,0.8c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.7,0.4-1.1,0.6c-0.1-0.5-0.3-1-0.6-1.5l1-0.8l0.1-0.1
    c0,0,0.5-1.2,0.6-1.5c0.1-0.2,0.4-1.1,0.7-1.7C103.5,98.5,104.1,99.5,104.5,100.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M104.8,105.3c-0.3,1.2-0.9,2.3-1.7,3.2
    c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.4-0.3c-0.6-0.4-1.2-0.8-1.5-1.1c0.2-0.3,0.3-0.6,0.4-1l0.8,0.3
    l-0.7-0.5c0.1-0.3,0.1-0.7,0.1-1c0.4,0.4,0.9,0.7,1.2,0.6c0.3-0.1,0.4-0.2,0.5-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.2,0,0.4-0.1,0.6-0.2
    c0.3-0.1,0.5-0.2,0.8-0.2h0.3L104.8,105.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M97.3,106.8l0.7-2l0.4,0l1.2,0.5
    C99.2,106.2,98.4,106.8,97.3,106.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M92.4,107.2l1.1-0.7c0.2,0.5,0.5,1,0.8,1.3
    l-1.1,0.8C92.8,108.3,92.6,107.8,92.4,107.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M92,104.7c0.1-0.5,0.2-0.9,0.4-1.3l1.2,0.7
    c-0.3,0.5-0.4,1-0.4,1.6L92,104.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.9,101.1l0.8,1.2c-0.2,0.1-0.4,0.2-0.6,0.3
    c-0.1,0-0.2,0.1-0.3,0.1l-0.7-1.3C94.4,101.3,94.6,101.2,94.9,101.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M98.2,101.6l0.4,0.4c0.2,0.2,0.4,0.4,0.6,0.7
    l-0.5,0.5c-0.2-0.4-0.6-0.6-1-0.8L98.2,101.6C98.2,101.5,98.2,101.5,98.2,101.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99.2,104.7l-0.5-0.2c0,0,0,0,0-0.1h0.1
    L99.2,104.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M97,106.8c-0.6-0.1-1.1-0.5-1.4-1l1.6-1.7
    c0.2,0.2,0.3,0.4,0.3,0.7l0.2,0L97,106.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M95.3,104.9c0-0.4,0.2-0.8,0.5-1.1
    c0.1,0,0.2-0.1,0.4-0.1c0.3,0,0.5,0.1,0.8,0.2l-1.5,1.6C95.3,105.3,95.3,105.1,95.3,104.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99,104.1c0-0.2-0.1-0.4-0.2-0.6l0.6-0.6
    c0.2,0.5,0.4,1,0.4,1.5c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2L99,104.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M97.4,102.2c-0.2-0.1-0.4-0.1-0.6-0.1
    c-0.2,0-0.5,0-0.7,0.1l-0.8-1.2c0.4-0.1,0.8-0.2,1.2-0.2c0.5,0.1,1,0.2,1.4,0.5L97.4,102.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M93.8,101.7l0.7,1.3c-0.3,0.2-0.5,0.5-0.7,0.7
    l-1.2-0.7C92.9,102.5,93.3,102,93.8,101.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M92,105.3c0,0,0-0.1,0-0.1l1.3,0.9
    c0,0,0,0.1,0,0.1l-1.1,0.7C92.1,106.4,92,105.8,92,105.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.5,108.1c0.5,0.4,1,0.7,1.7,0.8l-0.3,1
    l-0.3,0.7c-0.9-0.3-1.6-0.9-2.2-1.6L94.5,108.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M97.7,111c-0.6,0-1.2-0.1-1.8-0.3l0.3-0.7
    l0.3-1c0.2,0,0.4,0,0.6,0c0.6,0,1.1-0.2,1.6-0.4c0.2,0.4,0.4,0.8,0.5,1c0.1,0.2,0.2,0.8,0.3,1.2C98.9,110.9,98.3,111,97.7,111z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99.9,110.7c-0.1-0.4-0.2-1-0.3-1.2
    c-0.1-0.2-0.3-0.6-0.5-1c0.5-0.3,1-0.8,1.3-1.3c0.4,0.3,0.9,0.7,1.5,1.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0,0,0,0,0l0,0c0,0,0,0,0,0
    c0,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.2C102.1,109.7,101,110.3,99.9,110.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M105,104h-0.4c-0.3,0-0.6,0.1-0.9,0.2
    c-0.2,0.1-0.4,0.1-0.6,0.2c-0.3,0.1-0.4,0.2-0.5,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0-0.6-0.3-1.1-0.7c0-0.2,0-0.4-0.1-0.6
    c0.4-0.2,1-0.5,1.2-0.6c0.2-0.1,0.3-0.3,0.5-0.5c0.2-0.3,0.4-0.5,0.7-0.6c0.4-0.1,0.8-0.5,1.1-0.7c0.3,0.9,0.4,1.8,0.4,2.7
    C105,103.8,105,103.9,105,104z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.7,99.1c-0.1,0.3-0.5,1.2-0.6,1.5l-1,0.8
    c-0.3-0.4-0.6-0.7-1-1c0.2-0.5,0.5-1,0.6-1.2c0.1-0.2,0.7-1.4,0.9-1.8l0.6-1.2c0.4,0.3,0.8,0.7,1.2,1
    C102.3,97.7,101.8,98.9,101.7,99.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M97.9,96.3V98l-0.4,1.6
    c-0.3-0.1-0.6-0.1-1-0.1c0,0-0.1,0-0.1,0c-0.1-0.6-0.2-1.3-0.2-1.6c0-0.6,0-0.6-0.1-1.1c-0.1-0.5-0.2-1.4-0.2-1.4l0-0.1l-0.1,0
    c-0.1-0.1-0.6-0.5-1.2-0.8c0.4,0,0.8-0.1,1.2-0.1c0.9,0,1.8,0.1,2.6,0.4C98.2,95.2,97.9,96,97.9,96.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M91.5,95.2c0.3,0.7,0.9,1.9,0.9,2.1
    c0.1,0.2,0.8,1.8,1.2,2.7c-0.7,0.3-1.4,0.7-2,1.2l-0.2-0.2c0,0-1.4-1.1-2-1.6c-0.5-0.4-1-1.5-1.2-2c0-0.1-0.1-0.2-0.1-0.2
    C89.2,96.3,90.3,95.6,91.5,95.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.7,112c2.2,1.6,4.3,3.9,4.4,6.8
    c0,0.1,0.2,0.2,0.5,0.1c-0.5,0.3-1,0.5-1.5,0.7c-0.1-0.4-0.2-0.9-0.5-1.7c-0.3-0.8-0.4-1-0.8-1.4c-0.2-0.3-0.4-0.6-0.9-1.2
    c-0.7-1-1.5-2.1-2.1-2.8C102.2,112.3,102.4,112.2,102.7,112z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M108.7,118.3c-0.2-3-2.5-5.5-4.9-7.1
    c0.3-0.3,0.6-0.5,0.8-0.8c0.4,0.4,0.9,1,1.5,1.3c0.9,0.5,1.1,0.7,2.1,1.7c1,1,1.7,1.8,2.2,2.5c0.3,0.4,0.4,0.7,0.5,0.9
    C110.1,117.3,109.4,117.8,108.7,118.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.8,95.1c0.1,0,0.1-0.1,0.2-0.1
    c2.4-3.6,3-8,5.1-11.7c0.3,0.2,0.6,0.5,0.9,0.7c-0.5,1-1.5,3.5-2,5.2c-0.5,1.7-2.4,5.2-3.3,6.7C103.4,95.7,103.1,95.4,102.8,95.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M107.1,82.6c-2.3,3.5-3,7.7-4.9,11.3
    c0.3-1.8,1.3-7.6,2-9.3c0.5-1.4,1-2.5,1.2-3C106,81.9,106.6,82.3,107.1,82.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M85.5,100.3l0.8,1.1l0.1,0
    c0,0,1.2,0.5,1.6,0.8c0.2,0.2,0.7,0.4,1.3,0.6c0.4,0.1,0.8,0.3,1,0.4c-0.3,0.6-0.4,1.1-0.5,1.7c-0.6-0.1-1.4-0.2-1.7-0.3
    c-0.4-0.1-1.3-0.2-2.1-0.3c-0.3,0-0.7-0.1-0.9-0.1c-0.3,0-0.6-0.2-0.8-0.3C84.4,102.6,84.9,101.4,85.5,100.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M86.7,107.2c0.9,0.1,1,0,1.1,0
    c0.1,0,0.1,0,0.3,0c0.4,0,1.1-0.2,1.5-0.4c0.1,0.6,0.2,1.1,0.4,1.6c-1,0.6-2.4,1.4-2.8,1.5c-0.1,0-0.3,0-0.4,0.1
    c-0.4,0.1-0.7,0.1-1.2,0.1c-0.4,0-0.8,0.3-1,0.5c-0.4-1.1-0.6-2.3-0.6-3.5C84.7,107.1,85.8,107.2,86.7,107.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M87.6,112.5c0.4-0.2,0.8-0.2,1.1-0.2
    c0.3,0,0.6,0,0.8-0.2c0.3-0.2,1.2-1.1,1.6-1.6c0.2,0.3,0.4,0.5,0.6,0.7c-0.2,0.5-0.6,1.5-1,2.3c-0.4,0.8-0.6,1-1,1.4
    c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,1.3-0.8,2c-1.3-1.2-2.4-2.7-3.3-4.3C86.1,112.8,87,112.7,87.6,112.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M92.3,116.1c0.6-0.8,0.6-0.9,0.9-1.9l0.1-0.2
    c0.1-0.5,0.3-1,0.5-1.4c0.3,0.2,0.7,0.3,1.1,0.5c0,0.3-0.1,0.9-0.2,1.5c-0.1,0.6-0.1,1.1-0.1,1.6c0,0.4,0,0.7,0,1.3
    c-0.1,0.9-0.2,2.2-0.3,2.8c-1.3-0.4-2.6-1-3.7-1.8L92.3,116.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M96.7,118.3c-0.1-0.4-0.3-1-0.3-1.6v-3.2
    c0.5,0.1,1.1,0.1,1.6,0c0.3,0,0.5-0.1,0.8-0.1c0.3,0.9,0.4,1.3,0.4,1.4c0,0.2,0.1,0.5,0.3,0.8c0.2,0.6,0.6,1.3,0.6,2
    c0.1,1,0,1.3,0,1.3l0,0.1l0.6,1.8c-0.6,0.1-1.3,0.1-1.9,0.1c-0.8,0-1.5-0.1-2.2-0.2c0.2-0.4,0.4-1,0.4-1.4
    C96.9,119.1,96.8,118.7,96.7,118.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M103,120.6c-0.1-1.2-0.4-3.3-0.8-4.5
    c-0.1-0.3-0.2-0.6-0.3-0.8c-0.2-0.5-0.2-0.5-0.5-0.9c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.3-0.4-0.6-0.5-0.9c0.3-0.1,0.7-0.3,1-0.4
    c0.6,0.7,1.4,1.8,2.1,2.8c0.4,0.7,0.7,1,0.9,1.3c0.3,0.4,0.4,0.5,0.7,1.3c0.3,0.8,0.4,1.4,0.5,1.7C104.9,120.1,104,120.4,103,120.6
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M111.1,116.5c-0.1-0.2-0.2-0.5-0.5-0.8
    c-0.5-0.7-1.2-1.5-2.2-2.5c-1-1-1.2-1.2-2.2-1.7c-0.5-0.3-1.1-0.9-1.4-1.3c0.4,0.1,0.9,0.3,1.1,0.5c0.1,0.1,0.3,0.2,0.5,0.3
    c0.3,0.2,0.8,0.5,1.2,0.9c0.3,0.2,0.6,0.5,1,0.8c0.6,0.4,1.3,0.9,2,1.6c0.7,0.7,1.4,0.9,1.9,1C112.1,115.5,111.6,116,111.1,116.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M115,111.9c-0.6-0.4-1.4-0.9-2.4-1.5
    c-1-0.6-1.2-0.6-1.6-0.7c-0.3-0.1-0.6-0.2-1.5-0.5c-1.2-0.5-2.6-0.8-3.6-0.9c0.2-0.4,0.3-0.7,0.5-1.1c0.5,0.1,1.2,0.1,1.8,0.1
    c0.7,0,0.9,0.1,1.5,0.2c0.4,0.1,0.9,0.2,1.8,0.3c2.1,0.3,4,0.7,4,0.7l0.1,0l1.2-0.7C116.3,109.3,115.7,110.6,115,111.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M115.8,104.3c-0.8-0.1-1.1-0.1-1.5-0.2
    c-0.4-0.1-0.9-0.2-2-0.4c-1.6-0.2-1.9-0.1-2.5,0.2c-0.3,0.1-0.7,0.3-1.3,0.4c-0.6,0.2-1.2,0.3-1.6,0.4c0-0.5,0-1,0-1.5c0,0,0,0,0,0
    c0.8-0.2,1.9-0.5,2.5-0.6c0.6-0.2,0.8-0.3,1.2-0.5c0.4-0.2,1-0.5,2.2-1c1.8-0.7,2.8-1,3.5-1.2c0.3-0.1,0.5-0.1,0.7-0.2
    c0.1-0.1,0.3-0.3,0.4-0.5c0.1,1,0.2,2.1,0.2,3.1c0,0.7,0,1.5-0.1,2.2C117.1,104.4,116.5,104.4,115.8,104.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M114.3,95.5c-0.9,0.3-1.1,0.5-1.5,0.9
    c-0.2,0.2-0.5,0.6-1.1,1c-0.6,0.5-1,0.9-1.3,1.2c-0.5,0.5-1,1-1.9,1.6c-0.8,0.5-1.5,0.8-1.9,1c-0.1-0.5-0.3-1-0.4-1.4
    c0.6-0.6,1.9-2.2,2.8-3.2c0.6-0.7,1.6-1.5,2.5-2.4c0.9-0.8,1.7-1.5,2-2c0.4-0.5,0.5-1.7,0.4-2.8c1,1.6,1.8,3.2,2.5,5
    C115.8,94.8,115.1,95.2,114.3,95.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M106.6,96.3l-1.7,1.2c-0.3-0.4-0.6-0.9-1-1.3
    c0.8-1.4,2.9-5.1,3.4-6.9c0.5-1.7,1.5-4,2-5.1c0.8,0.6,1.5,1.3,2.2,2.1C111,87.1,109.8,89.4,106.6,96.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M103.9,84.5c-0.7,2-1.8,8.5-2,9.8l-0.3-0.1
    c-0.3-0.2-0.6-0.4-1-0.6c0.2-0.8,0.5-2.2,0.6-3.4c0-0.7,0.2-1.4,0.4-2.1c0.2-0.9,0.5-1.9,0.6-3.2c0.1-1.9,0.4-3.9,0.6-4.6
    c0.8,0.3,1.6,0.7,2.4,1.1C104.9,82,104.4,83,103.9,84.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99.6,83.3c-0.3,1.5-0.7,4.4-0.8,5.7
    c-0.1,0.8-0.4,2.6-0.6,3.7c-0.5-0.1-1-0.2-1.6-0.3c0.1-1.1,0.2-3,0.2-4c0-1.4,0.2-5.5,0.3-6.2c0.1-0.5,0.2-2.4,0.3-3.3
    c0.9,0.1,1.7,0.3,2.6,0.5C100,80.2,99.8,82.2,99.6,83.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M93.7,78.6c0.1,0.8,0.3,2.2,0.3,3.4
    c0,0.9,0.1,1.8,0.2,2.6c0.1,0.8,0.1,1.6,0.1,2.4c0,1.3,0.1,3.9,0.2,5.2c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.1-1,0.1
    c-0.1-0.8-0.2-2.1-0.4-2.6c-0.3-0.8-0.8-4.6-1-6.1c-0.2-1.7-0.8-3.8-1.2-4.9C91.5,78.7,92.6,78.6,93.7,78.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M87.4,79.4c0.6,2.2,2,7.7,3,9.8
    c0.8,1.7,0.6,3,0.3,3.8c-0.4,0.1-0.7,0.2-1.1,0.4c-1.8-4.4-3.9-8.6-5.5-13.1C85.3,79.9,86.4,79.6,87.4,79.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M88.3,94c-0.4,0.2-0.8,0.5-1.2,0.7
    c-0.4-0.8-1-2.2-2.2-3.9c-1.1-1.7-1.2-2-1.4-2.3c-0.1-0.2-0.2-0.5-0.6-1.2c-0.8-1.4-2.6-3.7-3.6-4.8c1.1-0.6,2.3-1.2,3.5-1.7
    C84.4,85.3,86.5,89.6,88.3,94z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76,84.6c0.7,0.8,2.3,2.9,2.7,3.7
    c0.5,1.1,3,4.7,3.6,5.1c0.4,0.2,1.4,2.2,2,3.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.4-0.7,0.9-1,1.4c-0.4-0.3-1-0.8-1.9-1.8
    c-0.8-1-1.5-1.6-2.1-2.3c-0.5-0.4-0.9-0.9-1.5-1.5c-1.2-1.3-4.6-3.9-5.8-4.8C73,87,74.5,85.8,76,84.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.7,90.8c0.4,0.5,1.3,1.5,1.5,1.8
    c0.2,0.3,0.9,0.7,1.9,1.2c0.6,0.3,1.2,0.6,1.7,0.9c0.9,0.6,3.8,2.8,4.8,3.8c0.7,0.7,1.6,1.6,2.2,2.1c-0.1,0.3-0.3,0.6-0.4,1
    c-0.9-0.4-2.8-1.4-4.9-2.5c-1.3-0.7-2.2-1.2-2.9-1.6c-0.9-0.6-1.7-1-3-1.6c-1.5-0.7-2.7-1.3-3.3-1.7C68.1,93.1,68.9,91.9,69.7,90.8
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M66,97.1c1.1,0.4,5.4,1.9,6.4,2.8
    c0.7,0.6,1.9,0.9,3.2,1.4c1.1,0.4,2.2,0.7,3,1.2c1,0.6,1.8,1.2,2.2,1.5c-0.1,0.3-0.1,0.7-0.2,1c-0.1-0.1-0.2-0.1-0.4-0.2
    c-5.3-0.8-10.3-3-15.4-4.5c0,0,0,0,0,0C65.2,99.2,65.6,98.2,66,97.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M79.8,105.8c0.3,0,0.7-0.1,0.9-0.3
    c0,0.5-0.1,0.9-0.1,1.4c-1.9-0.1-6.5-0.4-7.6-0.5c-0.9-0.1-1.5-0.2-2.3-0.3c-0.4-0.1-0.9-0.2-1.5-0.3c-1.7-0.3-4.7-0.3-5.4-0.3
    c0.2-1.4,0.4-2.7,0.7-4.1C69.6,103,74.5,105,79.8,105.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M63.6,108.6c0.7,0.2,2.2,0.6,3.1,0.7
    c0.7,0.1,1,0.1,1.6,0c0.4,0,1,0,1.9,0c1.8,0,3.3-0.1,4.5-0.2c0.3,0,0.6,0,0.8-0.1c1.1-0.1,4.1-0.1,5.2-0.1c0,0.3,0.1,0.5,0.1,0.8
    c-0.7,0.2-2.1,0.7-3.4,1c-0.6,0.1-1,0.2-1.4,0.3c-0.7,0.1-1.4,0.2-3,0.7c-2.6,0.8-4.7,1.1-5.3,1.1c-0.6,0-2.9,0.8-3.6,1.1
    C63.8,112.2,63.6,110.4,63.6,108.6C63.6,108.7,63.6,108.7,63.6,108.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M66.5,116.9c2.2-0.2,4.2-0.7,5.8-1.3
    c1.8-0.7,2.9-1,4.2-1.3c0.4-0.1,0.8-0.2,1.2-0.3c1.3-0.3,2.9-0.9,3.8-1.2c0.2,0.5,0.3,0.9,0.5,1.4c-1,0.4-2.6,1-4.6,1.6
    c-1.8,0.6-2.5,0.6-3.4,0.8c-0.7,0.1-1.5,0.2-3.1,0.5c-2.9,0.6-4.7,2.3-5.3,3c-0.4-1.2-0.8-2.4-1.1-3.7
    C65,116.7,65.9,117,66.5,116.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M68.3,123.2c0.3-0.1,0.6-0.2,1-0.4
    c0.6-0.3,1.4-0.6,2.6-1c1.1-0.4,1.7-0.5,2.3-0.7c0.4-0.1,0.9-0.2,1.5-0.4c1.4-0.5,6-3.7,7.2-4.6c0.2,0.4,0.5,0.9,0.7,1.3
    c-1,0.6-4.7,3-6.9,4.2c-2.1,1.1-2.7,1.5-3.5,2.1c-0.2,0.2-0.4,0.3-0.7,0.5c-1.1,0.8-3.2,2-3.9,2.4c-0.7-1.1-1.3-2.2-1.8-3.3
    C67.3,123.3,67.8,123.3,68.3,123.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M73.7,128.6c1-0.4,1.4-1,2.1-1.9
    c0.3-0.4,0.6-0.9,1.1-1.5c1.2-1.4,2.2-2.1,3.8-3.1c0.4-0.3,0.9-0.6,1.4-0.9c1.7-1.1,2.6-1.6,3-1.9c0.2,0.2,0.4,0.4,0.6,0.6
    c-0.2,0.3-0.7,0.9-1.7,1.8l-0.4,0.4c-1,0.9-1.4,1.3-2.7,2.9c-0.4,0.6-0.8,1-1.1,1.4c-0.7,1-1.3,1.8-3,3.4c-1.5,1.5-2.3,2.7-2.8,3.4
    c-1.2-1.1-2.3-2.4-3.3-3.7C71.5,129.5,72.8,129,73.7,128.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.9,133.1c1.2-1,1.5-1.6,2.2-3.1
    c0.2-0.5,0.5-1,0.8-1.7c1-1.9,1.9-2.6,2.9-3.4c0.5-0.3,1-0.7,1.5-1.2c0.8-0.7,1.2-1.3,1.5-1.7c0.3,0.3,0.7,0.5,1,0.8
    c-0.7,1.1-2.5,3.6-4.2,5.6l-0.6,0.8c-1.8,2.1-2.7,3.2-3.8,5.1c-0.7,1.2-1.6,1.9-2.2,2.3c-0.6-0.4-1.2-0.9-1.7-1.4
    C76.9,134.7,78,133.9,78.9,133.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M86.8,135.2c1-1.7,1.3-2.4,1.6-3.3
    c0.2-0.6,0.5-1.2,0.9-2.2c0.8-1.8,2-3.9,2.7-5c0.2,0.1,0.3,0.1,0.5,0.2c-0.4,0.9-1,2.2-1.3,2.9c-0.2,0.4-0.4,1.2-0.7,2.3
    c-0.6,2.4-1.4,5.9-2.9,7.6c-1.5,1.6-2.6,2.5-3.2,2.8c-0.5-0.2-1-0.5-1.5-0.7C83.9,138.8,85.7,137.1,86.8,135.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M89.7,139l0.1-0.1c0.7-1.2,1.3-2.2,2.2-4.4
    c0.7-1.8,1.1-3.1,1.5-4.7c0.1-0.5,0.3-1,0.4-1.5c0.3-1.2,0.7-2,0.9-2.6c0.6,0.2,1.2,0.3,1.8,0.4c-0.1,0.6-0.3,1.4-0.6,2.2
    c-0.2,0.6-0.3,1.2-0.5,1.8c-0.4,1.6-0.9,3.6-2,6.3c-1.1,2.6-2.5,5.1-3.2,6.4c-0.9-0.2-1.7-0.5-2.6-0.8
    C88.3,141,89.2,139.8,89.7,139z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M99.7,141.1c0.1-0.5,0.1-0.9,0.2-1.4
    c0.2-1.1,0.4-2.2,0.4-5.2c0-2.3,0.1-3.4,0.2-4.1c0.1-0.6,0.1-0.9,0-1.5c-0.1-0.6-0.4-1.8-0.6-2.8c0.1,0,0.1,0,0.2,0
    c0.3,0,0.7-0.1,1-0.1c0,0.4,0,1,0.2,2c0.2,1.3,0.2,1.7,0.2,2.2c0,0.5,0,1.2,0.1,2.8c0.2,2.6,0.4,6.2,0.1,8.2
    c-0.2,1-0.3,2.1-0.5,2.9c-0.8,0-1.6,0-2.4-0.1C99.1,143.3,99.5,142.2,99.7,141.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M105,135.9c-0.1-0.6-0.3-1.3-0.4-2.3
    c-0.2-1-0.3-1.7-0.4-2.2c-0.4-1.9-0.4-1.9-0.9-4.1c-0.2-0.6-0.3-1.1-0.3-1.5c0.7-0.1,1.4-0.3,2-0.5c0.2,0.7,0.5,1.6,0.8,2.5
    c0.1,0.3,0.1,0.5,0.2,0.7c0.2,0.9,0.3,1.2,0.7,3.8c0.2,1.3,0.4,2.2,0.6,2.9c0.3,1,0.5,1.8,0.5,3.4c0,1.8,0.5,3.9,0.8,5.2
    c-1.1,0.2-2.2,0.3-3.3,0.3c0.1-1.3,0.2-3.1,0.2-4.5C105.3,137.7,105.3,137.7,105,135.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M111.5,139.3c-0.4-1-0.5-1.8-0.7-2.8
    c-0.2-0.9-0.3-1.9-0.7-3.3c-0.4-1.4-0.7-2.7-0.9-3.8c-0.2-1.1-0.4-2.1-0.7-3c-0.3-0.9-0.6-1.6-0.9-2.2c0.4-0.2,0.8-0.4,1.2-0.5
    c0.5,0.8,1.3,2,1.9,3.4c0.6,1.4,0.6,1.5,0.6,1.9c0,0.3-0.1,0.9,0,2.3c0.2,1.7,0.6,2.8,1,3.9c0.4,1,0.7,1.9,0.7,3.1
    c-0.1,1.6,0.4,3.5,0.7,4.5c-0.6,0.2-1.2,0.3-1.9,0.4C111.8,142.2,111.9,140.6,111.5,139.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M117.4,141.6c0-0.1,0-0.2,0-0.4
    c0-0.7-0.1-2-0.8-4.3c-0.3-1.2-0.4-1.7-0.5-2.2c-0.1-0.7-0.3-1.4-1.1-4.2c-1-3.2-1-3.2-1.2-3.3c0,0-0.1-0.1-0.3-0.4
    c-0.4-0.9-1.6-3.1-2.4-4.4c0.4-0.3,0.8-0.5,1.2-0.8c0.8,1,1.9,2.8,2.9,4.9c0.5,1.1,0.8,1.8,1.1,2.3c0.6,1.2,0.8,1.6,1.3,3l0,0.1
    c0.8,2.1,1.5,3.8,1.7,6.1c0.1,1.1,0.1,2.1,0,2.9C118.8,141.1,118.1,141.4,117.4,141.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M122.5,139.5c0-0.8-0.2-1.7-0.4-2.3
    c-0.3-0.7-0.3-1.1-0.4-1.7c-0.1-0.5-0.2-1.2-0.5-2.3c-0.5-2-0.6-2.2-1.4-3.8c-0.3-0.5-0.6-1.1-1-1.9c-1.7-3.3-2-3.7-2.8-4.7
    l-0.2-0.3c-0.6-0.7-1.3-1.6-1.8-2.2c0.5-0.4,1-0.9,1.5-1.4c0.2,0.4,0.5,0.8,0.8,1.3c0.3,0.5,0.7,1,1.1,1.6c0.5,0.7,1.1,1.5,1.9,2.7
    c1.4,2.1,1.8,2.7,3.1,5.6c0.3,0.7,0.6,1.2,0.8,1.6c0.6,1.3,0.8,1.7,1.2,3.7c0.4,1.6,0.4,2.6,0.3,3.1
    C124,138.8,123.3,139.1,122.5,139.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M127.5,136.7c0-0.9-0.1-2.2-0.3-3.1
    c-0.2-0.8-0.6-1.2-1.1-2.1c-0.4-0.6-1-1.5-1.7-2.8c-0.7-1.3-1-2-1.3-2.7c-0.4-0.9-0.7-1.6-1.7-3c-1.8-2.4-1.8-2.4-3.5-3.8
    c-0.6-0.5-0.8-1.4-0.8-2.3c0.3-0.3,0.5-0.7,0.8-1c2.4,2.5,5.5,4.1,7.2,7.3c0.9,1.7,1,4,1.6,5.9c0.6,2.2,1.8,4.3,2.6,6.4
    C128.7,135.9,128.1,136.3,127.5,136.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M128.2,128.7c-0.9-2.3-1-4.7-2.1-6.8
    c-1.6-3-5.2-4.5-7.4-7.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1c0.2,0.2,0.6,0.4,1,0.6c0.6,0.4,1.4,0.8,2.1,1.5
    c0.4,0.4,1,0.8,1.7,1.3c1.1,0.8,2.6,1.8,3.7,3.1c1.1,1.3,1.6,2.4,2.2,3.5c0.3,0.7,0.6,1.3,1.1,2c0.9,1.3,2.3,4.1,2.9,5.2
    c-0.9,0.9-1.9,1.7-2.9,2.5C129.8,132.5,129,130.6,128.2,128.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M136.4,129.1c-0.2-0.3-0.5-0.6-0.8-0.9
    c-0.6-0.5-1-1-1.6-1.7c-0.4-0.4-0.9-1-1.6-1.7c-0.9-1-1.3-1.6-1.7-2.3c-0.3-0.5-0.6-1.1-1.2-1.8c-1.2-1.5-4-3.4-6.1-4.5
    c-1.1-0.6-2.5-1.5-3.6-2.1c0.7,0.3,1.7,0.7,2.7,1.1c1.3,0.6,1.8,0.7,2.3,0.9c0.5,0.2,1,0.3,2.5,0.9l0.6,0.2c2.3,1,2.4,1,4.5,2.6
    c1.2,0.9,2.1,1.3,2.9,1.7c0.9,0.4,1.8,0.9,2.9,1.7c0.8,0.6,1.3,1,1.7,1.2C138.9,126.1,137.7,127.6,136.4,129.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M142.9,119.3c-0.6-0.3-1.6-0.9-3.1-1.6
    c-1.4-0.7-1.6-0.8-2.7-1c-0.7-0.1-1.7-0.3-3.6-0.8c-2.7-0.7-3.9-0.8-5-1c-1-0.1-1.9-0.2-3.6-0.7c-2.5-0.6-4-1.3-4.8-1.8
    c0.7,0.1,1.5,0.2,2,0.4c1,0.3,2.4,0.1,4-0.2c0.3-0.1,0.7-0.1,1-0.2c1.6-0.3,1.9-0.2,3.9,0.1l0.9,0.1c2,0.3,2.8,0.2,3.9,0
    c0.5-0.1,1-0.1,1.6-0.1c0.4,0,0.8,0,1.3-0.1c1.7-0.1,3.4-0.2,5.2,0c0.8,0.1,1.4,0,1.8-0.2C144.8,114.8,143.9,117.1,142.9,119.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M146.7,107.4c-0.9-0.3-3-1-5.3-0.7l-0.9,0.1
    c-2.3,0.2-2.9,0.3-7.2,1.5c-1.2,0.4-2.1,0.6-2.8,0.8c-2,0.6-2.4,0.7-4.7,1.6c-2.2,0.9-4,0.7-5.2,0.5c0.1-0.4,0.3-0.7,0.4-1.1
    c0.7-0.1,2.4-0.5,4.1-0.9c1-0.2,1.9-0.4,2.7-0.6c2.4-0.5,4-1.5,6.3-3.1c1.9-1.3,3.2-1.8,5.4-2.6c0.5-0.2,1.1-0.4,1.7-0.7
    c3.1-1.3,5.8-0.8,5.9-0.8l0.1,0l0.3-0.4C147.2,103.3,147,105.4,146.7,107.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M144.6,93.7c-1.9,0.5-3.8,1.4-6,2.4
    c-1,0.5-2.1,1-3.2,1.5c-3.7,1.6-8.2,4.1-9.7,5.2c-0.8,0.6-2.4,0.9-3.6,1.1c0-0.7,0-1.4,0-2.2c0-0.1,0-0.2,0-0.3
    c1.9-0.6,5.9-1.7,8.2-2.4c2.3-0.7,4.4-2.2,6.3-3.6c0.7-0.5,1.3-0.9,1.9-1.3c1.9-1.2,4.8-3.8,6.2-5c0.3-0.2,0.5-0.4,0.6-0.5
    c0.5-0.4,1.4-1.8,2.1-2.8c0.5,2.9,0.7,5.2,0.7,7C147.4,92.9,146.2,93.3,144.6,93.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M143.5,84.8c-1,0.7-2.3,2-3.8,3.4
    c-1.6,1.6-3.3,3.3-4.6,4.1c-1.5,1-4.3,2.4-6.3,3.5c-1.3,0.7-2.3,1.2-2.7,1.4c-0.6,0.4-3.1,1.1-4.5,1.3c-0.2-1.1-0.5-2.1-0.8-3.1
    c0.2-0.1,0.7-0.1,1.1-0.2c0.6-0.1,1-0.1,1.2-0.2c0.1,0,0.5-0.2,0.9-0.3c1.2-0.4,3.6-1.3,5.2-2.3c1.6-1,2.1-1.3,2.9-1.9
    c0.3-0.2,0.7-0.5,1.2-0.8c1-0.7,2.1-1.9,3.2-3.1c0.8-0.9,1.5-1.7,2.2-2.2c1.5-1.2,5.7-4.2,6-4.4c0.2-0.1,0.3-0.8,0.3-2.1
    c0.6,1,1.1,2.1,1.4,3.3C146,82.1,145,83.7,143.5,84.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M139.3,78.4c-0.5,0.7-1,1.3-1.4,2
    c-0.9,1.3-1.8,2.6-3.1,3.6c-1.4,1.1-4.2,3-6.7,4.7c-0.8,0.6-1.7,1.1-2.4,1.6c-1.9,1.3-4.6,2.1-6,2.4c-0.3-0.6-0.5-1.2-0.8-1.7
    c1.1-0.3,3.4-0.9,4.5-1.5c1.2-0.6,2.3-2.1,3-3.1c0.2-0.3,0.4-0.6,0.6-0.8c0.3-0.4,1.2-1,2.1-1.5c1.1-0.7,2.3-1.4,3-2.2
    c0.3-0.4,0.8-1.1,1.4-1.9c1.6-2.1,3.7-5,4.7-6c0.6-0.6,0.8-1.2,0.7-1.9c0-0.1-0.1-0.3-0.1-0.4c1.2,0.8,2.3,1.8,3.4,2.8
    C141.8,75,140.6,76.8,139.3,78.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M138.7,69.4l3.6-6.1c0.2,0.2,0.5,0.4,0.7,0.7
    c-1.1,2.1-2.2,4.1-3.3,6.2C139.4,69.8,139,69.6,138.7,69.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M142,63l-3.6,6.2c-0.7-0.5-1.5-1-2.2-1.4
    c0.9-1,2-2.3,2.1-2.5c0.1-0.3,1.4-2.7,2.1-3.7C140.9,62,141.5,62.5,142,63z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M119.6,59c-0.7-0.6-1.5-1.2-2.4-1.7
    c0.4-0.7,1.1-1.9,1.4-2.1c0.3-0.4,1-2.4,1.1-2.8l1.4-1.9c0.9,0.3,1.8,0.7,2.7,1c-0.6,1.6-2.2,5.9-2.3,6.2c-0.1,0.2-0.6,1-1.1,1.8
    C120.1,59.4,119.8,59.2,119.6,59z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M117.1,59.9c-0.4,1.9-1.5,6.4-1.6,7.2
    c-0.1,0.5-0.9,2.7-1.6,4.5c-0.5,1.2-0.8,2.3-0.9,2.6c-0.2,0.7-1.8,5-2.1,5.7c-0.2,0.4-0.6,0.5-0.9,0.6c-0.3-0.2-0.6-0.4-0.9-0.6
    c0.5-1.3,2.7-7.2,3.2-9.4c0.4-1.5,0.6-3.9,0.8-6.3c0.1-1.5,0.3-2.9,0.4-3.9c0.1-0.8,0.2-1.8,0.2-2.4
    C114.9,58.4,116,59.1,117.1,59.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M109.3,56.6c0,0.5-0.1,0.9-0.1,1.4
    c-0.5,0-1,0.3-1.1,0.5c-0.5,6.4-1,12.8-3.7,18.7c-0.2-0.1-0.4-0.2-0.6-0.3c0.3-1,1.6-5.1,1.8-6.6c0.1-1.1,0.2-4.3,0.2-9.6
    c0-1.3,0.2-4.1,0.3-5.4c1.4,0.2,2.7,0.5,3.9,0.8C109.6,56.3,109.2,56.4,109.3,56.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.2,53.8L102.2,53.8c0-0.1-0.1-3.2-0.1-3.6
    c0-0.4,0.1-2.9,0.1-2.9l0.2-1.3c1.7,0.2,3.3,0.5,4.9,0.8l-0.1,2.9l-1.2,2l0.2,2.2C104.9,53.8,103.6,53.8,102.2,53.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.9,47.3c0,0.1-0.1,2.5-0.1,2.9
    c0,0.3,0.1,2.8,0.1,3.4l-1.2,0.2c-0.6,0-1.1,0-1.7,0.1c-0.3-0.7-0.6-1.7-0.6-1.9c0-0.2,0-0.7-0.1-1.3c0-0.8-0.1-1.7-0.1-2.1
    c0-0.5-0.3-2.1-0.5-3.1c0.4,0,0.8,0.1,1.2,0.1c1.1,0.1,2.1,0.3,3.1,0.4L101.9,47.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M89.8,56.4c-0.2,0.1-0.4,0.3-0.4,0.5
    c0.5,2.9,2,5.4,2.6,8.3c0.7,3.1,0.4,6.3,0.6,9.5c-0.3,0-0.6,0-0.8,0c0-1.2-0.1-5.5-0.9-9.3c-0.8-3.4-2.4-7-3.1-8.5
    C88.4,56.7,89.1,56.5,89.8,56.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.7,77c-0.1-0.2-0.2-0.3-0.4-0.5
    c-0.3-0.3-0.7-0.6-1.1-0.9c-0.7-0.5-1.3-1-1.6-1.7c-0.5-1.1-1.5-2.7-2-3.5c-0.2-0.3-0.7-0.8-1.2-1.3c-0.6-0.6-1.2-1.2-1.5-1.7
    c-0.5-0.7-1.2-2.1-1.5-2.6c0.4-0.3,0.9-0.6,1.3-0.9c0.3,0.3,0.9,0.9,2.1,2.3c1.3,1.5,1.4,1.7,2.1,2.9c0.1,0.3,0.3,0.6,0.5,1
    c1,1.8,3.6,5.1,4.7,6.5C81.6,76.7,81.1,76.8,80.7,77z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.4,60.5c0.4,0.2,1,0.6,1.8,1.5
    c1.8,2.2,3.2,4.5,4.2,6.8c0.9,2,1.8,5.1,2.2,6.4c-0.8,0.2-1.5,0.3-2.3,0.5c0.1-0.6,0-1.5-0.8-2.9c-0.6-1.1-1.1-1.9-1.5-2.6
    c-0.6-0.9-1.1-1.8-1.8-3.3c-1.2-2.3-2.7-5.1-3.1-5.7C77.5,61,77.9,60.7,78.4,60.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M88.8,74.9c-0.3-0.9-1-3.2-1.2-3.9
    c-0.2-0.5-1-2.4-1.8-4c-0.6-1.3-1.2-2.6-1.3-2.8c-0.1-0.3-0.5-0.9-0.8-1.6c-0.4-0.7-1-1.7-1-1.9c0-0.3-0.4-1.2-0.7-1.8
    c0.6-0.3,1.3-0.5,1.9-0.7c0.3,0.4,0.8,1.3,1.4,2.9c0.4,0.9,0.8,1.7,1.2,2.5c0.7,1.5,1.4,2.9,1.8,4.6c0.4,1.8,0.7,3.4,0.8,4.3
    c0.1,0.5,0.1,0.8,0.2,0.9c0.1,0.3,0.3,0.9,0.6,1.4C89.6,74.8,89.2,74.8,88.8,74.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M82.4,60.6c0,0.2,0.3,0.8,1,2
    c0.4,0.6,0.7,1.3,0.8,1.5c0.1,0.3,0.6,1.5,1.3,2.9c0.7,1.6,1.6,3.5,1.8,4c0.2,0.7,0.9,3,1.2,3.9c-0.5,0.1-1,0.2-1.5,0.3
    c-0.4-1.2-1.3-4.5-2.2-6.5c-1-2.3-2.5-4.6-4.3-6.9c-0.7-0.8-1.2-1.3-1.7-1.5c0.9-0.5,1.9-0.9,3-1.3C82,59.7,82.4,60.4,82.4,60.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76.8,61.3c0.3,0.6,1.9,3.4,3.1,5.7
    c0.7,1.5,1.3,2.4,1.8,3.3c0.4,0.7,0.9,1.5,1.5,2.6c0.8,1.5,0.8,2.4,0.7,2.9c-0.5,0.1-1,0.3-1.5,0.5c-1-1.3-3.8-4.8-4.7-6.5
    c-0.2-0.4-0.4-0.7-0.5-1c-0.7-1.2-0.8-1.5-2.1-2.9c-1.1-1.3-1.8-1.9-2-2.2C74.1,62.9,75.4,62.1,76.8,61.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M71,65c0.3,0.6,1,1.9,1.5,2.6
    c0.3,0.5,1,1.2,1.6,1.8c0.5,0.5,0.9,0.9,1.1,1.2c0.5,0.7,1.5,2.3,2,3.4c0.3,0.8,1,1.3,1.7,1.8c0.4,0.3,0.7,0.6,1,0.9
    c0.2,0.2,0.3,0.3,0.3,0.4c-0.5,0.2-1,0.4-1.5,0.7c-0.5-0.6-1.4-1.5-2.1-2.2c-0.6-0.7-1.1-0.9-1.6-1.1c-0.3-0.1-0.6-0.3-0.9-0.6
    c-0.7-0.7-1.3-1.3-3.3-3.7c-1.5-1.8-1.5-1.9-1.7-2.3c-0.1-0.1-0.1-0.3-0.3-0.6c-0.1-0.2-0.1-0.3-0.2-0.4c0.5-0.4,0.9-0.8,1.3-1.2
    C70.3,65.5,70.7,65.2,71,65z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M68.6,67.5c0.1,0.3,0.2,0.5,0.3,0.6
    c0.2,0.5,0.2,0.5,1.8,2.4c2,2.4,2.6,3,3.3,3.7c0.3,0.3,0.7,0.5,1,0.6c0.5,0.2,0.9,0.4,1.5,1c0.6,0.7,1.5,1.6,2,2.2
    c-0.4,0.2-0.8,0.4-1.2,0.6c-1-0.7-2.3-1.6-2.5-1.9c-0.1-0.2-0.2-0.2-0.4-0.4c-0.3-0.2-0.8-0.6-1.8-1.9c-0.9-1.1-1.5-1.5-2-1.9
    c-0.4-0.3-0.7-0.5-1-1c-0.4-0.5-0.6-0.7-0.8-0.9c-0.2-0.1-0.2-0.2-0.4-0.6c-0.3-0.6-0.8-1.6-0.9-2c0.3-0.3,0.7-0.7,1-1
    C68.5,67.3,68.5,67.4,68.6,67.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M52.4,117.5c0.2-0.1,0.6-0.2,0.9-0.3
    c0.3-0.1,0.5-0.1,0.6-0.1c0.3-0.1,0.5-0.1,1.2-0.3c0.5-0.2,0.9-0.3,1.2-0.3c0.4-0.1,0.7-0.2,0.9-0.3c0.1-0.1,0.9-0.2,1.8-0.3
    c0.1,0.5,0.2,1,0.3,1.5c-0.4,0.1-0.8,0.2-1.1,0.3c-0.2,0-0.4,0.1-0.7,0.2c-0.4,0.1-0.9,0.2-1.5,0.3c-0.8,0.1-1.2,0.4-1.7,0.6
    c-0.1,0.1-0.2,0.2-0.4,0.2c-0.8,0.4-1,0.4-1,0.4C52.7,118.8,52.6,118.1,52.4,117.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M53,100l6.7,0.6c-0.1,0.6-0.3,1.1-0.4,1.7
    l-6.5-0.5C52.9,101.1,52.9,100.5,53,100z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M52.2,106.5c0.6,0.1,1.5,0.2,1.6,0.2
    c0.1,0,0.4,0,0.8-0.1c0.7-0.1,1.8-0.1,2.4-0.1c0.5,0,1.1-0.1,1.6-0.1c-0.1,0.6-0.1,1.2-0.1,1.7c-0.4,0-1.3,0.1-2.1,0.2
    c-0.4,0-0.6,0.1-0.8,0.1c-0.2,0-0.4,0.1-1.1,0.1c-0.9,0-1.3,0.3-1.6,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.4,0.1-0.6,0.1
    C52.1,108.3,52.1,107.4,52.2,106.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.7,106c-0.5,0.1-1.1,0.1-1.6,0.1
    c-0.7,0-1.7,0.1-2.4,0.1c-0.3,0-0.6,0.1-0.7,0.1c-0.1,0-0.9-0.1-1.6-0.2c0.1-0.7,0.2-1.4,0.2-2l6.3,0.3
    C58.8,104.9,58.7,105.5,58.7,106z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.9,104.1l-6.4-0.3c0-0.1,0-0.2,0-0.2
    c0.1-0.5,0.1-1,0.2-1.5l6.4,0.5C59.1,103.1,59,103.6,58.9,104.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M52.8,109.6c0.2-0.1,0.3-0.2,0.4-0.3
    c0.3-0.2,0.6-0.4,1.4-0.4c0.7,0,0.9,0,1.2-0.1c0.2,0,0.4-0.1,0.8-0.1c0.7,0,1.6-0.1,2-0.2c0,0.5,0,1,0,1.5
    c-0.5-0.1-1.2-0.3-1.6-0.2c-0.4,0-0.5,0.1-0.9,0.2c-0.2,0-0.4,0.1-0.8,0.2c-0.8,0.2-2.4,0.5-3.2,0.6c0-0.4,0-0.7,0-1.1
    C52.2,109.7,52.5,109.7,52.8,109.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M60.2,121.1c-0.2,0-0.4,0-0.6,0.1
    c-0.2,0-0.3,0-0.5,0.1c-0.4,0-0.8,0.1-1.5,0.1c-0.9,0.1-1.6,0.3-2.1,0.5l-0.1,0c-0.4,0.1-1.1,0.2-1.4,0.2c0-0.1-0.1-0.1-0.1-0.2
    c-0.2-0.3-0.3-0.6-0.4-0.9c0.5-0.1,1.2-0.2,1.7-0.2c0.4,0,0.8-0.1,1.1-0.2c0.2-0.1,0.5-0.2,0.7-0.2c0.4,0,0.8,0,1.3,0
    c0.3,0,0.5,0,0.8,0c0.2,0,0.5-0.1,0.8-0.1C60.1,120.6,60.1,120.9,60.2,121.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M59.8,100.2L53,99.6c0-0.3,0-0.7,0-1
    c0-0.2,0-0.4,0-0.7c0.8,0.2,2.1,0.6,3.4,0.7c0.6,0.1,1.1,0.1,1.6,0.1c0.8,0,1.5-0.1,2.1-0.2C60.1,99.2,59.9,99.7,59.8,100.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M61.2,96.1c-1,0.1-2.6,0.2-4.1,0.1
    c-1.5-0.2-2.9-0.4-3.8-0.6c0-0.3,0.1-0.6,0.1-0.9c0.9,0.2,2.3,0.4,3.8,0.6c0.6,0.1,1.1,0.1,1.7,0.1c1.1,0,2.1-0.1,2.8-0.2
    C61.4,95.5,61.3,95.8,61.2,96.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M61.7,94.9C60.8,95,59,95.2,57.1,95
    c-1.4-0.2-2.9-0.4-3.8-0.6c0.1-0.4,0.1-0.8,0.2-1.3c4.5,1.3,8.9,0.3,8.8,0.4C62.1,94,61.9,94.4,61.7,94.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M53.8,92c0.1-0.6,0.3-1.3,0.4-1.9
    c0.9,0,2.4,0.1,3.6,0.8c1.5,0.9,4,1.1,5.3,1.1c-0.1,0.2-0.2,0.4-0.3,0.6C59.3,92.9,56.4,92.4,53.8,92z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M63.3,91.8c-1.3-0.1-3.9-0.3-5.4-1.1
    c-1.3-0.7-2.7-0.9-3.7-0.9c0.1-0.5,0.3-1,0.4-1.5c0.9,0.1,2.5,0.4,3.9,1.2c1.5,0.9,3.8,1.6,5,1.9C63.5,91.5,63.4,91.7,63.3,91.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M63.7,91.1c-1.1-0.3-3.5-1.1-5-1.9
    c-1.4-0.8-3-1.1-4-1.2c0.2-0.6,0.4-1.2,0.6-1.8c0.9,0.3,2.5,0.9,3.8,1.7c1.6,0.9,4.2,2,5.2,2.3C64.2,90.5,63.9,90.8,63.7,91.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M64.6,89.9c-0.9-0.3-3.6-1.4-5.2-2.3
    c-1.3-0.8-3-1.4-3.9-1.7c0.2-0.5,0.4-1,0.6-1.4c0.8,0.3,2.6,1.1,4,2.1c1.6,1.1,4.1,2.3,5,2.7C64.9,89.4,64.7,89.6,64.6,89.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M65.3,88.9c-0.8-0.4-3.4-1.6-5.1-2.7
    c-1.4-1-3.2-1.8-4.1-2.1c0.2-0.5,0.4-0.9,0.6-1.3c0.9,0.4,2.6,1.1,3.9,2c1.9,1.3,5.1,2.7,5.6,2.9C65.9,88,65.6,88.5,65.3,88.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M60.9,84.5c-1.4-0.9-3-1.6-3.9-2
    c0.2-0.4,0.4-0.7,0.6-1.1c0.9,0.3,2.9,1.2,4.6,2.3c1.5,1.1,3.9,2.2,5.2,2.8c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.2-0.4,0.4-0.5,0.7
    l0.1-0.2C66.4,87.3,62.8,85.8,60.9,84.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M67.5,86.2c-1.2-0.6-3.7-1.7-5.2-2.8
    c-1.6-1.1-3.6-1.9-4.6-2.3c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.3c1.1,0.4,2.8,1.2,4.2,2.2c1.8,1.2,4.7,2.6,5.8,3.1
    C68,85.5,67.8,85.8,67.5,86.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M68.6,85c-1-0.5-4-1.8-5.9-3.1
    c-1.4-1-3.1-1.7-4.2-2.2c0.2-0.3,0.4-0.6,0.6-0.8c1,0.4,3.1,1.2,4.8,2.4c1.6,1.1,4.1,2.3,5.5,2.9C69.2,84.4,68.9,84.7,68.6,85z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.7,83.9c-1.3-0.6-3.9-1.8-5.6-3
    c-1.7-1.2-3.8-2-4.8-2.4c0.3-0.4,0.5-0.8,0.8-1.2c0.7,0.5,2.6,1.8,4.4,3.1c1.7,1.2,4.4,2.4,5.7,3C70.1,83.5,69.9,83.7,69.7,83.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M70.6,83.1c-1.2-0.5-4-1.9-5.8-3.1
    c-1.8-1.2-3.7-2.5-4.4-3.1c0.3-0.4,0.6-0.8,0.9-1.2c0.7,0.7,2.5,2.4,4.4,3.6c1.8,1.2,4.6,2.5,5.8,3.1
    C71.2,82.6,70.9,82.9,70.6,83.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M71.8,82.1c-1.1-0.5-4.1-1.9-6-3.1
    c-1.8-1.2-3.7-2.9-4.3-3.6c0.4-0.5,0.8-1,1.2-1.6c0.1,0.1,0.3,0.1,0.5,0.1c2.4,3.4,6.3,5.8,10.1,7C72.8,81.3,72.3,81.7,71.8,82.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M63.9,72.3c0.3-0.4,0.6-0.8,0.9-1.1
    c0.2,0.2,0.6,0.5,1.1,0.9c1.4,1.1,1.6,1.3,2.1,1.9c0.2,0.2,0.4,0.5,0.7,0.9c0.8,1,0.8,1,1.3,1.2c0.2,0.1,0.6,0.3,1.2,0.6
    c0.8,0.5,1.5,0.9,2,1.2c0.7,0.4,1.2,0.7,1.5,0.9c0.3,0.2,0.7,0.4,1.1,0.5c-0.4,0.2-0.8,0.5-1.3,0.8C74.5,80,74.4,80,74.3,80
    C70.3,78.7,66,76.1,63.9,72.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M67.2,68.5c0.2,0.4,0.6,1.3,0.9,1.8
    c0.2,0.4,0.3,0.5,0.5,0.7c0.1,0.1,0.3,0.3,0.7,0.8c0.4,0.5,0.7,0.8,1.1,1c0.5,0.3,1.1,0.7,1.9,1.8c1,1.3,1.6,1.7,1.9,1.9
    c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,1.6,1.3,2.4,1.8c-0.2,0.1-0.4,0.2-0.7,0.4C76,79,75.3,78.8,75,78.6c-0.3-0.2-0.8-0.5-1.5-0.9
    c-0.5-0.3-1.2-0.7-2-1.2c-0.6-0.4-1-0.5-1.2-0.7c-0.4-0.2-0.4-0.2-1.2-1.1c-0.3-0.4-0.5-0.7-0.7-0.9c-0.5-0.6-0.7-0.9-2.1-2
    c-0.6-0.5-1-0.8-1.1-0.9C65.8,70,66.5,69.2,67.2,68.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M53.2,95.9c0.9,0.2,2.3,0.4,3.8,0.6
    c0.6,0.1,1.1,0.1,1.7,0.1c0.9,0,1.7-0.1,2.4-0.1c-0.2,0.6-0.5,1.2-0.7,1.8c-0.9,0.1-2.4,0.3-3.8,0.1c-1.3-0.2-2.6-0.5-3.5-0.8
    C53.1,97,53.1,96.5,53.2,95.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M51.9,113.1c1.9-0.5,3.8-1.1,5.8-1.6
    c0.9-0.2,0.8-0.9-0.2-0.6c-1.9,0.4-3.7,1-5.6,1.5c0-0.4,0-0.9,0-1.3c0.8-0.1,2.5-0.4,3.3-0.6c0.4-0.1,0.6-0.1,0.8-0.2
    c0.4-0.1,0.5-0.1,0.9-0.2c0.4,0,1.1,0.1,1.6,0.2c0,0.7,0,1.3,0.1,2c0,0.1,0,0.3,0,0.4c-0.2,0-0.4,0.1-0.6,0.2
    c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.6,0.1-1.5,0.3l-0.6,0.1c-0.8,0.2-0.8,0.2-1.1,0.3
    c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.2-0.6,0.3-0.8,0.5c-0.1,0.1-0.2,0.2-0.2,0.2c0,0,0,0,0,0C52,114.3,51.9,113.7,51.9,113.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M52.2,115.2c0.1,0,0.2-0.1,0.3-0.2
    c0.2-0.1,0.4-0.3,0.7-0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.3-0.1,1.1-0.3l0.6-0.1c0.9-0.2,1.2-0.3,1.5-0.3c0.2,0,0.3-0.1,0.7-0.2
    c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0.8,0.2,1.6,0.3,2.4c-0.7,0.1-1.7,0.2-1.9,0.3c-0.1,0.1-0.4,0.2-0.8,0.2
    c-0.3,0.1-0.8,0.2-1.2,0.3c-0.6,0.2-0.9,0.3-1.1,0.3c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.1-0.7,0.2-0.9,0.3
    C52.2,116.5,52.1,115.9,52.2,115.2C52.1,115.2,52.1,115.3,52.2,115.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M54.1,119.3c0.1-0.1,0.3-0.2,0.4-0.2
    c0.4-0.3,0.8-0.5,1.5-0.6c0.6-0.1,1.1-0.2,1.6-0.3c0.3-0.1,0.5-0.1,0.7-0.1c0.3,0,0.7-0.2,1.1-0.3c0.2,0.8,0.4,1.6,0.6,2.4
    c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7,0c-0.4,0-0.9-0.1-1.3,0c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.6,0.2-0.9,0.2
    c-0.6,0-1.4,0.1-1.9,0.2c-0.1-0.3-0.3-0.7-0.4-1C53.3,119.7,53.6,119.5,54.1,119.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M137.9,65.1c-0.1,0.2-1.1,1.4-2,2.4
    c-0.8-0.5-1.6-0.9-2.3-1.3l3.1-4.9l0.7-2.2c0.9,0.7,1.9,1.4,2.8,2.2C139.3,62.5,137.9,65.1,137.9,65.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M135.4,57.8c-0.8,1-2.7,3.2-2.9,3.4
    c-0.1,0.1-1.5,1.9-2.5,3.2c-0.8-0.4-1.6-0.8-2.4-1.1c0.8-1,2-2.6,2.2-2.9c0.1-0.2,0.4-0.8,0.7-1.5c0.4-1,1-2.3,1.1-2.4
    c0.1-0.2,0.2-0.6,0.3-0.9C133.2,56.3,134.3,57,135.4,57.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M126.3,56.7c-0.1,0.2-1.7,2.6-3,4.6
    c-1-0.5-1.9-1-2.7-1.5c0.5-0.7,1.1-1.7,1.2-1.9c0.1-0.3,1.7-4.6,2.3-6.2c1.6,0.7,3.1,1.4,4.6,2.1C127.7,55,126.4,56.5,126.3,56.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M115.6,48.8l-0.3,2c-0.2,0.4-1.2,2.7-1.3,3
    c0,0.1-0.1,1.1-0.1,2c-1.4-0.5-2.8-0.9-4.4-1.3l1.3-3.9l0.8-2.7C112.9,48.1,114.3,48.4,115.6,48.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M110.5,50.4l-1.3,4c-0.9-0.2-1.8-0.3-2.7-0.4
    l-0.2-2.2l1.2-1.9l0.2-2.9c1.2,0.2,2.4,0.5,3.6,0.8L110.5,50.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M100.6,44.5l0-0.5c0.3,0,0.6,0.1,0.8,0.1
    c0.2,0,0.4,0.1,0.7,0.1l-0.1,0.5C101.6,44.6,101.1,44.6,100.6,44.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M98.9,44.3c-0.5-0.1-1-0.1-1.4-0.1l0-0.4
    c0.5,0,1,0.1,1.5,0.1L98.9,44.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M92.2,45.3l1.2,5c0.2,0.3,0.6,1.3,0.7,1.5
    c0.1,0.3,0.1,1.6,0.2,2.5c-0.3,0-0.6,0.1-0.9,0.1c-0.7,0.1-1.4,0.2-2.2,0.4c-0.5-0.6-0.9-1.1-0.9-1.2c0-0.2-0.1-2.9-0.3-3.4
    c-0.1-0.4-1.3-3.3-1.8-4.7C89.5,45.3,90.8,45.3,92.2,45.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M87.7,45.5c0.5,1.4,1.7,4.5,1.9,4.8
    c0.1,0.4,0.3,3,0.3,3.3c0,0.1,0,0.2,0.9,1.3c-0.7,0.2-1.4,0.3-2.2,0.5c-0.3-1.6-0.7-2.8-1.1-3.6c-0.3-0.6-0.5-1-0.7-1.5
    c-0.2-0.5-0.4-1-0.8-1.7c-0.5-0.8-0.6-2-0.7-2.8C86.1,45.6,86.9,45.6,87.7,45.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M87.4,57c0.7,1.4,2.3,5,3.1,8.4
    c0.9,3.7,0.9,8,0.9,9.2c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3,0-0.4,0c-0.2-0.5-0.5-1.2-0.6-1.4c0-0.1-0.1-0.4-0.2-0.9
    c-0.2-0.9-0.4-2.5-0.8-4.4c-0.4-1.8-1.1-3.2-1.9-4.7c-0.4-0.8-0.8-1.6-1.2-2.5c-0.7-1.5-1.1-2.4-1.4-2.9
    C85.3,57.6,86.4,57.3,87.4,57z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M75.7,60.4c0.1,0.2-0.4,0.9-1.1,1.3l-0.9-1.2
    c-0.5-0.7-1.2-1.8-1.7-2.8c-0.2-0.4-0.4-0.9-0.6-1.4c-0.4-0.9-0.8-1.9-1.2-2.5c-0.4-0.6-1.5-2-2.2-2.9c0.5-0.3,1.1-0.5,1.6-0.8
    C71.5,53.7,73.7,56.9,75.7,60.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.6,65.3c-0.4,0.3-0.9,0.7-1.4,1.2
    c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2l-0.1,0.1c-0.6,0.6-1.3,1.2-1.9,1.9c-0.4-0.5-1.1-1.4-2.3-2.7c-1.4-1.4-5.9-5.5-7.6-7.1
    c1.9-1.7,4-3.3,6.3-4.8c0.4,0.7,1.2,1.9,1.5,2.2c0.3,0.4,1.7,2.6,2,3.2c0.2,0.4,1,1.5,1.6,2.4c0.3,0.4,0.5,0.7,0.6,0.9
    c0.5,0.7,2.5,1.4,2.7,1.5l0.1,0C70.3,64.7,69.9,65,69.6,65.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M55.7,59.1c1.7,1.6,6.3,5.7,7.6,7.1
    c1.2,1.2,2,2.2,2.3,2.7c-0.7,0.8-1.5,1.6-2.3,2.4c-1,0.8-1.3,1.2-1.2,1.4c-1,1.2-2,2.4-2.9,3.6c-0.9,0.6-2.3,1.5-3.5,1.5h0
    c-0.6,0-1-0.2-1.3-0.7c-1.4-2.2-2.7-2.7-4.4-3c-1.2-0.3-3-1.4-3.9-2C48.3,67.3,51.6,62.9,55.7,59.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M45.8,69.7l-0.1-0.1c0.4-0.9,0.8-1.7,1.2-2.5
    l0.3,0.1C46.7,68,46.3,68.9,45.8,69.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M43.4,80.2c1.2,1,3.6,2.7,4.4,2.7
    c0.8,0,4.5,1.4,6.5,2.2c-0.9,2.5-1.6,5.1-2,7.6c-0.8,0.6-1.9,1-2.1,1.1c-0.7-0.2-4.9-1.3-5.5-1.3c-0.4,0-2.5-0.1-3.6-0.2
    C41.6,88.2,42.4,84.1,43.4,80.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M41.1,92.7c1.1,0.1,3.2,0.2,3.7,0.2
    c0.5,0,4.1,0.9,5.4,1.3l0,0l0,0c0.1,0,1.1-0.3,2-0.9c-0.3,2-0.5,3.9-0.5,5.4c0,0.8-0.1,1.8-0.2,2.9c-0.8,0-2.7,0-4.8,0.5
    c-2,0.4-4.9-0.2-6.2-0.6C40.5,98.6,40.7,95.6,41.1,92.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M55.8,125.3c1.5-0.7,3-1.5,4.5-2.2
    c0.9-0.4-0.5-0.3-0.9-0.2c-1.3,0.6-2.6,1.3-3.9,1.9c-0.2-0.4-0.4-0.8-0.7-1.2c0,0,0-0.1,0-0.1c0.4-0.1,1.2-0.4,2.1-0.5
    c0.8-0.1,0.9-0.2,1-0.3c0,0,0,0,0.2-0.1c0.4-0.1,1.8-0.3,2.3-0.4c0.2,0.7,0.4,1.4,0.7,2c-0.1,0.1-0.3,0.1-0.4,0.2c0,0-0.1,0-0.1,0
    c-0.2,0-0.4,0.1-1.1,0.4c-0.7,0.3-0.9,0.5-1.2,0.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.6,0.4-1.3,0.8-1.6,1
    C56.3,126.2,56.1,125.7,55.8,125.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M64.1,130.4c-0.4,0.3-0.9,0.5-1,0.6
    c-0.3,0.1-1.3,0.7-1.5,0.9l-1.3,1c-0.4-0.6-0.7-1.1-1.1-1.7c0.4-0.2,1.4-0.7,1.6-0.9c0.1-0.1,0.6-0.3,0.9-0.5
    c0.3-0.2,0.5-0.2,0.6-0.3c0.1-0.1,0.6-0.3,1.1-0.5C63.6,129.5,63.9,130,64.1,130.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.4,141.8c0.3-0.5,0.8-1,0.9-1.1
    c0.2-0.1,0.7-0.7,0.9-1c0.3,0.3,0.5,0.5,0.8,0.8c-0.3,0.3-0.6,0.6-0.7,0.9c-0.2,0.3-0.9,1.2-1.3,1.8c-0.4-0.3-0.8-0.5-1.1-0.8
    C69.2,142.2,69.4,141.9,69.4,141.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M72.6,144l1.2-1.9c0.4,0.4,0.9,0.7,1.3,1.1
    c-0.1,0.2-0.2,0.4-0.5,0.7c-0.3,0.5-0.9,1.3-1.2,1.7c-0.5-0.4-1-0.7-1.5-1.1C72.1,144.4,72.3,144.2,72.6,144z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.2,149.3l1.8-2.8c0.2,0.1,0.5,0.3,0.7,0.4
    c-0.4,0.9-0.9,1.8-1,1.9c-0.1,0.1-0.5,0.6-0.7,1C78.7,149.6,78.4,149.4,78.2,149.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M79.9,149.2c0.2-0.1,0.7-1.2,1.1-2.1
    c0.2,0.1,0.5,0.3,0.7,0.4c-0.3,0.5-0.6,1.2-0.8,1.5c-0.2,0.3-0.7,1.1-0.9,1.6c-0.2-0.2-0.5-0.3-0.7-0.5
    C79.5,149.6,79.8,149.2,79.9,149.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M82,150.6l0.9-2.5c0.3,0.2,0.7,0.3,1,0.5
    c-0.2,0.5-0.5,1.3-0.5,1.6c0,0.2-0.4,1.3-0.7,2.2c-0.5-0.3-0.9-0.5-1.4-0.8L82,150.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M88.1,154.4c0-0.3,0.1-2.9,0.2-4.2
    c0.5,0.2,1.1,0.4,1.6,0.5c0,0.2,0,0.5-0.1,0.7l-0.3,1.3c0,0.3-0.2,1.5-0.3,2.1c-0.4-0.1-0.8-0.2-1.2-0.3
    C88,154.5,88.1,154.5,88.1,154.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M89.6,155c0.1-0.4,0.2-1.4,0.3-2.1l0.3-1.3
    c0-0.2,0.1-0.4,0.1-0.7c0.4,0.1,0.8,0.2,1.2,0.3c-0.1,1.2-0.2,2.5-0.2,2.7c-0.1,0.2-0.1,0.9-0.1,1.4
    C90.6,155.2,90.1,155.1,89.6,155z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M91.5,154c0.1-0.2,0.2-1.9,0.3-2.7
    c0.5,0.1,0.9,0.2,1.4,0.3c0,0.2,0,0.4,0,0.5c-0.1,0.3-0.1,1.7-0.1,2.1c0,0.2,0,0.8,0,1.3c-0.5,0-1-0.1-1.5-0.2
    C91.4,154.7,91.5,154.1,91.5,154z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M110.8,153.5l-0.3-1.5l0-0.2
    c0.2,0,0.3-0.1,0.5-0.1c0,0.1,0,0.2,0,0.3v1.3l0,0.1c0.1,0.2,0.2,0.5,0.2,0.7c-0.2,0-0.3,0-0.5,0.1L110.8,153.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M112.5,152.5l-0.1-1.1c0.1,0,0.3-0.1,0.4-0.1
    l0.1,1l0.2,1.2l0.1,0.1c-0.1,0-0.3,0.1-0.4,0.1l0,0L112.5,152.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M114.6,150.9c0.1,0,0.2-0.1,0.3-0.1l0.1,0.9
    l0.3,0.7v0.8c-0.2,0-0.3,0.1-0.5,0.1l0-1.1L114.6,150.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M116.6,152.9l0-0.7l-0.1-1.1l-0.1-0.7
    c0.2,0,0.3-0.1,0.5-0.1l0.2,0.7l0.1,1l0.2,0.8C117,152.8,116.8,152.8,116.6,152.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M118.7,150.7l-0.3-0.9v-0.1
    c0.5-0.2,0.9-0.3,1.4-0.5l0,0l0.5,1.9l0.3,0.8c-0.5,0.1-1,0.3-1.6,0.4l0-0.3L118.7,150.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M128.9,149.5c0-0.1,0-0.2-0.1-0.4
    c-0.1-1-0.3-1.5-0.5-1.6c-0.2-0.2-0.7-1-0.8-1.2c0-0.1-0.2-0.3-0.3-0.4c0.7-0.4,1.5-0.8,2.2-1.3c0.2,0,0.3,0.1,0.5,0.1
    c0.1,0.2,0.3,0.8,0.4,1c0,0.1,0.1,0.3,0.3,0.5c0.1,0.2,0.3,0.4,0.3,0.6c0,0.3,0.3,0.5,0.4,0.7c0.1,0.1,0.2,0.3,0.3,0.4
    c0.1,0.2,0.1,0.4,0.2,0.5C130.9,148.8,129.9,149.1,128.9,149.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M133.5,146.7c-0.1-0.2-0.4-0.8-0.7-1.2
    c-0.2-0.3-0.3-0.5-0.4-0.6c-0.1-0.3-0.8-1.4-0.9-1.4l0-0.1c0.6-0.4,1.1-0.8,1.7-1.2c0.1,0.1,0.2,0.2,0.3,0.4
    c0.2,0.4,0.4,0.8,0.6,1.2c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.3,0.3,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.6c0.2,0.3,0.3,0.7,0.4,1.1
    c0,0.1,0.1,0.3,0.1,0.4c-0.7,0.4-1.5,0.7-2.3,1.1C133.5,147.4,133.6,146.9,133.5,146.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M143.7,140.2c-0.6-0.9-1.2-2-1.2-2.1
    c0-0.2-0.3-0.4-0.6-0.7c-0.2-0.2-0.5-0.4-0.6-0.6c-0.2-0.2-0.5-0.7-0.8-1c0.5-0.6,1-1.1,1.6-1.7c0.1,0.1,0.2,0.2,0.3,0.3
    c0.4,0.4,0.5,0.6,0.7,0.8c0.2,0.3,0.4,0.6,1,1.1c0.8,0.8,0.9,0.9,1.3,1.1c0.1,0,0.2,0.1,0.3,0.2c0.4,0.2,0.7,0.6,0.8,0.8
    c-1,1.1-1.9,2-2.8,2.8L143.7,140.2L143.7,140.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M148.3,136.2c-0.4-0.5-1.1-1.2-1.2-1.4
    c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.7-0.4-1.1-1.1c-0.4-0.7-1.3-1.4-1.8-1.8c0.6-0.7,1.1-1.4,1.6-2.2c0,0,0,0.1,0,0.1
    c0,0.2,0.1,0.3,0.5,0.9c0.5,0.6,0.7,0.8,1,1.1c0.1,0.1,0.3,0.3,0.5,0.6c0.4,0.4,0.6,0.6,0.8,0.7c0.1,0.1,0.2,0.2,0.4,0.3
    c0.2,0.2,0.6,0.7,1,1.1C149.3,135,148.8,135.6,148.3,136.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M151,130.7c-0.1-0.1-0.2-0.1-0.3-0.2
    c-0.4-0.2-0.9-0.5-1.3-0.8c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.2-0.4-0.4-0.7-0.7c-0.2-0.2-0.6-0.5-0.6-0.6
    c0.2-0.3,0.4-0.6,0.6-0.9c0.4,0.4,0.5,0.4,0.7,0.5c0.1,0.1,0.3,0.2,0.7,0.5l0.3,0.3c0.7,0.5,0.9,0.6,1.4,0.8
    c0.5,0.2,0.8,0.2,1.3,0.2c0.2,0,0.3,0,0.5,0c0.4,0,0.8,0,1.2,0c-0.6,0.9-1.2,1.8-1.7,2.6C151.6,131.2,151.2,130.9,151,130.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M155,126.8c-0.1-0.1-0.1-0.1-0.2-0.1h0
    c-0.1,0-0.7-0.1-1.1-0.2c-0.8-0.2-1.1-0.3-1.2-0.3c-0.1,0-1-0.2-1.7-0.4c-0.6-0.2-1-0.3-1.3-0.5c-0.2-0.1-0.6-0.5-0.9-0.8
    c0.1-0.2,0.2-0.4,0.4-0.7c0.1,0,0.1,0.1,0.2,0.1c0.6,0.2,1,0.4,1.4,0.5c0.2,0.1,0.5,0.2,0.8,0.3c0.7,0.3,2.7,0.4,3.1,0.4h1.3
    C155.6,125.6,155.3,126.2,155,126.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M163.4,106c0.2,0.1,1.7,0.6,3.9,0.6
    c0.7,0,1.5-0.1,2.2-0.1c0.6,0,1.2-0.1,1.6-0.1c-0.3,1.5-0.6,3-1,4.5c-0.5,1.6-1,3.2-1.6,4.8c-0.1,0-0.2,0-0.3,0
    c-0.8,0.1-2,0.2-3.4,0.1c-1.2,0-2.7,0.2-3.6,0.4C162.2,113,163,109.6,163.4,106z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M163.2,91l9.4-1c0.1,2.9-0.1,5.7-0.3,8.5
    c-2.2,0.3-6.6,0.9-8.5,1.3C163.7,96.9,163.5,93.9,163.2,91z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M163.1,90.6c-0.4-2.8-0.9-5.5-1.5-8.2
    l10.1-1.3c0.5,2.9,0.7,5.7,0.8,8.5L163.1,90.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M171.7,80.8l-10.1,1.3
    c-0.6-2.5-1.3-4.9-2.1-7.2c1.6-0.5,5.1-1.6,6.2-2c1.1-0.4,3.2-0.2,4-0.2C170.5,75.5,171.2,78.1,171.7,80.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M156.5,70.8c-0.2,0.1-0.4,0.3-0.6,0.5
    c-0.3,0.3-0.7,0.4-1.2,0.6c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.2-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7,0c-0.6,0.1-1.6,0-2.2,0
    c-0.5-1.2-1.1-2.3-1.7-3.4c0.2,0,0.4,0,0.5-0.1c1.5-0.2,2.1-0.4,2.8-0.7c0.7-0.3,1.7-0.9,1.8-1c0.1-0.1,0.3-0.5,0.6-0.9
    C155.2,67.7,155.9,69.2,156.5,70.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M151.5,63.8c-0.2,0.1-0.3,0.2-0.4,0.2
    c-0.1,0.1-0.3,0.1-0.7,0.4c-0.4,0.3-0.5,0.4-0.9,0.6c-0.2,0.1-0.5,0.3-0.9,0.5c-0.6,0.4-1.3,0.6-1.7,0.6c-0.4-0.6-0.9-1.1-1.3-1.6
    c-0.1-0.1-0.3-0.3-0.4-0.4c0.2,0,0.3-0.1,0.5-0.1c1.4-0.4,2-0.6,2.9-1.2c1-0.7,1.8-1.3,2-1.8c0.1-0.1,0.1-0.3,0.2-0.5
    c0.6,0.7,1.1,1.5,1.7,2.4C152.2,63.3,151.8,63.6,151.5,63.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M135.9,56.5c0.5-0.4,1.3-1.1,1.5-1.4
    c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.4-0.6,0.4-0.7l0.4-1c0.5,0.2,0.9,0.4,1.4,0.6
    c-0.2,0.5-0.6,1.3-0.9,1.7c-0.3,0.4-0.8,1.3-1,1.3c-0.2,0-0.8,0.6-1.3,1C136.6,57,136.2,56.7,135.9,56.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M138,53.6c0,0-0.3,0.5-0.4,0.7
    c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.9,0.8-1.5,1.3c-0.4-0.3-0.8-0.5-1.2-0.7c0.1-0.3,0.4-0.8,0.6-1
    c0.2-0.2,0.4-0.6,0.6-1.2c0.1-0.4,0.3-0.8,0.4-0.9c0.1-0.1,0.2-0.4,0.2-0.7c0.8,0.3,1.5,0.6,2.3,0.9L138,53.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M121.4,47.8c0,0.2-0.3,0.6-0.4,0.8
    c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.1-0.5-0.2-0.7-0.3l0.4-1.3l0.1-0.3c0.4,0.1,0.7,0.2,1.1,0.2C121.5,47.6,121.4,47.8,121.4,47.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M118.4,47.5c-0.1,0.2-0.2,0.5-0.3,0.7
    c-0.4-0.1-0.9-0.3-1.3-0.4l0.2-0.4l0.1-0.6l0.1-0.3c0.5,0.1,1,0.2,1.4,0.3C118.6,47,118.5,47.3,118.4,47.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M115.7,46.3c0.4,0.1,0.7,0.1,1.1,0.2l-0.1,0.3
    l-0.1,0.6l-0.2,0.4c-0.4-0.1-0.7-0.2-1.1-0.3c0.1-0.2,0.2-0.3,0.2-0.5C115.6,46.9,115.7,46.6,115.7,46.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M107.8,45.6l0.2-0.6l0.4,0.7
    C108.3,45.7,108.1,45.7,107.8,45.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.4,44.7l0.1-0.5c0.3,0,0.6,0.1,0.9,0.1
    c0,0.1,0.1,0.3,0.1,0.6C103.2,44.8,102.8,44.8,102.4,44.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M97.1,43.7l0,0.4c-0.4,0-0.9-0.1-1.3-0.1
    l0-0.5C96.2,43.6,96.7,43.6,97.1,43.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M88.4,44.1c-0.2,0-0.4,0-0.6,0.1v-0.9
    c0.2,0,0.4,0,0.7,0L88.4,44.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M87.5,44.2c-0.2,0-0.4,0-0.6,0.1l0-1
    c0.2,0,0.4,0,0.6,0V44.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M86.5,44.3c-0.2,0-0.4,0-0.6,0.1l-0.2-1.1
    c0.3,0,0.6,0,0.9,0L86.5,44.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M85.5,44.4c-0.4,0.1-0.8,0.1-1.2,0.2
    c0.1-0.3,0.1-0.5,0.1-0.7c0-0.1-0.1-0.3-0.2-0.5c0.3,0,0.7,0,1.1,0L85.5,44.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M85,45.8c0.1,0.8,0.2,2.1,0.8,2.9
    c0.4,0.7,0.6,1.2,0.8,1.7c0.2,0.5,0.3,0.9,0.7,1.5c0.4,0.7,0.8,1.9,1.1,3.5c-1.3,0.4-2.5,0.8-3.7,1.2c-0.2-0.3-0.3-0.8-0.3-2v-0.8
    c0-2.5,0-2.6-0.6-3.8l-0.1-0.3c-0.7-1.4-1.9-2.7-2.3-3.2C82.4,46.2,83.7,46,85,45.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.7,46.5c0.3,0.3,1.7,1.9,2.4,3.3l0.1,0.3
    c0.6,1.2,0.6,1.2,0.6,3.7v0.8c0,1,0.1,1.7,0.3,2.1c-0.8,0.3-1.5,0.6-2.3,0.9c-0.3-0.8-0.8-2.6-0.8-3.8c0-1.6,0-1.6-0.2-2.4
    l-0.1-0.2c-0.1-0.2-0.1-0.3-0.1-0.5c-0.2-0.7-0.4-1.5-1.4-2.8c-0.3-0.4-0.5-0.8-0.6-1C79.4,46.9,80,46.7,80.7,46.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M55.7,56.1l0.6,0.7c-0.3,0.2-0.5,0.4-0.7,0.7
    l-0.5-0.8C55.3,56.5,55.5,56.3,55.7,56.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M48.3,65.5L48,65.3c0.2-0.3,0.3-0.5,0.5-0.8
    l0.5,0.2C48.7,65,48.5,65.3,48.3,65.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M47.8,65.6l0.4,0.3c-0.2,0.3-0.5,0.7-0.7,1
    l-0.3-0.1C47.3,66.4,47.5,66,47.8,65.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M45.6,70.1c-0.3,0.6-0.6,1.2-0.8,1.8
    c0,0,0-0.1,0-0.1c0.2-0.6,0.5-1.2,0.7-1.8L45.6,70.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M40.4,86.4l0.2,0.1c-0.2,1-0.3,2-0.5,3l-0.3,0
    C40,88.5,40.2,87.4,40.4,86.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M39.8,89.9l0.3,0c-0.2,1.1-0.3,2.1-0.4,3.2
    c-0.1,0-0.1-0.1-0.2-0.1C39.5,91.9,39.7,90.9,39.8,89.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M46.7,102.4c2.1-0.5,3.9-0.5,4.7-0.5
    c-0.1,0.5-0.1,1-0.2,1.5c-0.4,2.8-0.8,6.2-0.7,9.6c-1.2,0.2-3.8,0.7-5.3,0.7c-1.3,0-3.1,0.5-4.1,0.8c-0.6-4.1-0.8-8.4-0.7-12.7
    C41.9,102.3,44.7,102.9,46.7,102.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M41.3,114.9c1-0.3,2.8-0.8,4.1-0.8
    c1.5,0,4.1-0.5,5.3-0.7c0.1,2,0.4,4.1,1,6c-1.2,0.4-4.2,1.4-5.6,2.1c-1.2,0.6-2.3,0.8-3.1,0.5c-0.1,0-0.2-0.1-0.3-0.2
    C42,119.6,41.6,117.3,41.3,114.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.5,129.8c0.1-0.1,0.2-0.2,0.4-0.3
    c0.2-0.2,0.5-0.5,0.8-0.6c0.3-0.1,0.5-0.2,0.7-0.3c0.1-0.1,0.3-0.1,0.5-0.2c0.3-0.1,0.8-0.4,1.2-0.6c0.1-0.1,0.3-0.1,0.4-0.2
    c0.2,0.4,0.4,0.8,0.6,1.2c-0.5,0.2-1,0.4-1.1,0.5c-0.1,0-0.3,0.2-0.6,0.3c-0.4,0.2-0.8,0.4-1,0.5c-0.2,0.1-1,0.6-1.5,0.9
    c-0.2-0.3-0.4-0.7-0.6-1C58.4,129.8,58.5,129.8,58.5,129.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M95.3,155.6l0.4-1l-0.1-2.6
    c0.5,0.1,1,0.1,1.5,0.2l-0.1,0.6l0.1,2.6l0,0.2C96.5,155.6,95.9,155.6,95.3,155.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M100.3,154.5l0-2.1c0.5,0,1,0,1.5,0l-0.2,1
    l0.1,1.1l0,0.7c-0.6,0.1-1.1,0.1-1.6,0.1L100.3,154.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M158.6,122.8l5.4,3.7c-1.3,2.8-2.7,5.3-4,7.5
    l-6.2-2.8C155.5,128.6,157.1,125.8,158.6,122.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M171.1,106c-0.4,0-1,0-1.7,0.1
    c-0.7,0.1-1.5,0.1-2.2,0.1c-1.9,0-3.3-0.4-3.7-0.6c0-0.3,0-0.6-0.1-0.9c0.1-1.5,0.2-3,0.2-4.6c1.6-0.3,6.2-1,8.4-1.3
    C171.9,101.3,171.6,103.7,171.1,106z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M175,81.2c0.3,0,0.6,0,0.9,0
    c0.3,0,0.6,0,0.9,0c0.3,0,0.8-0.1,1.4-0.2c0.5-0.1,1-0.2,1.3-0.2c0.8-0.1,1.5-0.2,2.2-0.4c0.3-0.1,0.7-0.3,1-0.4
    c0.3,1.1,0.5,2.1,0.6,3.1c-0.2,0-0.5,0.1-1,0.3c-0.9,0.3-2,0.7-3.3,1.1c-0.8,0.3-1.6,0.4-2.2,0.5c-0.3,0-0.5,0.1-0.8,0.1
    c-0.2,0-0.5,0.1-0.7,0.1C175.2,83.8,175.1,82.5,175,81.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M182.6,79.5c-0.3,0.2-0.7,0.4-1,0.4
    c-0.6,0.2-1.3,0.3-2.1,0.4c-0.4,0-0.9,0.1-1.4,0.2c-0.6,0.1-1.1,0.2-1.4,0.2c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0
    c-0.1-1-0.3-1.9-0.5-2.9c0.6,0,1.5-0.1,2.3-0.3c0.6-0.2,1-0.2,1.3-0.2c0.4,0,0.7-0.1,1.4-0.3c1.2-0.5,1.6-0.9,1.8-1.2
    c0.1-0.1,0.2-0.1,0.3-0.2C181.9,77,182.3,78.3,182.6,79.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M170.2,65.8c0.4-0.1,0.9-0.3,1.3-0.5L172,65
    c0.4-0.2,0.6-0.4,1.2-0.8c0.8-0.5,1.5-1.1,2.3-2.6c0.1-0.1,0.1-0.3,0.2-0.4c0.6,1.2,1.2,2.4,1.7,3.6c-0.1,0.2-0.3,0.5-0.5,0.8
    c-0.2,0.3-0.7,0.8-1.6,1.1c-0.1,0-0.3,0.2-0.7,0.5c-0.4,0.3-0.8,0.7-1.2,0.8c-0.5,0.2-1.2,0.4-2,0.5
    C171.1,67.6,170.7,66.7,170.2,65.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M173.9,89.4c-0.3-8.8-2.2-17.8-7.2-26.6
    C172.2,70.5,174.1,79.9,173.9,89.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M169.5,72.4c-0.9-0.1-2.9-0.2-4,0.2
    c-1,0.3-4.6,1.5-6.2,2c-0.7-2.1-1.5-4.1-2.4-6c0.6-0.4,1.6-0.9,2.3-1.2c0.5-0.2,0.9-0.5,1.3-0.7c1.1-0.6,4-2.4,5.1-3.1
    C167.2,66.5,168.5,69.4,169.5,72.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M161.6,57.4c1.4,1.9,2.7,3.9,3.8,5.9
    c-1.1,0.7-4,2.5-5.1,3.1c-0.3,0.2-0.8,0.4-1.3,0.7c-1,0.5-1.8,0.9-2.3,1.2c-1.2-2.5-2.4-4.8-3.7-6.7c1-0.5,4-1.9,5.1-2.9
    C159,57.8,160.6,57.3,161.6,57.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M148.2,57.9c-0.1,0-0.2,0.1-0.3,0.2
    c-0.3,0.4-3.1,3.4-3.3,3.5c-0.1,0-0.9,0.5-1.4,0.8c-0.7-0.7-1.4-1.3-2.2-1.9c0.3-0.4,0.7-0.9,1-1c0.6-0.2,1-0.5,1.5-1
    c0.5-0.5,1.1-1.3,1.1-1.3l0.4-1.3C146.3,56.5,147.3,57.1,148.2,57.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M142.3,55.7c-0.2,0.3-0.4,0.5-0.7,0.8
    c-0.3,0.2-0.6,0.5-0.9,0.8c-0.4,0.5-1.3,1-1.8,1.4c-0.5-0.4-1-0.8-1.6-1.2c0.5-0.4,1-0.8,1.1-0.9c0.2-0.1,0.5-0.5,1.2-1.5
    c0.3-0.4,0.7-1.2,0.9-1.7c0.9,0.4,1.7,0.8,2.5,1.2C142.8,54.9,142.5,55.3,142.3,55.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M133.4,50.8c0,0.2,0,0.4-0.1,0.5
    c0,0.3-0.3,1-0.5,1.3c-0.2,0.2-0.9,0.8-1.4,1.2c-0.4-0.2-0.9-0.5-1.4-0.7c0.2-0.4,0.5-1,0.6-1.1c0-0.1,0.1-0.2,0.2-0.4
    c0.1-0.2,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.4,0.2-0.7C132.1,50.3,132.8,50.5,133.4,50.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M128.5,50.2c0,0-0.5,1-0.6,1.2
    c-0.1,0.1-0.2,0.4-0.4,0.5c-0.5-0.2-1-0.4-1.5-0.7l0.4-0.7c0,0,0.2-0.3,0.3-0.6c0.1-0.1,0.1-0.6,0.2-1c0.6,0.2,1.1,0.3,1.7,0.5
    L128.5,50.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M125,48.4L125,48.4c0,0.1-0.1,0.6-0.3,0.9
    c-0.1,0.3-0.3,0.6-0.3,0.7l-0.4,0.3c-0.4-0.2-0.8-0.3-1.2-0.5c0.1-0.1,0.3-0.3,0.7-1.1l0.1-0.7C124.1,48.1,124.6,48.2,125,48.4
    L125,48.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M115.4,46.2c-0.1,0.3-0.1,0.6-0.1,0.7
    c0,0.1-0.1,0.2-0.2,0.4c-0.4-0.1-0.8-0.2-1.2-0.3l0.1-0.9l0-0.2C114.5,46,114.9,46.1,115.4,46.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M111.9,46.5c-0.2,0-0.4-0.1-0.6-0.1l-0.4-0.5
    l0-0.5c0.5,0.1,1,0.2,1.6,0.3C112.2,46,111.9,46.3,111.9,46.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M109.1,45.9c0,0-0.1,0-0.1,0l-0.5-0.8
    c0.5,0.1,1,0.2,1.6,0.2L109.1,45.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M107.5,45.6c-0.3-0.1-0.6-0.1-1-0.2l0.1-0.6
    c0.4,0.1,0.8,0.1,1.1,0.2L107.5,45.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M105.3,44.7c0,0.1-0.1,0.2-0.3,0.4
    c-0.4-0.1-0.7-0.1-1.1-0.2c0-0.2-0.1-0.4-0.1-0.6c0.5,0.1,1,0.1,1.5,0.2C105.3,44.6,105.3,44.7,105.3,44.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M95.4,44c-0.3,0-0.5,0-0.8,0l-0.1-0.5
    c0.3,0,0.6,0,0.9,0.1L95.4,44z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M93.1,43.4l0,0.6c-0.4,0-0.7,0-1.1,0l0.1-0.6
    C92.4,43.4,92.7,43.4,93.1,43.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M90.7,44c-0.2,0-0.5,0-0.7,0l0-0.7
    c0.3,0,0.6,0,0.9,0L90.7,44z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M67.7,47.9c0.2,0.3,0.2,0.4,0.3,0.5
    c0,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.3,0.1,0.5c-0.5,0.2-1,0.5-1.5,0.7l-0.5-0.9l-0.1-0.5L66,48.5c0.5-0.3,1-0.6,1.5-0.9
    C67.6,47.7,67.6,47.8,67.7,47.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M54.8,57l0.5,0.8c-0.2,0.2-0.4,0.4-0.6,0.6
    L54,57.7C54.3,57.5,54.5,57.2,54.8,57z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M52.8,59l0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.6
    l-0.6-0.5C52.3,59.5,52.5,59.2,52.8,59z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M51.1,60.9l0.6,0.4c-0.2,0.3-0.5,0.5-0.7,0.8
    l-0.6-0.4C50.7,61.4,50.9,61.1,51.1,60.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M49.3,63.2l0.5,0.4c-0.2,0.2-0.3,0.4-0.5,0.6
    l-0.2,0.2l-0.5-0.2C48.9,63.8,49.1,63.5,49.3,63.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M39,86.6c-1.1-0.1-3.6-0.3-5.5-0.5
    c-1.9-0.2-4.4-0.7-5.3-0.9c0-0.1,0-0.1,0-0.2c0.2-1.3,0.5-2.5,0.8-3.7c0.6,0,1.4,0.1,2.6,0.1c0.6,0,1.3,0,2,0
    c2.5,0.1,5.1,0.6,6.3,0.9C39.7,83.7,39.3,85.1,39,86.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M28.2,85.6c0.9,0.2,3.4,0.7,5.3,0.9
    c1.8,0.2,4.3,0.4,5.4,0.5c-0.4,1.8-0.6,3.7-0.8,5.6l0,0.1l0,0c0,0.5-0.1,1-0.1,1.5c-2.1,0-6.4-0.1-8.3,0.3
    c-1.6,0.3-2.6-0.3-3.2-0.7C27.1,91.1,27.7,88.5,28.2,85.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M39.4,94.2c0-0.3,0-0.6,0-0.8
    c0.1,0,0.2,0.1,0.2,0.1c-0.2,1.6-0.3,3.2-0.4,4.8c0-1.1,0-2.2,0.1-3.3c0-0.2,0.1-0.4,0.1-0.6l0-0.2L39.4,94.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M42.8,122.3c0.9,0.3,2.1,0.1,3.3-0.5
    c1.4-0.7,4.3-1.7,5.6-2.1c0.3,1,0.7,1.9,1.2,2.8c0.5,0.9,1,1.9,1.5,2.8c-0.3,0.2-0.2,0.2,0.1,0.2c-0.9,0.7-2.6,2.1-3.6,2.6
    c-1,0.6-3.6,1.9-5,2.7c-0.5-1-1-2.1-1.7-3.3C43.6,125.9,43.1,124.1,42.8,122.3C42.7,122.3,42.7,122.3,42.8,122.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M47.5,134.3c-0.4-0.9-0.9-2-1.5-3.1
    c1.3-0.7,4-2.1,5-2.7c1.1-0.6,2.8-1.9,3.7-2.6c1.3,2.4,2.5,4.7,4,7.1c-1.2,0.8-3.7,2.6-4.7,3.7c-1.1,1.3-2.7,2.7-3.6,2.9
    C49.3,138.1,48.6,136.5,47.5,134.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M50.6,140c1.1-0.4,2.8-1.9,3.7-3
    c0.9-1.1,3.5-2.9,4.7-3.7c2.1,3.3,4.7,6.5,8.3,9.4c-1.4,1.1-4.5,3.6-4.8,4.4c-0.2,0.6-1.1,1.6-1.6,2.1
    C55,144.8,52.4,142.4,50.6,140z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.1,155.4c1.3-1.2,4.2-5.3,5.5-7.1
    c2.2,1.7,4.3,3.3,6.5,4.7c0.1,1.2-1.2,5.1-2.4,8.1C75.6,159.5,72.5,157.7,69.1,155.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.4,164.8l0.4-3.5c0.2-0.3,1.7-3,1.2-4.4
    c0.9,0,1.8,0,2.8-0.1c1.4-0.1,2.7-0.2,4.2-0.4l-0.6,5.7l0,0l0,0c0,0,0.5,1.5-0.1,2.4c0,0.1-0.1,0.1-0.1,0.1c-0.4,0-0.7,0.1-1.1,0.1
    C98.7,165,96.5,165,94.4,164.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M154.9,141.1c-0.2-0.2-0.4-0.3-0.7-0.5
    c-0.3-0.2-0.6-0.4-0.9-0.6c-0.7-0.8-2.6-2.7-3.6-3.6c1.3-1.5,2.5-3.2,3.8-5l6.2,2.9c-1.6,2.6-3.1,4.8-4.4,6.3
    C155.2,140.8,155,141,154.9,141.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M170.8,113.7c0.4-1.2,0.8-2.4,1.1-3.6
    c0.6,0.1,1.7,0.4,3,0.5c1.3,0.1,1.7,0,2.3-0.1c0.3,0,0.6-0.1,1-0.1c1.2-0.1,1.7-0.2,2.9-0.4l0.5-0.1c0.3-0.1,0.6,0,0.8,0
    c-0.3,1.2-0.5,2.5-0.9,3.8c-0.4,0.1-1,0.2-1.8,0.2c-1,0-1.7,0-3.5-0.1c-1.1,0-2,0-3,0c-0.6,0-1.3,0-2.1,0c-0.1,0-0.2,0-0.3,0
    C170.8,113.8,170.8,113.7,170.8,113.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M175.3,85.5c0.2,0,0.4,0,0.7-0.1
    c0.2,0,0.5-0.1,0.8-0.1c0.6-0.1,1.4-0.2,2.3-0.5c1.4-0.4,2.4-0.8,3.4-1.1c0.5-0.2,0.8-0.3,0.9-0.3c0.1,0.6,0.2,1.2,0.2,1.8
    c0,0.4,0.1,0.8,0.1,1.2c-0.8,0.6-2.1,1.6-2.8,1.8c-0.4,0.1-0.7,0.2-1,0.2c-0.5,0.1-0.8,0.2-1.4,0.2c-0.2,0-0.4,0-0.6,0.1
    c-0.5,0.1-0.9,0.1-1.5,0.1c-0.4,0-0.8,0-1.1,0C175.4,87.7,175.4,86.6,175.3,85.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M180.9,75.6c-0.2,0.3-0.6,0.6-1.7,1.1
    c-0.6,0.2-0.9,0.3-1.3,0.3c-0.3,0-0.7,0.1-1.4,0.2c-0.7,0.2-1.6,0.3-2.2,0.3c-0.2-0.8-0.3-1.5-0.5-2.3c0.4-0.1,0.9-0.3,1.3-0.5
    c0.3-0.1,0.5-0.2,0.7-0.3c0.3-0.1,0.8-0.3,1.2-0.6c0.5-0.2,0.9-0.5,1.2-0.6c0.7-0.2,1.7-1.2,1.7-1.3c0.1-0.1,0.1-0.2,0.2-0.3
    c0.4,1.2,0.8,2.4,1.2,3.5C181.2,75.4,181,75.5,180.9,75.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M179.3,69.3c-0.5,0.2-1.5,0.5-1.8,0.8
    c-0.2,0.2-0.6,0.3-1,0.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6,0.3-1.3,0.5-1.8,0.6l-0.1,0c-0.2,0.1-0.7,0-1.2,0c-0.3-0.9-0.7-1.8-1.1-2.7
    c0.8-0.1,1.5-0.3,2-0.5c0.4-0.2,0.8-0.6,1.2-0.9c0.2-0.2,0.5-0.4,0.6-0.4c1-0.3,1.5-0.8,1.8-1.2c0.1-0.2,0.3-0.4,0.4-0.6
    C178.2,66.6,178.8,68,179.3,69.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M173.8,57.8c0,0.1-0.1,0.2-0.1,0.3
    c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2,0.4-0.6,1.1-0.9,1.2c-0.2,0.1-0.6,0.5-1,0.9c-0.4,0.4-0.8,0.8-1.1,1c-0.1,0.1-0.3,0.2-0.4,0.3
    c-0.4,0.3-1,0.7-1.6,1c-0.4-0.6-0.8-1.2-1.2-1.7c0.2,0,0.3,0,0.4,0c0.2-0.1,0.8-0.5,1.5-0.9c0.8-0.5,1.8-1.6,2.1-1.9
    c0.5-0.5,0.7-1,0.9-1.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.5-0.3,0.8-0.3C173.3,56.9,173.6,57.4,173.8,57.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M163,56.1c0.4-0.4,0.9-1.1,1.2-1.7
    c0.2-0.4,0.5-0.9,0.8-1.3c0.2-0.3,0.4-0.6,0.5-0.9c0.1-0.2,0.3-0.5,0.5-0.7c0.3-0.4,0.6-0.9,0.8-1.3c0.2-0.5,0.4-1.5,0.5-2.2
    c0.7,0.9,1.3,1.7,1.9,2.6c-0.2,0-0.4,0.1-0.6,0.3c-1.7,2-3.1,4.2-5.2,5.9C163.3,56.4,163.1,56.3,163,56.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M166.5,50c-0.2,0.4-0.5,0.8-0.8,1.3
    c-0.2,0.3-0.4,0.6-0.5,0.8c-0.1,0.2-0.3,0.5-0.5,0.8c-0.3,0.4-0.6,0.9-0.9,1.4c-0.3,0.6-0.8,1.2-1.1,1.6c-0.2-0.2-0.4-0.4-0.6-0.7
    c0.3-0.1,0.5-0.2,0.6-0.3c1.1-1.8,2.3-3.5,3.3-5.4c0.1-0.3-1.3-0.2-1.5,0.3c-0.9,1.7-1.9,3.3-2.9,4.9c-0.5-0.6-1.1-1.2-1.7-1.7
    c0.1,0,0.3,0,0.3-0.1c0.3-0.2,1.4-0.8,2.3-2.1c0.5-0.7,0.5-0.8,0.7-0.9c0.1-0.1,0.2-0.2,0.5-0.6c0.8-0.9,1.6-2.4,1.7-2.7l0.5-0.4
    c0.4,0.5,0.8,1,1.2,1.5C167,48.1,166.7,49.5,166.5,50z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M154.1,49.1c-0.8,1-2.3,2.9-3.4,4
    c-1,1-1.8,2.4-2.2,3.2c-2.1-1.6-5-3.1-8.3-4.5c0.5-1,1.4-2.7,2.3-3.7c1-1.1,1.6-4.6,1.8-6.2C147.6,44,150.8,46.3,154.1,49.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M105,43.2c0.1-1.7,0.2-7.6,0.4-9.3
    c0.1-1.4,0.8-2.9,1.4-3.8c0.5,0.1,0.9,0.1,1.3,0.2l1.1,0.2c2,0.3,4.1,0.6,6.4,1l-1.1,13.1C111.3,44.1,108.1,43.6,105,43.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M105.1,33.9c-0.1,1.7-0.3,7.5-0.4,9.3
    c-1-0.1-2-0.3-3.1-0.4c-2-0.2-3.9-0.4-5.7-0.5L95,30.2c4.2-0.4,8.1-0.4,11.4,0C105.8,31.1,105.2,32.6,105.1,33.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.6,30.2l0.9,12c-2-0.1-3.9-0.2-5.7-0.3
    l-1.1-11.1C90.7,30.6,92.7,30.4,94.6,30.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M80.8,43.6C80.9,43.7,80.9,43.7,80.8,43.6
    c0.1,0.2,0.1,0.3,0.1,0.8V45l-0.1,0.2c-0.4,0.1-0.7,0.1-1.1,0.2c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.2c0-0.2-0.1-0.5-0.2-0.8
    C80.1,43.7,80.5,43.7,80.8,43.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.1,44c0,0.1,0,0.1,0,0.2
    c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.1,0.5,0.1,0.7l-0.1,0.4c-0.4,0.1-0.7,0.2-1.1,0.3c0,0,0,0,0-0.1c0.1-0.2,0.1-0.5,0.1-1
    c0-0.2,0-0.6-0.1-0.8C77.4,44.2,77.8,44.1,78.1,44z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M75.2,44.7c0.1,0.3,0.2,0.7,0.3,0.8
    c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.3c-0.4,0.1-0.8,0.3-1.2,0.4l0-0.3l-0.1-0.8L74,45
    C74.4,44.9,74.8,44.8,75.2,44.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M72.2,45.6c0,0.2,0.1,0.3,0.2,0.4
    c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.5c0,0.1,0,0.3,0,0.5c-0.6,0.2-1.2,0.4-1.8,0.7l-0.6-0.8l-0.3-0.9
    C70.8,46.1,71.5,45.8,72.2,45.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M64.2,49.6c0.2,0.1,0.2,0.1,0.3,0.2
    c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.2,0.8,0.2,1c-0.6,0.4-1.3,0.7-1.9,1.1c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.3,0-0.3
    c0-0.1-0.1-0.3-0.4-0.6c0.3-0.2,0.6-0.4,0.8-0.6C63.6,50,63.9,49.8,64.2,49.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M60.7,52.1c0,0.1,0.1,0.2,0.2,0.2
    c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1c-0.6,0.4-1.1,0.7-1.6,1.1l-0.4-0.7L59,53.4
    C59.5,53,60.1,52.5,60.7,52.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M47.1,64.2l-9.9-4.8c1.2-2.1,2.4-4.1,3.8-5.9
    c1.4,1.1,3.8,3.2,4.5,3.9c0.8,0.8,4.4,0.6,6.5,0.3c0.2,0,0.3-0.1,0.5-0.1C50.3,59.7,48.6,61.9,47.1,64.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M46.9,64.5c-0.9,1.4-1.7,2.9-2.4,4.5
    c-3-1.6-6.2-2.4-9.3-3.8c-0.3-0.1-0.8,0-1.1,0.2c0.9-2,1.8-4,2.8-5.8L46.9,64.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M40.2,81.9c-1.3-0.3-3.9-0.8-6.4-0.9
    c-0.8,0-1.4,0-2,0c-1,0-1.9,0-2.5-0.1c0.7-3.2,1.6-6.4,2.6-9.3c1.4,0.5,4.1,1.3,5.8,1.9c1.8,0.6,3.8,1.4,4.6,1.8
    C41.5,77.4,40.8,79.6,40.2,81.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M26.1,95.4c0.1-0.5,0.2-0.9,0.3-1.4
    c0.6,0.5,1.8,1,3.4,0.7c1.9-0.3,6.1-0.3,8.2-0.3c-0.1,1.6-0.1,3.2,0,4.8c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.4,0.4-0.7,0.7
    c-1.4,1.2-3.3,2.3-4.1,2.1c-1.2-0.3-6.7-0.3-8.5-0.3C25.1,99.9,25.6,97.7,26.1,95.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M23.7,108.4c0.9,0.6,2.7,1.1,4.6,0.9
    c2.3-0.2,7.9-0.3,9.6-0.3c0,0.9,0,1.9,0.1,2.8c-0.9,0-4.2,0-7.3,0.5c-2.7,0.4-5.9,0.6-7.3,0.7C23.5,111.4,23.6,109.9,23.7,108.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M24,119.5c4.8-1.6,9.5-3.6,14.6-3
    c0.2,0.8,0.4,1.7,0.6,2.6c-2.4,0.9-8.5,3.2-10.4,4.4c-1.3,0.8-2.3,1.1-3.1,0.9c-0.3-0.1-0.6-0.2-0.8-0.5
    C24.6,122.4,24.3,120.9,24,119.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M76.3,162.7c-0.9,3.1-1.9,6.1-3.1,9
    c-1.2-0.2-2.3-0.5-3.4-0.7c0.5-1.5,1.3-4.4,1.4-5.5c0.1-1,1.4-3,2.1-4.1C74.3,161.9,75.3,162.3,76.3,162.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M74.7,172c1.1-2.9,2.1-5.8,3-8.8
    c0.8,0.3,1.7,0.6,2.5,0.9l-2.1,7.4l-1.8,0.7C75.7,172.2,75.2,172.1,74.7,172z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M112.9,172.4c0-0.2-0.1-0.4-0.1-0.7
    c-0.1-0.6-0.2-1.2-0.2-1.6c0-0.8-0.3-1.9-0.5-2.6l-0.1-0.3c-0.1-0.4-0.3-1.3-0.5-2c1-0.2,2-0.3,3.1-0.5v1.7c0,0.7-0.1,1.1-0.1,1.4
    c-0.1,0.6-0.1,0.8,0.3,1.7c0.4,0.9,0.6,1.9,0.7,2.6C114.7,172.2,113.8,172.3,112.9,172.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M115.1,169.4c-0.3-0.8-0.3-1-0.2-1.5
    c0-0.3,0.1-0.7,0.1-1.5v-1.7c0.8-0.2,1.7-0.4,2.5-0.6c0,0.2,0,0.4,0.1,0.7c0.1,0.4,0.2,1,0.2,1.8c0.1,1,0.2,1.3,0.4,1.6
    c0.1,0.2,0.3,0.5,0.4,1.2c0.3,1.1,0.4,1.7,0.3,2.1c-1,0.2-2.1,0.3-3.1,0.5C115.8,171.3,115.5,170.3,115.1,169.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M119,169.3c-0.2-0.7-0.3-1-0.5-1.2
    c-0.2-0.3-0.3-0.5-0.4-1.5c-0.1-0.8-0.2-1.4-0.2-1.8c0-0.3-0.1-0.6-0.1-0.7c0.3-0.1,0.6-0.1,0.9-0.2c0,0,0.1,0,0.1,0
    c1,2.4,1.8,4.9,2.4,7.4c-0.6,0.1-1.2,0.2-1.8,0.3C119.4,171,119.3,170.3,119,169.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M135.5,167.6c-0.1-0.3-0.2-0.8-0.6-1.3
    c-0.5-0.8-0.4-0.9-0.4-1.2c0-0.2,0-0.5-0.2-1.2c-0.3-0.8-0.4-1.2-0.6-1.7c-0.1-0.4-0.2-0.9-0.5-1.7c-0.3-1-0.3-1.3-0.3-1.5
    c0-0.1,0-0.2,0-0.3c0.5-0.3,1.1-0.5,1.6-0.8c0.1,0.3,0.3,0.8,0.6,1.3c0.3,0.6,0.8,1.4,1.1,2.4c0.3,0.9,0.4,1.1,0.5,1.3
    c0.1,0.3,0.1,0.4,0.7,2.3c0.2,0.6,0.3,1,0.4,1.3c0,0.1,0.1,0.2,0.1,0.3C137,167,136.3,167.3,135.5,167.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M156.7,152c-0.4-0.3-0.8-0.4-1.2-0.6
    c-0.6-0.3-1.3-0.5-2.2-1.3c-1.1-0.9-1.3-1.3-1.6-1.7c-0.1-0.1-0.2-0.3-0.3-0.5c-0.2-0.3-0.5-0.4-0.6-0.6c-0.1,0-0.2-0.1-0.2-0.1
    c0,0,0,0,0.1-0.1c0.3-0.3,0.6-0.5,0.9-0.8c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.5,0.2c0.3,0.1,0.3,0.1,0.8,0.5
    c0.2,0.2,0.4,0.4,0.8,0.7c1.5,1.3,2.4,1.8,3.3,2.3l0.2,0.1c0.3,0.2,0.5,0.3,0.7,0.4c0.3,0.1,0.5,0.2,1,0.7c0.4,0.4,0.8,0.7,1,0.9
    c-0.4,0.4-0.9,0.9-1.3,1.3C158.4,153.1,157.7,152.6,156.7,152z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M159.3,151.2c-0.5-0.6-0.8-0.7-1.1-0.8
    c-0.2-0.1-0.4-0.2-0.7-0.3l-0.2-0.1c-0.8-0.5-1.8-1-3.2-2.3c-0.3-0.3-0.6-0.5-0.8-0.7c-0.5-0.4-0.5-0.4-0.9-0.6
    c-0.1,0-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1,0c0.1-0.1,0.3-0.2,0.4-0.4c0,0.1,0.1,0.1,0.2,0.2c1.5,0.8,3,1.6,4.5,2.5
    c0.8,0.5,1.6,1,2.3,1.6c0.2,0.2,0.5,0.3,0.8,0.4c0.1,0.1,0.2,0.1,0.3,0.1c-0.1,0.4,0.4,0.6,0.9,0.6c-0.3,0.3-0.5,0.6-0.8,0.8
    C160.1,151.9,159.7,151.6,159.3,151.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M160.5,149.1c-2.3-1.4-4.5-2.8-6.8-4.1
    c-0.2-0.1-0.4-0.1-0.6-0.1c0.5-0.5,1-0.9,1.4-1.4c0.4,0.2,2.1,1.3,3.1,2.2c0.9,0.8,1.8,1.3,2.6,1.8c0.3,0.1,0.5,0.3,0.7,0.4
    c0.6,0.4,1.6,0.8,2.3,1c-0.4,0.4-0.8,0.9-1.2,1.3C161.9,149.8,161,149.5,160.5,149.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M161.5,135.5c0.6,0.5,1.9,1.5,3.1,2.3
    c1.3,0.9,2.3,1.1,3,1.2c0.3,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.7,1,1,1.8c-0.5,0.7-0.9,1.4-1.4,2c-1-1-2.9-2.8-3.5-3.2
    c-0.6-0.4-3.2-2-4.2-2.6C160.6,136.7,161,136.1,161.5,135.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M169.5,140.7c-0.3-0.7-0.7-1.6-1.1-1.8
    c-0.2-0.1-0.5-0.2-0.8-0.2c-0.7-0.2-1.6-0.4-2.8-1.2c-1.3-0.8-2.5-1.8-3.1-2.3c0.7-1,1.4-2,2-3c0.2,0.1,0.3,0.2,0.4,0.4
    c0.1,0.2,0.3,0.3,0.4,0.5c0.7,0.8,1,1.2,2.3,2.1c1,0.7,1.4,0.8,1.8,0.9c0.3,0.1,0.6,0.2,1,0.4c0.3,0.1,0.6,0.3,0.8,0.4
    c0.5,0.2,0.9,0.5,1.2,0.5C171,138.4,170.3,139.5,169.5,140.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M173.3,134.4c-0.6-0.6-1.8-1.5-3.1-1.9
    c-0.7-0.2-1.1-0.3-1.4-0.3c-0.6-0.1-0.9-0.2-1.8-0.5c-1-0.4-1.9-1-2.3-1.4c0.5-0.8,0.9-1.7,1.3-2.6c0.1,0,0.2,0.1,0.3,0.2
    c0.9,0.5,1.5,0.6,2.2,0.8c0.4,0.1,0.8,0.2,1.4,0.4c0.6,0.2,1,0.4,1.3,0.5c0.5,0.2,0.9,0.4,1.9,0.7c0.4,0.1,0.7,0.3,1,0.4
    c0.3,0.1,0.7,0.3,0.9,0.3C174.5,132.2,173.9,133.3,173.3,134.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M176.6,127.8c-0.6-0.3-2.1-1.1-3.5-1.4
    c-0.5-0.1-0.8-0.1-1-0.1c-0.4,0-0.6,0-1.7-0.7c-0.8-0.5-1.8-0.9-2.5-1.1c-0.1,0-0.2-0.1-0.3-0.1c0.4-1,0.7-2,1.1-3
    c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1,0,0.1,0c1.2,0,1.6,0.1,2.2,0.2c0.4,0.1,0.8,0.1,1.6,0.2c0.9,0.1,1.1,0.2,1.3,0.3
    c0.3,0.1,0.6,0.2,2.3,0.3c1.4,0.1,2.2,0,2.8-0.1C178.3,123.9,177.5,125.9,176.6,127.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M180.3,118c-0.5-0.2-1.3-0.6-2.3-0.6
    c-0.5,0-1.1,0-1.7-0.1c-0.9-0.1-1.9-0.2-2.8-0.1c-1.1,0.1-2.2,0.1-3,0.1c-0.2,0-0.4,0-0.5,0c0,0-0.1,0-0.1,0c0.3-1,0.6-2.1,0.9-3.1
    c0.1,0,0.2,0,0.4,0c0.8,0,1.5,0,2.1,0c1.1,0,1.9,0,3,0c1.8,0.1,2.4,0.1,3.5,0.1c0.3,0,1,0,1.7-0.1C181,115.4,180.7,116.7,180.3,118
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M182.4,109.6c-0.2-0.1-0.5-0.1-0.9,0l-0.5,0.1
    c-1.2,0.2-1.6,0.2-2.8,0.4c-0.4,0-0.7,0.1-1,0.2c-0.6,0.1-1,0.2-2.2,0.1c-1.3-0.1-2.4-0.4-2.9-0.5c0.4-1.4,0.8-2.9,1.1-4.3
    c0.1,0,0.2,0,0.5,0.1c0.2,0.1,0.5,0.1,0.9,0.2c0.4,0.1,0.7,0.2,1,0.3c1.3,0.3,2.1,0.5,3.5,0.4c0.4-0.1,0.7-0.1,1-0.1
    c1-0.1,1.6-0.2,2.2-0.5c0.3-0.2,0.7-0.3,0.9-0.5C182.9,106.7,182.7,108.1,182.4,109.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M183.7,99.9c-0.2,0.1-0.5,0.2-0.8,0.4
    c-0.4,0.2-0.9,0.2-1.4,0.3c-0.5,0.1-1,0.1-1.6,0.3c-0.6,0.2-0.9,0.2-1.3,0.2c-0.4,0-0.8,0-1.5,0.2c-1,0.2-2.4,0.2-3.1,0.2
    c0.3-1.4,0.5-2.8,0.7-4.2l0,0c1.8,0.7,2.1,0.8,3.4,0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.8-0.2,1.5-0.4,2-0.4c0.4,0,0.8-0.1,1.1-0.2
    c0.4-0.1,0.7-0.2,1.1-0.2c0.3,0,0.5-0.1,0.7-0.2C183.9,97.5,183.8,98.7,183.7,99.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M184,92.4c-0.2,0-0.3,0.1-0.5,0.2
    c-1.1,0.6-2.1,1.1-3,1.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.3,0.1-0.3,0.1-1.2,0.1l-0.9,0c-1.4,0-2.3,0-2.8,0c0.2-1.7,0.3-3.4,0.3-5.1
    c0.3,0,0.7,0,1.1,0c0.7,0,1.1,0,1.5-0.1c0.2,0,0.4,0,0.6-0.1c0.6,0,0.9-0.1,1.4-0.2c0.3-0.1,0.6-0.1,1-0.2c0.7-0.1,2-1,2.8-1.7
    C183.9,88.4,184,90.3,184,92.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M181.9,72.7c0.8,0.4,1.9,0,2.1,0
    c0.1,0,0.6-0.3,1.4-0.7c0.5-0.3,1.2-0.6,1.7-0.9c0.4,1.8,0.8,3.6,1,5.5c-0.9,1.2-3.2,2.1-4.3,2.5C183.3,77,182.7,74.9,181.9,72.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M177.6,62.1c0.6,0,1.3-0.2,2.4-0.8
    c1.4-0.7,2.8-1.3,3.6-1.6c0.6,1.4,1.2,2.8,1.6,4.3c0.1,0.2,0.1,0.4,0.2,0.6c-0.9,0.6-3,1.6-5.5,2.5
    C179.1,65.5,178.4,63.8,177.6,62.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M172,56.7c-0.1,0.1-0.1,0.2-0.2,0.3
    c-0.2,0.4-0.4,0.8-0.9,1.3c-0.6,0.7-1.4,1.5-2,1.9c-0.6,0.4-1.2,0.7-1.4,0.8c-0.1,0-0.3,0-0.6-0.1c0,0,0,0,0,0
    c-0.4-0.5-0.8-1-1.2-1.5c0.3-0.5,0.8-1.1,1.1-1.3c0.2-0.2,0.6-0.6,1-1c0.4-0.4,0.8-0.8,0.9-0.9c0.1-0.1,0.4-0.2,0.6-0.4
    c0.6-0.3,0.9-0.6,1.1-0.7c0,0,0.1,0,0.1,0c0.1,0,0.3-0.2,0.6-0.8c0.1-0.2,0.2-0.4,0.2-0.6c0.5,0.8,1,1.6,1.5,2.4
    C172.6,56.3,172.2,56.4,172,56.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M168.7,54.1c-0.5,0.5-0.6,0.7-1,1
    c-0.2,0.2-0.4,0.4-0.8,0.7c-1,0.9-1.2,1.2-1.7,1.8l-0.2,0.2c-0.1,0.2-0.3,0.2-0.4,0.3c-0.2-0.2-0.3-0.4-0.5-0.6
    c2.3-1.6,3.8-4,5.6-6.1c0.3,0.4,0.5,0.8,0.8,1.1C170,52.9,169.2,53.6,168.7,54.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M165.2,46.2L165.2,46.2c0,0.1-0.9,1.7-1.7,2.7
    c-0.3,0.4-0.4,0.5-0.5,0.6c-0.2,0.2-0.2,0.2-0.7,0.9c-0.8,1.2-1.8,1.8-2.1,2c-0.1,0-0.3,0-0.6,0c-0.4-0.4-0.8-0.8-1.2-1.2
    c0.3-0.3,0.7-0.7,0.9-1c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.4,0.5-0.7c0.3-0.5,0.6-1,0.9-1.2c0.4-0.3,0.7-0.9,0.9-1.3
    c0.1-0.2,0.3-0.5,0.3-0.6c0.1-0.1,0.3-0.3,0.5-0.7c0.2-0.3,0.5-0.8,0.7-0.9c0.2-0.2,0.4-0.4,0.5-0.7c0.6,0.7,1.2,1.4,1.7,2
    L165.2,46.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M161.2,43.7c-0.2,0.5-0.5,1-1.3,2.1
    c-0.4,0.6-0.6,0.8-0.7,1c-0.1,0.2-0.3,0.4-0.7,1c-0.9,1.2-1.3,1.6-1.6,1.9c0,0,0,0,0,0c-0.3-0.3-0.7-0.7-1-1
    c0.3-0.3,0.5-0.9,0.8-1.4c0.1-0.2,0.2-0.5,0.3-0.7c0.3-0.5,1.2-2.3,1.4-2.6c0-0.1,0.1-0.2,0.2-0.4c0.2-0.5,0.5-1.3,0.9-1.7
    c0.1-0.2,0.3-0.3,0.4-0.4c0.3-0.3,0.6-0.6,0.7-1.3c0,0,0,0,0-0.1c0.6,0.6,1.1,1.1,1.7,1.7C161.8,42.4,161.3,43.3,161.2,43.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M159.6,39.3c-0.8,0.8-2.5,2.5-2.9,3.3
    c-0.3,0.5-0.5,0.8-0.7,1.1c-0.2,0.2-0.4,0.5-0.6,0.9c-0.2,0.5-1.4,1.4-2,1.8c-0.4-0.4-0.9-0.8-1.4-1.2c0.5-0.5,1-1.3,1.3-1.7
    c0.2-0.3,0.3-0.8,0.5-1.3c0.1-0.3,0.2-0.7,0.3-1c0.1-0.2,0.2-0.5,0.3-0.8c0.2-0.5,0.4-1.1,0.6-1.2c0.2-0.3,0.5-0.8,0.6-1.3
    c0.1-0.3,0.1-1.2,0.1-1.9C157.1,37.1,158.4,38.2,159.6,39.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M124.6,33.6l-1.7,12.9c-2.6-0.6-5.3-1.2-8-1.7
    l1.1-13.1C118.7,32.1,121.6,32.7,124.6,33.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M110.2,29.2l0,0.2c-0.2,0-0.5-0.1-0.7-0.1
    l-1.1-0.2c-1.3-0.2-2.7-0.3-4.3-0.4l0-0.2C106.1,28.6,108.1,28.9,110.2,29.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M89.2,29.2l0.1-1.5c1.7,0,3.5,0,5.2,0.1
    l0.1,0.9C92.8,28.9,91,29,89.2,29.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M88.4,31l1.1,11c-3.1,0-6,0.1-8.8,0.4
    c-1.2-1-3.6-3.7-3.8-4.1c-0.1-0.4-0.8-2.9-1.4-4.7C79.8,32.5,84.1,31.6,88.4,31z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M75.1,33.7c0.6,2.1,1.4,4.5,1.4,4.6
    c0,0.3,2.5,3,3.7,4.1c-3.6,0.5-7,1.3-10.2,2.7c-0.3-0.9-0.9-2.6-1.4-3.4c-0.2-0.4-0.8-1.3-1.4-2.2c-0.5-0.8-1.3-2-1.8-2.8
    C68.5,35.5,71.8,34.5,75.1,33.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M62.5,49.1c-1.4,1-2.6,1.9-3.8,2.8l-9.7-7.1
    c1.5-1.2,3.1-2.3,4.7-3.2c0.6-0.3,1.2-0.6,1.7-0.9c1,1.4,2.6,3.6,2.9,4.3C58.9,45.9,61.1,48.5,62.5,49.1
    C62.6,49.1,62.6,49.1,62.5,49.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M34,66c3.3,1.5,6.9,2.3,10,4.2
    c-0.2,0.4-0.3,0.8-0.5,1.2c-0.4,1.2-0.9,2.4-1.3,3.6c-0.9-0.4-2.8-1.2-4.6-1.8c-1.7-0.6-4.4-1.4-5.8-1.9c0.6-1.8,1.3-3.6,2-5.3
    C34,66,34,66,34,66z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M27,84.8c-0.4,2-0.7,3.8-1.1,5.6
    c-0.4,0.2-1.2,0.4-2.6,0.3c-1.8-0.1-3.3-0.5-4.1-0.8c0,0,0-0.1,0-0.1c0.6-2.6,1.1-5.1,1.7-7.5c0.5,0.3,1.3,0.6,2.1,0.9
    c1.5,0.5,3.5,0.2,4.3,0.1C27.2,83.8,27.1,84.3,27,84.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M22.5,118c-0.4,0.2-1,0.5-1.9,0.7
    c-1.6,0.4-3,0.4-3.9,0.4c-0.1-2.5-0.1-5,0-7.4c0.6,0.5,1.4,0.8,2.6,0.6c1.4-0.3,2.4-0.8,3-1.2C22.1,112.8,22.5,117.8,22.5,118z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M16.7,119.4c0.9,0,2.4,0,4-0.4
    c0.9-0.2,1.5-0.4,1.9-0.6c0,0.2,0,0.4,0.1,0.6c-0.4,0.3-0.3,0.8,0.1,0.9c0.2,1.4,0.5,2.9,0.9,4.4c-0.6,0.5-1.6,1.2-3.2,1.7
    c-1.5,0.5-2.5,0.9-3.1,1.3C17,124.5,16.8,121.9,16.7,119.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M25.7,124.7c0.9,0.2,2-0.1,3.3-1
    c1.9-1.2,7.9-3.5,10.3-4.4c0.6,1.9,1.3,4,2.4,6.2c-1.2,1.1-4,3.5-5.8,4.4c-1.8,1-5.8,3.2-7.6,4.2c-0.7-1.7-1.3-3.5-1.9-5.3
    c-0.5-1.5-0.9-3-1.3-4.4C25.3,124.6,25.5,124.7,25.7,124.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M32.1,143.1c1-0.5,2.8-1.4,5.7-3.2
    c3.2-2,5.8-4,7.1-5.1c0.3-0.2,0.5-0.4,0.7-0.5c0.9,1.8,2,3.7,3.3,5.5c0,0,0,0,0,0c-4.1,3.6-8.2,7.3-12.7,10.3
    C34.8,148,33.4,145.6,32.1,143.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M49.6,140.8c0.8,1.2,1.7,2.4,2.7,3.6
    c-0.9,1.4-3.2,4.7-5.3,6.2c-2,1.4-4.8,4.4-6,5.7c-1.5-1.6-2.9-3.4-4.2-5.3C41.4,148.1,45.5,144.4,49.6,140.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M49.7,163.6c1-0.3,1.9-1.1,2-1.2l8.1-10.1
    c2.1,1.8,4.3,3.6,6.6,5.2c-0.3,1.3-2.5,3.5-3.7,4.8c-0.8,0.8-1.1,1.1-1.1,1.3c-0.1,0.4-1.1,3.6-1.5,4.7c-1.5-0.5-2.9-1-4.4-1.6
    C53.5,165.7,51.5,164.7,49.7,163.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M65.4,169.9c0.7-0.6,1.3-1.6,2-2.8
    c0.5-0.9,2.8-5.2,3.6-6.8c0.6,0.3,1.3,0.7,1.9,1c-0.8,1.1-2,3.1-2.2,4.2c-0.1,1.1-0.9,4-1.4,5.5C68.1,170.6,66.8,170.3,65.4,169.9z
    "
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M78.4,171.8l2.1-7.6c0.8,0.2,1.6,0.5,2.4,0.7
    l-1.7,5.5l-0.5,2.5c-1.3-0.1-2.6-0.3-3.8-0.5L78.4,171.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M84.8,173.3c0.2-0.7,0.2-1.6,0.3-2.5
    c0-0.5,0.1-1,0.1-1.4c0.2-1.3,0.8-3.2,1-3.8c0.6,0.1,1.3,0.2,1.9,0.3c0.1,0.5,0.2,1.3,0.1,2.2c0,0.6-0.3,1.5-0.5,2.5
    c-0.3,1-0.5,2-0.6,2.8C86.3,173.4,85.5,173.3,84.8,173.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M91.5,171.8c0.1-0.4,0.1-0.8,0.2-1.1
    c0.1-0.7,0.2-1.7,0.3-2.6c0-0.6,0.1-1.4,0.2-1.7c1.3,0.1,2.7,0.1,4.1,0.1c0.1,1.5,0.1,4,0.1,5.3c0,0.5-0.1,0.9-0.1,1.4
    c0,0.1,0,0.2,0,0.3c-1.7,0-3.4,0-5,0C91.2,173.1,91.3,172.6,91.5,171.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M101.6,173.3c0.1-0.5,0.1-1,0.1-1.6
    c0-1.1-0.1-2.4-0.2-3.5c-0.1-0.8-0.2-1.7-0.1-1.8c0,0,0.1-0.1,0.2-0.1c0.5,0,1.1-0.1,1.7-0.1c0,0.3-0.1,0.6,0,1.1
    c0,0.4,0.1,0.7,0.2,1c0.1,0.6,0.3,1.2,0.2,2.3c0,0.7-0.1,1.3-0.1,1.9c0,0.3-0.1,0.6-0.1,0.9C102.9,173.3,102.2,173.3,101.6,173.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M107.1,172.9c-0.1-0.4-0.1-0.8-0.1-1
    c0-0.5-0.2-1.3-0.3-2.1c-0.1-0.4-0.1-0.8-0.2-1.1c-0.1-0.6-0.4-2.2-0.5-2.9c0.3,0,0.6,0,0.9-0.1c0.4,0,0.7-0.1,1.1-0.1
    c0.1,0.6,0.3,1.6,0.3,2.5c0,0.3,0,0.5,0,0.6c0,0.5,0,0.6,0.2,1.6c0.1,0.5,0.2,0.9,0.3,1.3c0.1,0.4,0.1,0.7,0.2,1
    c-0.1,0-0.3,0-0.4,0C108.1,172.9,107.6,172.9,107.1,172.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M129.7,169.3c0-0.1,0-0.1-0.1-0.2
    c-0.4-1-0.7-1.8-0.9-2.4c-0.3-0.7-0.5-1.2-0.6-1.7c-0.1-0.5-0.3-0.8-0.5-1c-0.2-0.3-0.3-0.5-0.3-0.9c0-0.4-0.5-1-0.9-1.5
    c-0.1-0.1-0.1-0.1-0.2-0.2c0.9-0.3,1.9-0.7,2.8-1.1c0,0.1,0.1,0.3,0.1,0.4c0.2,0.6,0.2,0.9,0.3,1.2c0.1,0.3,0.1,0.6,0.5,1.6
    c0.2,0.4,0.3,0.7,0.4,0.9c0.3,0.7,0.3,0.8,0.5,2c0.1,0.6,0.1,1.1,0.1,1.6c0,0.3,0,0.7,0.1,0.9C130.5,169.1,130.1,169.2,129.7,169.3
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M138.1,166.6c0-0.1,0-0.2-0.1-0.3
    c-0.1-0.3-0.2-0.7-0.4-1.3c-0.5-2-0.5-2-0.7-2.3c-0.1-0.2-0.2-0.5-0.5-1.3c-0.3-1-0.8-1.8-1.1-2.4c-0.3-0.5-0.5-1-0.6-1.3
    c0.7-0.3,1.3-0.7,2-1.1c0.1,0.2,0.3,0.6,0.6,1.8c0.3,1.1,0.5,1.6,0.7,2.2c0.2,0.5,0.4,1,0.6,1.9c0.2,0.8,0.2,1.2,0.3,1.6
    c0,0.5,0,0.8,0.5,1.4c0.1,0.1,0.2,0.2,0.4,0.3C139.4,166.1,138.7,166.4,138.1,166.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M143.3,164.3c0-0.5-0.1-1-0.4-1.5
    c-0.3-0.4-0.5-0.8-0.7-1.2c-0.4-0.7-0.7-1.3-1.1-2.2c-0.2-0.5-0.4-0.9-0.6-1.2c-0.3-0.5-0.5-0.9-0.7-1.6c-0.1-0.5-0.4-1-0.7-1.3
    c0,0,0,0,0,0c0.5-0.3,0.9-0.5,1.4-0.8c0.1,0.2,0.2,0.5,0.4,0.9c0.5,1,0.8,1.5,1.1,2c0.3,0.5,0.7,1,1.2,2.1c0.6,1.2,0.6,1.8,0.7,2.3
    c0.1,0.4,0.1,0.8,0.4,1.2c0.1,0.2,0.3,0.3,0.5,0.4C144.3,163.8,143.8,164.1,143.3,164.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M148,161.8c-0.1-0.2-0.1-0.5-0.3-0.7
    c-0.4-0.8-0.8-1.3-1.1-1.9c-0.4-0.6-0.9-1.3-1.2-2.2c-0.3-0.7-0.3-0.9-0.3-1c0-0.3,0-0.5-0.8-1.5c-0.6-0.7-1.1-1.2-1.5-1.4
    c0.7-0.5,1.5-1,2.2-1.5c0.2,0.2,0.5,0.5,0.8,1.1c0.6,1.1,0.8,1.4,1.1,1.7c0.2,0.3,0.4,0.5,0.9,1.4c0.4,0.8,0.5,1.1,0.6,1.4
    c0.1,0.4,0.2,0.7,1,1.4c0.7,0.6,1.4,1,2,1.2C150.2,160.4,149.1,161.1,148,161.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M153.7,157.9c-0.1-0.3-0.4-0.8-0.8-1.4
    c-0.1-0.2-0.3-0.4-0.4-0.7c-0.4-0.6-0.5-0.8-0.7-1c-0.2-0.2-0.5-0.4-1.2-1.3c-0.6-0.8-0.8-1.1-1-1.3c-0.2-0.3-0.2-0.3-0.9-1
    c-0.5-0.6-1.1-0.9-1.4-1.2c-0.1,0-0.1-0.1-0.2-0.1c0.6-0.5,1.3-1,1.9-1.5c0,0,0,0,0,0l0.1,0.1c1.1,0.7,1.1,0.7,1.9,1.7
    c0.2,0.2,0.3,0.4,0.6,0.6c0.6,0.7,1.2,1.3,1.7,1.8c0.6,0.6,1.1,1.1,1.3,1.5c0.2,0.3,0.4,0.8,0.6,1.2c0.2,0.4,0.3,0.7,0.5,1
    C155,156.9,154.4,157.4,153.7,157.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M163.5,148.7c-0.6-0.2-1.7-0.7-2.3-1
    c-0.2-0.1-0.5-0.3-0.7-0.4c-0.8-0.4-1.7-0.9-2.6-1.7c-1-0.9-2.5-1.9-3.1-2.2c0.4-0.4,0.8-0.8,1.1-1.1c0.2,0.1,0.4,0.3,0.7,0.5
    c0.3,0.1,0.5,0.3,0.8,0.4c0.7,0.3,1.3,0.7,1.9,1.1c0.3,0.2,0.6,0.4,0.9,0.6c0.5,0.3,0.9,0.5,1.5,1c0.9,0.8,2.6,1.4,2.6,1.5l0.1,0
    c0,0,0.1,0,0.2,0C164.2,147.8,163.8,148.2,163.5,148.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M166.1,145.4c-0.1-0.4-0.4-0.9-1.2-1.4
    c-0.5-0.3-1-0.6-1.5-0.8c-0.8-0.4-1.4-0.7-2-1.2c-0.9-0.9-2.6-2-3-2.2l0.1-0.5c0.5-0.6,1-1.2,1.5-1.8c1,0.6,3.7,2.3,4.3,2.7
    c0.5,0.3,2.5,2.2,3.4,3.1c-0.2,0.2-0.3,0.4-0.5,0.6C166.8,144.5,166.4,145,166.1,145.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M184.9,85c0-0.1,0-0.3-0.1-0.5
    c0.4,0.1,0.8,0,1.2-0.1c0.8-0.3,2.1-0.5,2.9-0.6c0.1,1.6,0.2,3.3,0.2,5c-0.3,0-0.7,0.1-1.3,0.2c-0.8,0.2-1.6,0.3-2.2,0.3
    c-0.1,0-0.2,0-0.3,0C185.2,87.7,185,86.3,184.9,85z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M185.9,84.1c-0.5,0.2-0.8,0.2-1,0.1
    c0,0,0,0-0.1,0c-0.2-1.5-0.5-3.1-0.9-4.8c1.1-0.3,3.2-1.1,4.3-2.3c0.3,2.1,0.5,4.3,0.7,6.4C188,83.6,186.6,83.9,185.9,84.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M187,70.7c-0.3,0.2-0.9,0.5-1.8,0.9
    c-0.6,0.3-1.2,0.6-1.3,0.7c-0.4,0.1-1.3,0.3-1.9,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-1.5-1.1-3.1-1.8-4.7c1.7-0.6,4.2-1.7,5.4-2.5
    C186,66.9,186.5,68.8,187,70.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M179.2,51.4C179.2,51.4,179.2,51.4,179.2,51.4
    c-0.4,1.3-1.6,2.6-2.3,2.9c-0.7,0.4-1.9,1.2-2.6,1.7c-1-1.7-2.1-3.5-3.2-5.2c0.5-0.1,1.1-0.3,1.6-0.8c1-0.8,2.8-2.3,3.6-2.9
    C177.3,48.5,178.3,50,179.2,51.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M151.5,33c0,0.4-0.1,1-0.3,1.6
    c-0.3,0.8-0.5,1.2-0.7,1.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.4-0.3,1-0.5,1.5c-0.1,0.5-0.2,0.9-0.4,1.2c-0.1,0.2-0.3,0.6-0.5,1
    c-0.3,0.6-0.7,1.3-0.8,1.5c0,0,0,0,0,0c-0.6-0.4-1.1-0.8-1.7-1.2c0.7-1,2-4.3,2.1-5c0.1-0.6,0.4-1.1,0.6-1.6
    c0.2-0.3,0.3-0.6,0.4-0.9c0.2-0.5,0.3-0.9,0.5-1.2C150.5,32.4,151,32.7,151.5,33z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M132,33.4c1.5-2.1,2.3-4.5,2.8-7.1
    c0.5,0.1,0.9,0.3,1.4,0.4c-0.1,0.7-0.2,1.6-0.4,2c-0.4,1-0.9,1.8-1.6,2.8c-0.4,0.5-0.5,0.9-0.6,1.3c-0.1,0.3-0.2,0.6-0.5,1
    C132.8,33.7,132.4,33.6,132,33.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M131.5,25.4c-0.3,0.1-0.5,0.3-0.5,0.6
    c0.1,2.3-1,4.4-2.1,6.3c-0.4-0.1-0.7-0.2-1.1-0.4c0.5-0.8,0.9-2,0.9-2c0.3-0.8,0.5-1.4,0.8-3.1c0.1-0.7,0.3-1.4,0.5-1.9
    C130.4,25.1,131,25.3,131.5,25.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M111.1,21.4c0,0.5,0,1,0,1.4
    c0,0.4,0,0.8,0,1.2c0,0.7,0,0.7-0.1,1.5l-0.1,0.5c-0.1,0.8-0.4,1.6-0.7,2c-0.8-0.1-1.6-0.2-2.4-0.3c0.1-0.3,0.2-0.7,0.4-1.1
    c0.3-0.7,0.4-1.9,0.4-3c0-0.2,0-0.5,0-0.7c0-0.5,0-1,0-1.4c0-0.5,0-0.9,0.1-1.1c0,0,0,0,0,0c0.8,0.1,1.6,0.3,2.4,0.4
    C111.1,20.9,111.1,21.1,111.1,21.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M103.8,28.4l0,0.2c-2.7-0.1-5.7-0.1-8.8,0.1
    l-0.1-0.9C97.8,27.9,100.8,28.1,103.8,28.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M65,36.7c0.4,0.6,1,1.6,1.8,2.9
    c0.6,0.9,1.2,1.8,1.4,2.2c0.4,0.9,1,2.5,1.4,3.4c-2.3,0.9-4.5,2.1-6.6,3.6l0,0c-1.3-0.2-3.8-3-4.2-3.9c-0.4-0.7-1.9-2.9-2.9-4.3
    C58.6,39.2,61.7,37.9,65,36.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M35.5,57.9c-1.1-0.3-2.7-0.7-3.4-1
    c-0.6-0.3-1.3-0.8-1.8-1.1c1.6-3.2,3.4-6,5.3-8.6l5.2,2.1C38.9,51.8,37.1,54.8,35.5,57.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M28.8,76.4c-0.3,0-0.7-0.1-1.2-0.2
    c-1.4-0.3-3.3-0.9-4.4-1.3c-0.1,0-0.2-0.1-0.3-0.1c0.9-3.1,1.9-6.1,2.9-8.8l5.6,1.7C30.5,70.4,29.6,73.3,28.8,76.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M22.9,75c0.1,0,0.2,0.1,0.3,0.1
    c1.1,0.4,3,1.1,4.4,1.4c0.5,0.1,0.9,0.2,1.2,0.2c-0.5,2-1,4.1-1.4,6.2c-0.6,0.1-2.8,0.4-4.3-0.1c-0.9-0.3-1.6-0.6-2.1-0.9
    C21.6,79.5,22.2,77.2,22.9,75z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M19.1,90.2c0.9,0.3,2.4,0.7,4.2,0.8
    c1.2,0.1,2-0.1,2.6-0.2c-0.3,1.5-0.7,2.9-1,4.3c-0.1,0.3-0.1,0.6-0.2,0.9c-0.6,0-1.7-0.2-2.8-0.5c-0.9-0.3-2.6,0.1-3.8,0.4
    C18.3,93.9,18.7,92,19.1,90.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M17.2,102.8c1.2,0.2,3.7,0.5,4.6,0.5
    c0.5,0,1,0,1.4-0.1c-0.4,2.4-0.7,4.7-0.8,7.2c-0.6,0.4-1.6,1-3.1,1.3c-1.2,0.3-2-0.1-2.5-0.7C16.7,108.3,16.9,105.5,17.2,102.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M17.4,127.5c0.6-0.3,1.7-0.8,3.2-1.3
    c1.5-0.5,2.6-1.2,3.2-1.6c0.4,1.5,0.8,3.1,1.4,4.7c0.2,0.6,0.4,1.1,0.6,1.7c-0.3-0.2-0.6-0.3-0.9-0.3c-0.5,0-0.9,0.2-1.2,0.6
    c-0.9,1.1-3.6,2.2-5.2,2.8C18,131.9,17.6,129.7,17.4,127.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M31.4,144.6c-0.7,0.3-1.7,0.8-2.4,1.3
    c-0.7,0.6-4.2,2.3-5.9,3.1c-0.9-2-1.7-4.1-2.4-6.2c0.4-0.5,1.2-1,2.7-1.5c2.7-0.9,4.5-1.9,5.3-2.4
    C29.6,140.8,30.5,142.8,31.4,144.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M23.3,149.3c1.8-0.8,5.3-2.6,6-3.1
    c0.6-0.5,1.6-0.9,2.3-1.2c1.1,2.1,2.3,4.1,3.5,6c-0.4,0.2-0.3,0.4-0.1,0.6c-0.7,0.7-2.2,2-3.5,2.9c-0.8,0.5-2.5,1.9-3.5,3.1
    C26.2,154.8,24.6,152.1,23.3,149.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M28.2,157.7c0.8-0.9,2.2-2.2,3.5-3.1
    c1.4-0.9,3-2.4,3.7-3.1c0.1,0,0.1,0,0.2,0c0.7,1,1.4,1.9,2.1,2.8c-0.3,0.3-0.6,0.7-0.9,1.4c-1.3,2.3-3.8,4.9-5,6.1
    C30.4,160.5,29.3,159.2,28.2,157.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M177.1,136.4c-0.3-0.3-0.7-0.6-1.1-0.7
    c-0.3-0.1-0.7-0.9-1-1.7c0.8-1.5,1.5-3,2.2-4.5c0.3,0.1,0.8,0.2,1.3,0.4c0.5,0.2,1,0.3,1.5,0.5
    C179.1,132.4,178.1,134.5,177.1,136.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M181.9,125.8c-0.5-0.4-1.2-0.8-1.7-0.9
    c-0.3-0.1-0.6-0.1-0.9-0.2c0.5-1.4,1-2.8,1.5-4.1c0.3,0.1,0.7,0.2,1.2,0.3c0.6,0.2,1.2,0.1,1.6,0.1
    C183.1,122.6,182.5,124.2,181.9,125.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M185.6,114.6c-0.3-0.1-0.8-0.2-1.3-0.3
    c-0.5-0.1-1.1-0.1-1.6,0c0.3-1.2,0.6-2.5,0.8-3.7c0.4-0.2,1.1-0.5,1.6-0.4c0.6,0.1,1.2-0.1,1.6-0.5
    C186.4,111.4,186,113,185.6,114.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M187.8,104.3c0,0-0.1,0-0.1,0
    c-0.6-0.1-1.5-0.2-2.5-0.2c-0.2,0-0.4,0-0.6,0c0.2-1.7,0.4-3.3,0.5-4.8c0.4,0,1,0.1,1.5,0.1c0.3,0,0.6,0,0.9,0.1
    c0.3,0,0.7,0.1,1,0.1C188.3,101,188.1,102.6,187.8,104.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M186,93.8c-0.2,0.1-0.3,0.1-0.4,0.2
    c-0.1,0-0.2,0.1-0.3,0.1c0-1.6,0-3-0.1-4.3c0.1,0,0.2,0,0.3,0c0.6-0.1,1.4-0.2,2.2-0.3c0.6-0.1,1-0.2,1.2-0.2c0,1.3,0,2.7-0.1,4
    C188.2,93.3,186.9,93.4,186,93.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M190.4,87.9c1.3,0,2.6-0.2,3.9-0.5
    c0.2,0,0.4-0.1,0.5-0.3c0,0.8,0.1,1.6,0.1,2.5c-0.5,0.1-1.3,0.1-1.6,0.2c-0.3,0.1-1.8-0.2-2.9-0.4
    C190.4,88.9,190.4,88.4,190.4,87.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M194.7,86.4c-0.2-0.1-0.5-0.1-0.7-0.1
    c-1.2,0.2-2.4,0.4-3.6,0.4c0,0-0.1,0-0.1,0c0-0.9-0.1-1.7-0.1-2.6c0.6,0,1.6,0,2.3-0.1c0.6,0,1.4-0.2,2-0.4
    C194.6,84.6,194.7,85.5,194.7,86.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M192.5,83.8c-0.7,0-1.7,0.1-2.3,0.1
    c-0.1-1.2-0.2-2.4-0.3-3.6c0.5,0,1.2,0,1.7-0.1c0.6-0.1,1.8-0.3,2.5-0.5c0.2,1.2,0.3,2.5,0.4,3.7C193.9,83.5,193.1,83.7,192.5,83.8
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M188.3,70.8c0.8-0.1,2.3-0.2,2.9-0.4
    c0.4-0.2,0.9-0.3,1.2-0.3c0.3,1.4,0.6,2.8,0.9,4.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.6,0.4-1.5,0.9-1.9,1c-0.4,0.1-1,0.1-1.5,0.2
    C189,74.1,188.7,72.4,188.3,70.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M186.4,63.6c-0.1-0.3-0.2-0.6-0.3-0.9
    c0.6-0.2,1.7-0.6,2.7-1.2c0.5-0.3,0.8-0.5,1-0.6c0.5,1.4,1,2.9,1.4,4.4c-0.3,0.4-0.7,0.7-0.8,0.8c-0.1,0-0.1,0-0.2,0.1
    c-0.3,0.1-0.7,0.3-1.5,0.4c-0.5,0.1-0.9,0.2-1.3,0.3C187.1,65.8,186.7,64.7,186.4,63.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M183.2,55.8c0.6-0.2,1.4-0.6,1.7-0.8
    c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.6-0.3,1-0.7c0.1-0.1,0.3-0.2,0.4-0.2c0.4,0.9,0.9,1.8,1.3,2.8c-0.6,0.5-1.7,1.3-2.2,1.5
    c-0.4,0.1-1,0.4-1.5,0.6C184.1,57.7,183.6,56.8,183.2,55.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M176.1,46.9c-0.8,0.7-2.6,2.1-3.6,2.9
    c-0.7,0.6-1.2,0.7-1.6,0.7c-1.2-1.8-2.6-3.6-4-5.3c0,0,0,0,0,0c0.3-0.2,0.6-0.3,0.9-0.5c0.7-0.4,1.4-0.8,1.9-1.2
    c0.5-0.3,1.5-1.2,1.7-2.2C173.1,43.1,174.6,44.9,176.1,46.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M166.2,36.3c-0.9,1.1-2.8,3.5-3.1,3.8
    c-0.1,0.1-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.3-0.1c-1.3-1.3-2.7-2.6-4.1-3.8c0.5-0.6,1.4-1.8,1.7-2.2c0.2-0.3,0.7-1.4,0.8-2.3
    C162.6,33.2,164.5,34.7,166.2,36.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M149.7,31.9c-0.1,0.3-0.3,0.7-0.5,1.2
    c-0.1,0.3-0.3,0.6-0.4,0.9c-0.3,0.5-0.6,1.1-0.7,1.7c-0.1,0.8-1.5,4-2,4.8c-1.1-0.8-2.3-1.5-3.5-2.1c0,0,0,0,0,0
    c0.7-0.7,1-1.1,1.2-1.4c0.1-0.2,0.2-0.3,0.4-0.5c0.5-0.6,0.8-1.5,1-2.2c0.1-0.3,0.2-0.7,0.3-1c0.1-0.4,0.2-0.8,0.3-0.9
    c0.1-0.2,0.3-1.3,0.5-2.2C147.4,30.8,148.6,31.3,149.7,31.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M143,29.6c-0.3,0.5-0.6,1.2-0.8,2
    c-0.2,0.9-0.4,1.9-0.7,2.6c-0.1,0.2-0.1,0.4-0.2,0.6l0,0.1c-0.2,0.8-0.3,1.1-0.8,1.9c-0.1,0.2-0.2,0.3-0.3,0.4
    c-0.5-0.3-1.1-0.6-1.6-0.8c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.3,0.2-0.8,0.3-1.3c0.1-0.7,0.3-1.5,0.5-2.1c0.2-0.5,0.4-1.1,0.5-1.6
    c0.2-0.5,0.3-0.9,0.4-1c0.1-0.2,0.4-1,0.5-1.6c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,1.2,0.4,1.8,0.6C143.2,29.2,143.1,29.4,143,29.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M137.7,27.2c-0.1,0.4-0.3,1-0.4,1.5
    c0,0.4-0.2,0.8-0.3,1.3c-0.1,0.4-0.2,0.8-0.3,1.3c-0.2,1-0.4,1.7-0.7,2.5c-0.2,0.4-0.5,0.7-0.7,1c-0.6-0.3-1.2-0.5-1.8-0.8
    c0.3-0.4,0.4-0.7,0.5-1c0.1-0.4,0.2-0.7,0.6-1.2c0.7-1,1.2-1.9,1.6-2.8c0.2-0.5,0.3-1.4,0.4-2.1C136.9,27,137.3,27.1,137.7,27.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M129.1,26.8c-0.3,1.7-0.4,2.3-0.7,3
    c-0.2,0.6-0.6,1.4-1,2c-1.5-0.5-2.9-0.9-4.4-1.3c0.3-0.4,0.5-1.3,0.7-2c0.1-0.3,0.1-0.5,0.1-0.6c0.1-0.3,0.1-0.6,0.1-0.9
    c0-0.3,0-0.7,0.1-1.1c0.1-0.6,0.1-1.7,0.1-2.5c1.8,0.5,3.6,0.9,5.4,1.4C129.4,25.4,129.3,26.1,129.1,26.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M120.7,22.6c-0.1,0.3-0.2,0.6-0.1,0.9
    c0,0.3,0,0.7,0,1c0,0.3-0.1,0.6-0.1,0.9c0,0.5,0,0.5,0,0.7c0,0.1-0.1,0.3-0.1,0.6c-0.1,0.7-0.6,2.1-0.9,2.8
    c-1.1-0.3-2.2-0.5-3.3-0.7c0,0,0,0,0,0c0.6-1.1,0.7-1.9,0.9-2.6c0.1-0.3,0.1-0.6,0.2-0.9c0.2-0.6,0.2-1,0.2-1.3
    c0-0.2,0-0.4,0.1-0.6c0.1-0.4,0.1-1.1,0.2-1.5C118.6,22.2,119.7,22.4,120.7,22.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M96.9,18.9c0.1,0.3,0.1,0.8,0.1,1.5
    c0,0.1,0,0.1,0,0.2v1.7c0,0.5-0.1,2.1-0.4,2.8c-0.2,0.4,0,1,0.2,1.4c-1.8-0.1-3.6-0.1-5.3-0.2c0.5-0.7,1-1.9,1.1-2.7
    c0.1-0.9,0.1-1,0.1-1.3c0-0.1,0-0.2,0-0.4c0-0.6-0.1-1.4-0.4-2.5c-0.1-0.3-0.1-0.6-0.2-0.8C93.7,18.8,95.3,18.8,96.9,18.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M73.1,28.8l0.3,3.2c-1.7,0.4-3.3,0.8-4.9,1.2
    c0-0.1,0-0.1-0.1-0.2c-0.3-0.6-0.7-1.5-0.6-2c0.1-0.5-0.3-1-0.8-1.4C68.9,29.4,70.9,29,73.1,28.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M67.4,31c-0.1,0.6,0.3,1.5,0.6,2.2
    c0,0,0,0.1,0,0.1c-2.8,0.8-5.5,1.6-8,2.6c-0.1-0.8-0.5-1.7-0.7-2c-0.1-0.2-0.8-1.5-1.2-2.3c2.5-0.7,5.2-1.3,8.2-1.8
    C67,30.2,67.5,30.6,67.4,31z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M42.7,39.5c0.6,0.6,1.5,1.7,2.3,2.5
    c0.6,0.7,1,1.2,1.1,1.6c-1.8,1.6-3.5,3.4-5.1,5.5l-5.2-2.1C37.9,44,40.2,41.6,42.7,39.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M30.1,56.1c0.5,0.4,1.2,0.9,1.9,1.1
    c0.7,0.3,2.2,0.7,3.3,1c-1.4,2.8-2.6,5.8-3.7,9L26,65.5C27.3,62.1,28.7,59,30.1,56.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M23.4,66.5c-0.5-0.2-1.2-0.4-1.7-0.5
    c-0.8-0.2-1.2-0.2-2.8-0.2c-0.8,0-1.3-0.2-1.6-0.4c0.6-1.4,1.3-2.7,2-4.2c0.4,0,0.9,0.1,1.1,0.1c0.1,0,0.6,0.1,1,0.2
    c0.3,0.1,0.7,0.2,0.9,0.2c0.4,0.1,1.6,0.6,2.1,0.8l0.1,0l0.8-0.7C24.7,63.3,24,64.8,23.4,66.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M20.1,76.5c-1.1-0.3-2.7-0.6-3.1-0.7
    c-0.4,0-0.6-0.1-1.1-0.2c-0.3-0.1-0.8-0.2-1.5-0.4c-0.4-0.1-0.8-0.2-1-0.4c0.6-1.7,1.3-3.4,2.1-5.2c0.5,0.1,1.1,0.2,1.3,0.3
    c0.2,0,0.8,0.3,1.4,0.5c0.4,0.2,0.9,0.4,1.3,0.5c0.6,0.2,1.6,0.4,2.3,0.5C21.1,73,20.6,74.7,20.1,76.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M15.2,100.1c-0.3,0-0.6,0-0.9-0.1
    c-1.4-0.1-3.5-0.3-4.6-0.3c-0.8,0-1.4-0.3-1.9-0.5c0.1-1,0.2-2,0.4-2.9c0.6,0,1.5,0.1,2.2,0.1c0.8,0,1.7,0.1,2.8,0.2l0.9,0.1
    c0.6,0.1,1.2-0.1,1.6-0.2C15.5,97.7,15.3,98.9,15.2,100.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M14.6,108.6c-0.7,0-1.8,0.1-2.6,0
    c-0.8,0-1.1,0-1.6,0c-0.4,0-0.8,0.1-1.6,0.1c-1,0-1.9,0.3-2.5,0.6c0.2-1.5,0.5-3,0.7-4.7c0.6,0.1,2.3,0.2,3.6,0.3
    c1.1,0,2.9-0.4,4.1-0.8C14.7,105.6,14.7,107,14.6,108.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M7.9,113.3c0.3,0,2.3-0.4,4-0.7
    c1.3-0.2,2.1-0.4,2.6-0.6c0,1.6,0,3.2,0,4.9c-0.7,0.1-1.4,0.2-2,0.2c-0.8,0-1.2,0.1-1.8,0.2c-0.5,0.1-1.2,0.2-2.2,0.4
    c-1.3,0.2-2.5,0.1-3.3,0.1c0.1-1.2,0.3-2.5,0.5-3.8C6.3,113.7,7.6,113.3,7.9,113.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M9.7,123.3c1.4-0.2,3.7-0.8,5.1-1.2
    c0.1,1.1,0.2,2.2,0.3,3.3c-0.8,0.3-2.3,0.9-3.8,1.3c-1.4,0.4-2,0.8-2.5,1.2c-0.3,0.2-0.6,0.4-0.9,0.5c-0.6,0.1-1.7,0-2.4-0.1
    c-0.2-1.3-0.4-2.6-0.5-3.8C6.1,124.1,8.2,123.5,9.7,123.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M16.3,133.3c-0.7,0.2-2.1,0.6-3.8,1.2
    c-1,0.3-1.8,0.6-2.1,0.7c-0.6,0.2-1.9,0.4-2.6,0.6c-0.4-1-0.8-2.1-1.1-3l5.3-1.3l3.8-0.8C15.9,131.5,16.1,132.4,16.3,133.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M17.7,140.5c0,0-4.7,1.6-6.8,2.2
    c-0.6-1.1-1.1-2.2-1.5-3.2c0.8-0.3,2.9-1.1,4.9-1.6c1.2-0.3,2.2-0.7,2.8-1.1C17.3,138.1,17.7,140.5,17.7,140.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M23.9,131.6c0.3-0.3,0.6-0.5,1-0.5
    c0.4,0,0.8,0.2,1.1,0.5c0.9,2.4,1.7,4.7,2.6,6.9c-0.8,0.5-2.6,1.5-5.3,2.3c-1.4,0.5-2.2,1-2.7,1.5c-0.8-2.6-1.5-5.2-2.1-7.8
    C20.1,133.9,22.9,132.7,23.9,131.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M25.6,157.6c-0.6,0.6-1.6,1.4-2.5,2
    c-1,0.7-1.7,0.8-2,0.8c-0.4-0.7-0.9-1.4-1.3-2.2c0.5-0.4,1.7-1.4,2.7-2c0.6-0.3,1-0.9,1.3-1.5C24.4,155.8,25,156.7,25.6,157.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M30.3,163.8c-1,0.9-3.1,2.8-3.6,3.4
    c-0.2,0.3-0.6,0.5-0.9,0.5c-0.8-1.1-1.5-2.2-2.4-3.5c0.5-0.4,1.1-0.9,1.8-1.4c0.8-0.5,1.9-1.3,2.7-1.8
    C28.8,162,29.5,162.9,30.3,163.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M31.9,162.1c1.3-1.2,3.8-3.8,5.1-6.2
    c0.4-0.6,0.6-1,0.8-1.3c1.4,1.8,2.9,3.4,4.4,4.8l0.2,0.2c-0.3,0.5-1.6,2.8-3.1,4.2c-1,0.9-1.5,2.2-1.7,3.2
    C35.5,165.6,33.6,163.9,31.9,162.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.2,180.9c0.1-0.6,0.1-1.2,0-1.8
    c-0.2-0.8-0.4-1.8-0.6-2.7c2.6,0.1,5.4,0,8.3-0.1c0.1,0.6,0.1,1.6-0.3,2.9c-0.2,0.8-0.2,1.4,0,1.9C99.3,181.1,96.8,181,94.2,180.9z
    "
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M114.8,180.7l-1.5-5.3
    c2.9-0.4,5.7-0.8,8.4-1.4c0.1,0.6,0.2,1.4,0,2.3c-0.2,1.5,1,2.5,2.1,3.1C121,180,117.9,180.4,114.8,180.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M135.9,176.4c-0.8-2.3-1.5-4.6-1.8-5.8
    c2.3-0.8,4.4-1.7,6.5-2.7c0,0.1,0.1,0.1,0.1,0.2c0.4,0.7,1,1.8,1.5,3c0.4,1.1,1.4,1.6,2.1,1.8C141.7,174.2,138.9,175.4,135.9,176.4
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M152,168.2c-0.3-0.6-1.4-2.6-2.6-3.7
    c-0.4-0.4-0.6-0.7-0.7-1.1c1.6-1.1,3.2-2.2,4.8-3.4c0.7,0.7,1.9,2,2.2,2.6c0.2,0.4,0.8,0.6,1.8,0.6c0.1,0,0.3,0,0.5,0
    C156.1,165,154.1,166.7,152,168.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M162.9,157.8L162.9,157.8
    c-0.1-0.1-0.3-0.2-0.4-0.4c-0.4-0.5-1.2-1.3-1.9-1.6c-0.5-0.2-0.8-0.6-1.1-1c1.5-1.5,3-3,4.4-4.7c0.4,0.1,0.8,0.3,1.4,0.7
    c0.8,0.5,1.7,0.9,2.2,1.1C166,154,164.5,155.9,162.9,157.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M176.2,140.9c0.9,1,3.3,3.4,5.5,4.7
    c0.8,0.5,1.5,0.8,2,0.9c-0.7,1.3-1.5,2.6-2.2,3.9c-1.4-0.6-3.7-1.7-5.1-2.7c-1.4-0.9-2.3-1.9-2.8-2.4
    C174.5,143.8,175.3,142.4,176.2,140.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M180,133.6c2.6,1.4,5.1,3.3,8.1,3.3
    c-0.4,1-0.8,2.1-1.2,3.1c-1.4-0.8-4-2.2-4.9-2.6c-0.8-0.4-2.3-0.9-3.2-1.2C179.1,135.3,179.6,134.5,180,133.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M180.6,132.4c0.4-0.8,0.7-1.6,1.1-2.4
    c0.9,0.5,4,2,5.1,2.2c0.8,0.2,1.9,0.6,2.6,0.8c-0.3,0.9-0.6,1.8-0.9,2.7C185.6,135.8,183,133.7,180.6,132.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M185.5,119.6c0.9,0.5,2.7,1.5,4.3,2
    c1.1,0.4,1.9,0.4,2.4,0.3c-0.4,2-0.8,3.8-1.3,5.6c-1,0-2.6,0.1-3.2,0c-0.7-0.1-2.8-1.6-4.1-2.6
    C184.3,123.2,184.9,121.4,185.5,119.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M189.4,102.8l5.3,0.6c-0.2,3-0.5,5.9-0.9,8.9
    l-6.3,0.3C188.3,109.3,188.9,106,189.4,102.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M190.4,89.7c0.8,0.1,2.5,0.4,3,0.4
    c0.3,0,1.1-0.1,1.6-0.2c0.1,1.6,0.1,3.3,0.1,4.9l-4.9,1.7C190.3,94.3,190.4,92,190.4,89.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M191.6,79.8c-0.6,0-1.3,0.1-1.8,0.1
    c-0.1-1.3-0.3-2.5-0.5-3.8c0.5,0,1.1-0.1,1.5-0.2c0.5-0.1,1.3-0.6,2-1c0.2-0.1,0.4-0.2,0.5-0.3c0.3,1.6,0.5,3.1,0.7,4.7
    C193.4,79.5,192.2,79.8,191.6,79.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M191.1,70.1c-0.5,0.2-2,0.4-2.8,0.4
    c-0.3-1.1-0.5-2.2-0.8-3.2c0.4-0.1,0.8-0.2,1.3-0.3c0.8-0.2,1.2-0.3,1.5-0.4c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.5-0.4,0.8-0.7
    c0.4,1.3,0.7,2.6,1,4C192,69.8,191.5,69.9,191.1,70.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M188.6,61.2c-0.9,0.6-2,1-2.6,1.2
    c-0.4-1.2-0.9-2.3-1.3-3.4c0.4-0.2,1-0.4,1.4-0.6c0.6-0.2,1.7-1,2.2-1.5c0.5,1.2,1,2.4,1.4,3.6C189.4,60.7,189.1,60.9,188.6,61.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M186.2,53.7c-0.4,0.4-0.7,0.5-1,0.7
    c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-1.1,0.5-1.7,0.8c-0.7-1.4-1.5-2.8-2.3-4.2c0.5,0,1.2-0.1,1.8-0.4c0.5-0.2,0.6-0.4,0.7-0.6
    c0.1-0.1,0.1-0.2,0.3-0.2c0.4-0.1,0.7-0.5,1-0.8c0.8,1.4,1.5,2.8,2.2,4.2C186.5,53.5,186.3,53.6,186.2,53.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M184.2,49c-0.2,0.3-0.5,0.7-0.9,0.8
    c-0.3,0.1-0.4,0.3-0.5,0.4c-0.1,0.1-0.1,0.2-0.6,0.5c-0.5,0.3-1.3,0.3-1.8,0.3c-0.6-1-1.3-2-2-3c0.8-0.3,2-0.9,2.5-1.2
    c0.4-0.2,0.7-0.9,0.9-1.4C182.7,46.5,183.5,47.7,184.2,49z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M180.8,46.5c-0.5,0.3-1.8,0.9-2.5,1.2
    c-0.9-1.3-1.8-2.5-2.8-3.7c0.5-0.2,1-0.5,1.2-0.5c0.2-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.6-0.3c0.5-0.2,1-0.9,1.3-1.4
    c0.9,1.1,1.8,2.2,2.6,3.4C181.4,45.6,181.2,46.3,180.8,46.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M168.3,36.3l1.7-1.1c0.4-0.4,0.9-0.8,1.2-1.1
    c0.9,0.7,1.8,1.4,2.7,2.2c-0.5,0.8-1.1,1.5-1.2,1.6c-0.3,0.1-1.3,0.7-1.7,1C170.1,38,169.2,37.2,168.3,36.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M169.8,34.9l-1.7,1.2
    c-0.6-0.6-1.2-1.1-1.8-1.6c0.3-0.5,0.7-1.1,0.9-1.2c0.3-0.1,0.7-0.8,1-1.4c1,0.6,1.9,1.3,2.9,2C170.6,34.1,170.3,34.4,169.8,34.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M167.7,31.6c-0.3,0.6-0.6,1.2-0.8,1.2
    c-0.4,0.2-0.8,0.9-1,1.3c-0.7-0.6-1.4-1.1-2-1.7c0.3-0.4,0.8-0.9,0.9-1.2c0.1-0.2,0.4-0.7,0.6-1.1C166.2,30.7,167,31.1,167.7,31.6z
    "
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M157.1,27.6c-0.6-0.4-1.2-0.8-1.9-1.2l0.2-1.1
    c0.7,0.3,1.5,0.7,2.2,1L157.1,27.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M155.1,25.2l-0.2,1.1c-0.7-0.4-1.3-0.8-2-1.2
    l0.4-0.7C153.9,24.6,154.5,24.9,155.1,25.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M152.6,24.8c-1-0.6-2-1.1-3-1.7l0.1-0.4
    c1.1,0.5,2.2,0.9,3.3,1.4L152.6,24.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M149.3,23c-1.1-0.6-2.2-1.1-3.2-1.7
    c1.1,0.4,2.2,0.9,3.3,1.4L149.3,23z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M147.7,23.6l-0.6,5.5c-1.5-0.7-3-1.3-4.6-1.8
    l1.6-5.6C145.2,22.3,146.5,23,147.7,23.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M142.1,27.2c-0.1,0-0.1,0-0.2-0.1
    c-1.4-0.4-2.8-0.9-4.3-1.3l1.1-6.5c1.7,0.7,3.3,1.5,5,2.2L142.1,27.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M138.4,19.2l-1.1,6.5
    c-2.8-0.8-5.7-1.7-8.7-2.5l3.4-6.5C134.1,17.5,136.2,18.3,138.4,19.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M128.2,23.2c-2.8-0.7-5.6-1.5-8.5-2.1l1.8-7.6
    c3.4,0.9,6.8,1.9,10.1,3.1L128.2,23.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M118.5,12.7c0.9,0.2,1.8,0.4,2.7,0.7l-1.8,7.6
    c-1.8-0.4-3.5-0.8-5.3-1.1l1.4-7.8C116.4,12.3,117.4,12.5,118.5,12.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M113.7,19.7l-0.6-0.1
    c-1.4-0.3-2.8-0.5-4.3-0.7l-0.8-8.1c2.2,0.3,4.6,0.7,7.1,1.2L113.7,19.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M108.4,18.9c-1.8-0.3-4.7-1-4.7-1.2v-7.4
    c1.2,0.1,2.5,0.3,3.9,0.5L108.4,18.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M102.2,10.2V18c-1.2-0.1-2.3-0.2-3.5-0.3
    c0-0.5,0-1.8,0-3.3c0-1.4-0.3-3.4-0.5-4.6C99.5,9.9,100.8,10,102.2,10.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M72.4,21.5c0.3-0.1,0.6-0.2,1-0.3
    c0.2,0.3,0.7,1.1,0.8,1.6c0.2,0.7,0.3,0.9,0.3,2.3c0,0.6,0.3,1.3,0.9,1.8c0.1,0.1,0.3,0.2,0.4,0.3c-1.2,0.1-2.3,0.2-3.5,0.4
    c-0.3-1-0.8-3.3-1.1-3.7c-0.2-0.3-0.7-1.1-1.1-1.6C70.9,22,71.6,21.8,72.4,21.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M60.4,29l0.1,0.7c-0.7,0.2-1.4,0.3-2.1,0.5
    l-2.1-3.4c0.6-0.2,1.3-0.5,2-0.7L60.4,29z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M58.1,30.3c-0.6,0.2-1.2,0.3-1.7,0.5l-2.6-3.1
    c0.8-0.3,1.5-0.6,2.3-0.8L58.1,30.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M55.9,30.9c-0.6,0.2-1.1,0.4-1.7,0.5l-2.7-2.9
    c0.6-0.2,1.2-0.5,1.8-0.7L55.9,30.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M50.9,32.8c-0.6,0.3-1.2,0.6-1.8,0.8
    c-0.2,0.1-0.5,0.3-0.7,0.4l-1.2-1.6c-0.4-0.5-0.8-1.1-1.1-1.5c1-0.5,2-0.9,3-1.4L50.9,32.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M46.9,32.7l1.2,1.5c-1.3,0.7-2.6,1.6-3.9,2.5
    c-0.1-0.2-0.3-0.4-0.4-0.7l-0.4-0.6c-0.4-0.6-0.4-0.6-1.1-1.2l-0.1-0.1c-0.3-0.3-0.5-0.5-0.7-0.7c0.2-0.1,0.4-0.2,0.6-0.4
    c0,0.1,3.7,3.4,3.7,3.5c0.3,0.4,1.1-0.6,0.7-1.1c-0.1-0.1-2.9-3-3-3.1c0.8-0.4,1.5-0.8,2.3-1.2C46.2,31.7,46.7,32.4,46.9,32.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M41.1,33.6c0.2,0.2,0.5,0.5,0.8,0.8l0.1,0.1
    c0.7,0.6,0.7,0.6,1,1.2l0.4,0.6c0.2,0.2,0.3,0.5,0.4,0.7c-1,0.7-1.9,1.5-2.9,2.3c-0.3-0.7-0.9-1.7-1.2-2.3
    c-0.2-0.3-0.3-0.6-0.4-0.8c-0.2-0.3-0.3-0.6-0.5-0.8c0,0-0.1-0.1-0.1-0.1C39.5,34.6,40.3,34.1,41.1,33.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M26.9,58.3c-0.2-0.2-1-0.6-1.6-0.9
    c-0.5-0.3-0.7-0.3-1.4-0.5c-0.2,0-0.5-0.1-0.8-0.2c-0.6-0.1-1.1-0.3-1.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5c1.6-1.3,3.3-2.4,3.6-2.5
    c0.2,0.1,0.5,0.2,0.9,0.3c0.4,0.1,0.8,0.3,1.1,0.4c0.4,0.2,0.8,0.3,1.2,0.4C28.2,55.8,27.5,57,26.9,58.3z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M21.6,56.7c0.3,0.1,0.8,0.3,1.4,0.4
    c0.3,0.1,0.6,0.1,0.8,0.2c0.7,0.1,0.8,0.1,1.3,0.4c0.6,0.3,1.3,0.8,1.6,0.9l0,0.1c-0.4,0.7-0.7,1.5-1,2.3l-1.2,1.1
    c-0.4-0.2-1.7-0.7-2.1-0.8c-0.2,0-0.5-0.1-0.8-0.2c-0.6-0.2-0.9-0.2-1.1-0.2c-0.2,0-0.6,0-0.9,0C20.2,59.5,20.9,58.1,21.6,56.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M17.2,65.6c0.4,0.2,0.9,0.4,1.8,0.4
    c1.6,0,2,0.1,2.7,0.2c0.5,0.1,1.2,0.3,1.7,0.5c-0.5,1.4-1,2.8-1.5,4.2c-0.7-0.1-1.7-0.3-2.3-0.5c-0.4-0.1-0.8-0.3-1.3-0.5
    c-0.6-0.3-1.2-0.5-1.5-0.5c-0.2,0-0.8-0.2-1.2-0.3C16,68.1,16.6,66.9,17.2,65.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M13.2,75.2c0.3,0.1,0.6,0.3,1.1,0.4
    c0.7,0.2,1.2,0.3,1.5,0.4c0.5,0.1,0.8,0.2,1.2,0.2c0.4,0,2,0.4,3.1,0.7c-0.3,1.1-0.6,2.3-1,3.5c-0.8-0.7-1.8-1.5-2.2-1.6
    c-0.7-0.1-1.4-0.1-2.2-0.4c-0.6-0.2-1.7-0.4-2.4-0.5C12.5,77,12.9,76.1,13.2,75.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M11.3,80.8c2,0.7,4.1,1.1,6.3,1.1
    c0.6,0,1.7-0.9,0.6-0.9c-2.3,0-4.4-0.5-6.5-1.3c0.2-0.5,0.3-1,0.5-1.5c0.7,0.1,1.8,0.4,2.4,0.5c0.8,0.2,1.5,0.3,2.2,0.4
    c0.3,0,1.3,0.8,2.2,1.6c-0.3,1.1-0.6,2.3-0.9,3.5c-0.8-0.2-2.3-0.5-2.8-0.7c-0.8-0.2-1.5-0.3-2.4-0.4c-0.6,0-1.5-0.3-2-0.5
    C11,82.1,11.2,81.4,11.3,80.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M16.2,92.8c-1-0.1-2.6-0.3-3.2-0.3l-0.7-0.1
    c-0.7-0.1-1.2-0.1-1.7-0.1c-0.4,0-1.3-0.3-1.8-0.5C9,90.9,9.2,90,9.4,89c0.7,0.1,1.6,0.1,2.2,0.2c0.9,0.1,2,0,2.5,0.1
    c0.2,0,0.6,0.1,1.1,0.2c0.8,0.1,1.4,0.2,1.7,0.3C16.6,90.7,16.4,91.8,16.2,92.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M8.8,92.2c0.5,0.2,1.4,0.5,1.9,0.5
    c0.5,0,1,0,1.7,0.1l0.7,0.1c0.6,0,2.2,0.2,3.2,0.3c-0.2,0.9-0.3,1.9-0.5,2.9c-0.4,0.2-1,0.3-1.6,0.3l-0.9-0.1
    c-1.1-0.1-2.1-0.2-2.9-0.2c-0.6,0-1.5-0.1-2.1-0.1C8.4,94.7,8.6,93.4,8.8,92.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M7.7,99.8c0-0.1,0-0.1,0-0.2
    c0.5,0.2,1.1,0.4,1.9,0.5c1.1,0,3.2,0.2,4.6,0.3c0.4,0,0.7,0.1,0.9,0.1c-0.1,1.1-0.2,2.1-0.3,3.2c-1.2,0.4-3,0.9-4.1,0.8
    c-1.3-0.1-2.9-0.2-3.6-0.3C7.4,102.9,7.6,101.4,7.7,99.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M8,128.7c0.4-0.1,0.7-0.3,1-0.5
    c0.5-0.3,1.1-0.8,2.4-1.1c1.4-0.4,2.9-0.9,3.7-1.2c0.2,1.5,0.4,3,0.6,4.5l-3.9,0.8l-5.3,1.3c-0.4-1.3-0.7-2.5-0.9-3.7
    C6.3,128.7,7.4,128.8,8,128.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M10.5,135.5c0.3-0.1,1.1-0.3,2.1-0.7
    c1.3-0.4,3.1-1,3.8-1.2c0.2,0.9,0.4,1.8,0.6,2.7c-0.5,0.4-1.4,0.8-2.8,1.2c-2,0.5-4.2,1.3-4.9,1.6c-0.5-1.1-0.9-2.1-1.3-3.1
    C8.6,135.9,9.9,135.7,10.5,135.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M21.9,151.1c-0.3,0.2-2.2,1.7-2.9,2.5
    c-0.6,0.6-1.5,0.4-1.8,0.4l-1.8-3c0.7-0.5,2.3-1.7,3.7-2.2c0.5-0.2,1-0.5,1.4-0.8C21,149,21.4,150,21.9,151.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M19.3,153.8c0.7-0.8,2.4-2.2,2.8-2.4
    c0.5,1,1,2,1.6,3c-0.2,0.6-0.5,1.2-1.2,1.6c-1.1,0.6-2.2,1.5-2.7,2c-0.7-1.1-1.4-2.4-2.2-3.6C18,154.4,18.7,154.4,19.3,153.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M23.4,159.9c0.8-0.6,1.8-1.4,2.4-2
    c0.6,1,1.3,1.9,2.1,2.9c-0.8,0.5-2,1.3-2.7,1.8c-0.7,0.5-1.3,1-1.8,1.4c-0.6-1-1.3-2-2-3.2C21.8,160.8,22.5,160.6,23.4,159.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M27,167.4c0.4-0.6,2.6-2.5,3.5-3.4
    c0.8,0.9,1.7,1.7,2.6,2.6c-0.2,0.3-0.5,0.7-0.8,1c-0.2,0.3-0.5,0.6-0.7,0.9c-0.7,1-1.9,2.8-2.4,3.5c-1.1-1.2-2.2-2.5-3.3-4
    C26.4,167.9,26.8,167.8,27,167.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M29.6,172.4c0.4-0.7,1.7-2.6,2.4-3.6
    c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.4,0.5-0.7,0.7-1c0.5,0.5,1.1,1,1.7,1.4c-0.8,1.3-2.2,3.3-2.2,3.5c-0.1,0.1-0.8,1.2-1.5,2.2
    C30.8,173.5,30.2,172.9,29.6,172.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M33.2,171.9c0.1-0.2,1.2-2,2.2-3.4
    c0.6,0.5,1.2,0.9,1.8,1.3l-3.9,5.7c-0.6-0.4-1.1-0.9-1.7-1.3C32.1,173.5,33.1,172.1,33.2,171.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M33.6,175.8l3.9-5.7c0.3,0.2,0.5,0.3,0.8,0.5
    l-3.2,6.2C34.5,176.4,34,176.1,33.6,175.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M38.5,170.8c0.3,0.2,0.7,0.4,1,0.7l-2.5,6.5
    c-0.6-0.3-1.2-0.6-1.7-1L38.5,170.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M37.3,178.1l2.5-6.5c0.5,0.3,1.1,0.7,1.7,1
    l-2.6,6.3C38.4,178.6,37.8,178.4,37.3,178.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M41.8,172.7c0.7,0.4,1.4,0.7,2.1,1l-3,6
    c-0.6-0.2-1.1-0.5-1.7-0.7L41.8,172.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M41.2,179.9l3-6c0.3,0.2,0.7,0.3,1,0.5
    l-2.4,6.2C42.3,180.3,41.8,180.1,41.2,179.9z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M45.6,174.5c0.9,0.4,1.8,0.7,2.8,1.1l-2.6,6
    c-0.9-0.3-1.7-0.6-2.5-0.9L45.6,174.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M46,181.7l2.6-6c0.7,0.3,1.5,0.5,2.3,0.7
    c-0.3,1.2-1,3.8-1.1,4.4c-0.1,0.4-0.8,1.2-1.2,1.7C47.7,182.3,46.9,182,46,181.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M50.1,180.9c0.2-0.5,0.8-3.1,1.1-4.4
    c0.8,0.2,1.6,0.4,2.4,0.6c-0.2,1.1-0.7,3-1.2,4.8c-0.1,0.8-0.2,1.3-0.2,1.7c-1.1-0.3-2.2-0.7-3.3-1C49.4,182.2,50,181.4,50.1,180.9
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M52.6,183.8c0-0.4,0.1-1.1,0.2-1.7
    c0.7-2.6,1.1-4,1.2-4.8c0.9,0.2,1.8,0.4,2.6,0.6c-0.2,1-0.5,2.9-0.6,4.1c-0.1,1.2-0.3,2.1-0.5,2.6
    C54.5,184.3,53.6,184.1,52.6,183.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M60.9,185c0.1-1.3,0.8-4.7,1-6
    c0.6,0.1,1.1,0.2,1.7,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.5-0.2,1-0.2,1.3c0,0.3,0,1.2,0,1.9c0,0.7,0,1.3,0,1.5c0,0.3,0,1.5-0.1,2.3
    c-0.8-0.2-1.7-0.4-2.5-0.6C60.8,185.7,60.9,185.4,60.9,185z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M63.6,186.6c0-0.7,0.1-2,0.1-2.3
    c0-0.2,0-0.8,0-1.5c0-0.8,0-1.6,0-1.9c0-0.2,0.1-0.8,0.2-1.2c0-0.1,0-0.2,0.1-0.3c0.9,0.2,1.8,0.4,2.8,0.5
    c-0.2,0.4-0.2,0.9-0.2,1.3c0,0.3,0,0.7,0,0.9c-0.2,0.7-0.4,3.2-0.4,3.4c0,0.1-0.2,0.8-0.3,1.6C65.1,186.9,64.3,186.8,63.6,186.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M66.5,185.5c0-0.3,0.2-2.6,0.4-3.3
    c0.1-0.3,0.1-0.6,0.1-1c0-0.6,0-1,0.2-1.2c0.7,0.1,1.4,0.3,2.1,0.4c0,0.4,0,1.1-0.1,2.1l-0.1,0.8c-0.1,1-0.2,1.9-0.2,2.5
    c0,0.5,0.1,1.3,0.1,1.9c-0.9-0.2-1.8-0.3-2.8-0.5C66.4,186.5,66.5,185.7,66.5,185.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M72.8,186.5c0-0.3,0-0.7,0-1.2
    c0-0.6,0-1.2,0-2c0-0.9,0.1-1.9,0.2-2.4c0.4,0.1,0.8,0.1,1.3,0.2c0,0.1,0.1,0.2,0.1,0.3v3.2c0,0.1,0,0.2,0,0.3
    c-0.1,0.9-0.1,2.7,0.5,3.6c-0.8-0.1-1.7-0.2-2.5-0.4C72.6,187.7,72.8,186.9,72.8,186.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M82.6,189.5c0-1.3,0-4,0.1-4.9
    c0.1-0.8-0.1-1.7-0.3-2.3c1,0.1,2,0.2,3,0.3c0,0.5,0.2,1.3,0.3,2.2c0.2,0.9,0.3,1.9,0.4,2.6l0,0.2c0.1,1.2,0.1,1.8,0,2.1
    C84.9,189.7,83.8,189.6,82.6,189.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M86.5,187.6l0-0.2c-0.1-0.8-0.2-1.7-0.4-2.6
    c-0.1-0.7-0.2-1.5-0.3-2.1c1.4,0.1,2.7,0.2,4.1,0.3c0.1,0.4,0.2,1,0.2,1.5c-0.1,0.9,0,2.3,0,3.4c0,0.4,0,0.8,0,1
    c0,0.3,0.1,0.8,0.3,1.2c-1.3-0.1-2.6-0.1-3.9-0.2C86.6,189.4,86.5,188.7,86.5,187.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M107.4,189.4c-0.1-0.5-0.2-1.1-0.2-1.8
    c0-1-0.2-1.6-0.3-2c-0.1-0.3-0.1-0.5-0.1-0.6c0-0.3-0.1-1-0.3-1.7c2-0.1,4-0.2,6-0.4c0.4,1.5,1.1,4.4,1.6,6.7
    c-2.2,0.2-4.4,0.3-6.7,0.4C107.5,189.9,107.5,189.7,107.4,189.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M128.7,186.8c-0.2-0.4-0.5-1-0.7-1.6
    c-0.3-1.2-1.1-3.2-1.5-4.4c1.9-0.4,3.7-0.9,5.5-1.4c0.7,0.7,1.6,2.1,2.2,3.2c0.2,0.4,0.4,0.8,0.6,1.3c0.3,0.8,0.6,1.4,0.8,1.8
    c-2.1,0.6-4.3,1.2-6.6,1.7C128.9,187.3,128.8,187.1,128.7,186.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M157.2,165.7c1.8,1.4,4.3,3.3,5.8,4.5
    c-4,3-8.1,5.7-12.2,8.3l-3.5-5.6C150.9,170.8,154.2,168.4,157.2,165.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M163.3,170c-1-0.8-2.7-2.2-5.8-4.5
    c1.5-1.4,2.9-2.8,4.3-4.3c0.4-0.4,0.7-0.8,1-1.2c0.9,0.7,2.9,2.3,3.7,2.9c0.6,0.4,1.9,1.3,2.9,2C167.5,166.7,165.4,168.4,163.3,170
    z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M166.7,162.5c-0.9-0.6-2.8-2.2-3.7-2.9
    c0.9-1.1,1.8-2.2,2.7-3.3c0.8,0.6,3.6,3,4.9,3.9c0.6,0.4,1.3,1,1.8,1.6c-0.9,0.9-1.8,1.8-2.7,2.7
    C168.7,163.9,167.4,162.9,166.7,162.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M172.7,161.7c-0.6-0.6-1.3-1.2-1.9-1.6
    c-1.3-0.9-4.1-3.3-4.9-3.9c0.7-0.9,1.4-1.7,2-2.6c0.5,0.7,1.7,2.1,3.1,2.9c1.2,0.7,2.8,1.9,3.9,2.8
    C174.3,160,173.5,160.8,172.7,161.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M171.3,156.1c-1.4-0.8-2.6-2.2-3.1-2.9
    c0.9-1.2,1.8-2.5,2.7-3.7c0.4,0.6,1.2,1.5,2.1,1.9c0.5,0.2,0.9,0.5,1.4,0.7c0.7,0.4,1.6,0.8,2.7,1.4c0.8,0.4,1.3,0.7,1.8,1
    c-1.1,1.6-2.3,3-3.5,4.4C174.2,158,172.5,156.8,171.3,156.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M179,154.1c-0.4-0.3-1.1-0.7-1.8-1
    c-1.1-0.5-1.9-0.9-2.7-1.3c-0.5-0.2-0.9-0.5-1.4-0.7c-0.8-0.4-1.6-1.4-2-1.9c0.8-1.2,1.6-2.3,2.3-3.5c0.5,0.6,1.5,1.5,2.8,2.4
    c1.4,0.9,3.7,2,5.1,2.7C180.5,151.9,179.8,153,179,154.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M183.9,146.1c-0.4-0.1-1-0.3-2-0.9
    c-2.2-1.3-4.6-3.8-5.5-4.7c0.8-1.4,1.5-2.7,2.2-4.1c0.9,0.3,2.4,0.7,3.2,1.2c0.9,0.4,3.5,1.9,4.9,2.6
    C185.8,142.3,184.9,144.3,183.9,146.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M189.5,132.6c-0.8-0.3-1.9-0.6-2.6-0.8
    c-1.1-0.3-4.1-1.8-5-2.2c0.6-1.4,1.2-2.9,1.7-4.3c1.1,0.8,3.3,2.4,4.1,2.6c0.6,0.1,2.2,0.1,3.2,0
    C190.4,129.5,189.9,131.1,189.5,132.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M192.3,121.5c-0.5,0.1-1.2,0.2-2.4-0.2
    c-1.6-0.5-3.5-1.5-4.3-2c0.7-2.1,1.3-4.2,1.8-6.4l6.3-0.3c-0.3,2.1-0.6,4.1-0.9,6.1C192.7,119.6,192.5,120.6,192.3,121.5z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M189.4,102.4c0.3-1.8,0.5-3.7,0.6-5.5l4.9-1.7
    c0,2.6-0.1,5.2-0.3,7.8L189.4,102.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M176.4,38.7c-0.4,0.3-1.3,1.1-1.8,1.6
    c-0.3,0.3-0.9,0.8-1.3,1.1c-0.7-0.8-1.4-1.5-2.1-2.3c0.6-0.4,1.3-0.9,1.5-0.9c0.3-0.1,0.9-1,1.4-1.7
    C174.9,37.3,175.7,38,176.4,38.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M165,30c-0.2,0.4-0.5,0.9-0.6,1.1
    c-0.1,0.2-0.5,0.7-0.9,1.1c-1.2-1-2.5-1.9-3.7-2.8c0.1-0.4,0.3-0.8,0.5-0.9c0.2-0.2,0.3-0.5,0.4-0.8c0.8,0.4,1.6,0.8,2.4,1.2
    C163.8,29.3,164.4,29.7,165,30z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M94.5,9.7c1,0,2.2,0,3.5,0.1
    c0.2,1.1,0.5,3.2,0.5,4.6c0,1.5,0,2.8,0,3.3c-3.1-0.2-6.1-0.3-9.1-0.2c-0.6-1.6-0.9-5.6-0.8-5.9c0.1-0.2,0-0.9,0-1.8
    C90.4,9.7,92.4,9.7,94.5,9.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M68.5,19.1c0-0.1-1-4.5-1.7-7.3
    c2-0.3,5.5-0.8,9.9-1.3c-0.1,0.8,0,1.8,0.2,3.2c0.3,1.5,0.5,3.1,0.6,4.2c0,0.4,0.1,0.6,0.1,0.8c-2,0.4-3.9,0.9-5.7,1.5
    c-0.5,0.2-1,0.3-1.5,0.5C69.5,20.6,68.6,19.2,68.5,19.1z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M69.8,22.4c0.3,0.5,0.8,1.3,1.1,1.7
    c0.2,0.3,0.7,2.1,1.1,3.6c-0.7,0.1-1.4,0.2-2,0.3c-0.6-1.7-1.3-3.3-2.4-4.8C68.3,22.8,69.1,22.6,69.8,22.4z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M65.4,23.8c0.2-0.1,0.4-0.1,0.7-0.2
    c0,0,0,0,0,0.1c1,1.4,1.7,2.9,2.2,4.5c-0.9,0.1-1.7,0.3-2.5,0.4c-0.2-0.2-0.4-0.5-0.5-0.8c-0.3-0.8-0.6-1.5-0.9-1.9
    c-0.2-0.3-0.8-0.9-1.4-1.3C63.8,24.3,64.6,24,65.4,23.8z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M33.1,39.6c0.2,0.4,0.5,0.8,0.8,1
    c0.3,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.6,0.8c0.4,0.4,1.1,1.1,1.7,1.6c-0.8,0.9-1.6,1.8-2.3,2.8l-2-0.1
    c-0.9-0.3-1.9-0.7-2-0.8c-0.1-0.2-0.8-0.6-1.5-0.9C30.1,42.7,31.6,41.1,33.1,39.6z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M25.1,50.2c0.4-0.7,0.7-1.3,1.1-2
    c0.4,0.1,1.1,0.4,2.1,0.7c0.9,0.3,1.3,0.5,1.5,0.7c0.2,0.1,0.3,0.2,0.6,0.3c0.4,0.1,0.6,0.5,0.8,0.7c-0.7,1.2-1.5,2.3-2.2,3.6
    c-0.3-0.1-0.8-0.3-1.2-0.4c-0.4-0.1-0.8-0.3-1.2-0.4c-0.4-0.1-0.7-0.3-0.9-0.3c-0.1,0-0.3-0.2-3.3,2.1
    C23.3,53.5,24.1,51.9,25.1,50.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M10.7,83c0.5,0.2,1.5,0.4,2.1,0.5
    c0.9,0.1,1.5,0.2,2.3,0.4c0.6,0.2,2,0.5,2.8,0.7c-0.3,1.5-0.7,3-1,4.5c0,0.1,0,0.2-0.1,0.3c-0.2,0-1.1-0.2-1.7-0.3
    c-0.5-0.1-0.9-0.1-1.1-0.2c-0.5-0.1-1.6,0-2.5-0.1c-0.5,0-1.5-0.1-2.1-0.2C9.8,86.7,10.3,84.8,10.7,83z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M13,146.7c0.4,0,0.9-0.1,1.4-0.3
    c2.2-1,4.1-1.8,4.7-2.1c0.4,1.1,0.8,2.2,1.2,3.3c-0.3,0.3-0.8,0.5-1.4,0.8c-1.4,0.6-3,1.7-3.7,2.3l-0.3-0.6
    C14.3,148.9,13.6,147.8,13,146.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M55.9,184.7c0.1-0.5,0.4-1.5,0.5-2.7
    c0.1-1.2,0.5-3.1,0.6-4.1c0.7,0.2,1.4,0.3,2,0.5c-0.3,0.7-0.7,1.7-0.8,2.3c-0.1,0.7-0.3,3.2-0.4,4.5c-0.6-0.2-1.2-0.3-1.8-0.5
    C56,184.7,55.9,184.7,55.9,184.7z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M95,190.2c0.1-0.4,0.1-0.9-0.1-1.8
    c-0.2-0.9-0.3-1.3-0.4-1.6c-0.1-0.3-0.1-0.4-0.2-1c0-0.7-0.1-1.8-0.2-2.6c1.7,0.1,3.4,0.1,5,0.2c0,0.1,0,0.3,0,0.4c0,0.2,0,0.6,0,1
    c0,0.8,0.1,1.7,0,2.3c-0.1,0.8,0.3,2.5,0.5,3.2C98.2,190.3,96.6,190.3,95,190.2z"
      />
      <path
        :fill="color"
        fill-opacity="0"
        :stroke="color"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M147.2,180.8c-0.6,0.4-1.2,0.7-1.9,1.1
    c-0.1-1-0.5-2.3-1-3.2c-0.2-0.4-0.4-0.8-0.6-1.1c-0.4-0.8-0.9-1.6-1.1-2.1c1.5-0.7,3-1.5,4.4-2.3l3.5,5.6
    C149.4,179.4,148.3,180.1,147.2,180.8z"
      />
    </g>
  </svg>
</template>
