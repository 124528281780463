<script>
export default {
  name: 'BgScribbleCircle',

  props: {
    w: {
      type: [Number, String],
      default: null,
    },
    h: {
      type: [Number, String],
      default: null,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="scribble-circle">
      <g>
        <path
          :fill="color"
          d="M115,78.1C114.9,78,114.9,78,115,78.1c-0.6,0.3-1.1,0.6-1.6,0.9C113.9,78.7,114.4,78.4,115,78.1z M116,72
      c-0.8,0.9-1.7,1.8-2.8,2.5c0.6,0.8,1.3,1.6,2.1,2.5c1.2-0.8,2.5-1.6,3.7-2.5c-1-1-1.8-2-2.6-3C116.2,71.7,116.1,71.8,116,72z
       M112,65.2c-0.9,1.2-1.8,2.3-2.9,3.4c0.8,1.5,2,3.3,3.6,5.4c1-0.7,1.9-1.5,2.6-2.4c0.2-0.2,0.3-0.4,0.5-0.6c-1.7-2.2-3-4-3.7-5.4
      C112.2,65.4,112.1,65.3,112,65.2z M106.9,71c-2.1,2.2-4,4.3-4.9,6.6c2.4-0.2,4.6-0.7,6.6-1.5c-0.4-1.3-0.8-2.6-1.3-3.8
      C107.2,71.9,107,71.4,106.9,71z M37.7,61.8c-1.5,0-3,0.1-4.5,0.1c0.5,2.7,2.2,5.4,6.5,8.4c2.9,2,6.4,3.7,10.2,4.8
      c2.8-3.2,4.8-6.8,5.8-9.8c-2.6-1-4.4-1.5-4.4-1.5C48.9,62.6,43.6,61.8,37.7,61.8z M110,75.5c0.7-0.3,1.3-0.7,2-1.1
      c-1.5-1.9-2.7-3.7-3.5-5.1c-0.2,0.2-0.3,0.3-0.4,0.5c0.3,0.6,0.5,1.3,0.8,2C109.2,73,109.6,74.3,110,75.5z M101.7,78.3
      c-0.5,1.8-0.5,3.7,0.4,5.9c2.5-1.2,5-2.5,7.6-3.9c-0.3-1.2-0.6-2.3-0.9-3.5C106.7,77.6,104.3,78.1,101.7,78.3z M74.2,73.9
      c2.5-1.5,5-2.9,6.6-5.1c-3-3.8-4.8-8.1-6.2-12.4c-3.8,1.9-7.1,4.6-9.5,7.8c-0.8,1.1-1.6,2.2-2.3,3.3c3.6,1.7,7.5,3.9,10.9,6.7
      C73.8,74.1,74,74,74.2,73.9z M111.3,63.6c-1.8-4.4-2.9-9.7-2.8-15c-0.4,0-0.8,0-1.2,0c-0.9-0.1-1.7-0.2-2.4-0.4
      c-0.3,6.8,1,13.6,3.5,18.9C109.5,66,110.5,64.8,111.3,63.6z M81.6,60.1c-0.5-1.8-1.4-3.6-2.6-5.5c-1.3,0.4-2.6,0.9-3.8,1.5
      c1.4,4.2,3.1,8.4,6,12.1C82.3,66.2,82.7,63.7,81.6,60.1z M62.8,77.1c4.8,0,7.7-0.9,8.8-1.6c0.4-0.3,0.9-0.6,1.4-0.9
      c-3.3-2.6-7.1-4.8-10.6-6.4c-2.1,3.1-3.8,5.9-5.3,8.5C58.9,76.9,60.9,77.1,62.8,77.1L62.8,77.1z M108.6,47.2
      c0.2-3.1,0.7-6.1,1.8-8.8c-1-0.5-2-0.9-3-1.1c-1.2,3-1.9,6.3-2.2,9.6c0.7,0.2,1.5,0.3,2.4,0.4C107.9,47.1,108.2,47.2,108.6,47.2z
       M102.8,36.5c-1.1,0-2.6,0.1-4.3,0.3c2,6.1,3,8.6,5.6,9.6c0.3-3.3,1-6.5,2.2-9.5c-1-0.2-1.9-0.3-2.9-0.4
      C103.2,36.5,103,36.5,102.8,36.5z M83.3,91.5c0.9-0.3,1.7-0.7,2.6-1c2.2-0.8,4.2-1.6,5.9-2.2c1.7-0.6,3.5-1.2,5.3-1.9
      c1.2-0.5,2.4-1,3.6-1.6c0,0,0,0,0,0c-1-2.4-1-4.6-0.6-6.5c-0.1,0-0.2,0-0.3,0c-5,0-9.8-1.4-13.4-4.1c-1.1-0.8-2.1-1.6-3-2.5
      c-2,2.4-4.6,4-6.7,5.3c1.2,1.3,2.3,2.7,3.1,4.2C81.5,84.3,82.7,87.8,83.3,91.5z M95.2,55.1c-2.7-1.1-5.6-1.7-8.6-1.7
      c-1.2,0-2.3,0.1-3.5,0.3c1,1.8,1.8,3.6,2.3,5.3c1.7,5.5,0.5,9.5-1.6,12.3c0.9,0.9,1.9,1.7,3,2.5c3.5,2.5,8.2,3.9,13,3.9
      c0.2,0,0.3,0,0.5,0c1-3.1,3.4-5.7,5.9-8.2C103.1,62,99.1,56.7,95.2,55.1z M82.3,52.3c1.4-0.2,2.9-0.4,4.3-0.4
      c3.2,0,6.3,0.6,9.1,1.8c4.2,1.7,8.3,7,11.6,14.6c0.1-0.1,0.2-0.2,0.4-0.4c-2.6-5.5-4.1-12.8-3.7-20c-3.6-1.2-4.8-4.1-7-11
      c-7.1,1-16.8,3.4-21.4,6.9C78.2,46.6,80.5,49.4,82.3,52.3z M73,49.2c0.2,0.6,0.4,1.3,0.6,1.9c0.4,1.2,0.7,2.4,1.1,3.6
      c1.1-0.5,2.3-1,3.4-1.4c-1.4-2.1-3.2-4.2-5.1-6.2C72.9,47.8,72.8,48.5,73,49.2z M115.7,15.1c-2.3,0-4.5,0.4-6,1
      C98.1,20.9,94.3,23.6,97.6,34l0.2,0.7c0.2,0.5,0.3,1,0.5,1.5c1.8-0.2,3.3-0.3,4.5-0.3c0.2,0,0.5,0,0.7,0c1,0,2.1,0.2,3.1,0.4
      c0.9-2,1.9-4,3.2-5.8c3.1-4.2,8.8-8.7,14.5-12.7c-0.3-0.2-0.5-0.5-0.8-0.7C121.8,15.9,119,15.1,115.7,15.1z M36.7,83.5
      c3.7-0.6,7.1-2.6,10-5.1c-3.5-1.2-6.7-2.8-9.4-4.7c-2.4-1.7-4.2-3.4-5.5-5.1c1,5.4,2.5,10.5,4.6,15C36.6,83.6,36.7,83.6,36.7,83.5
      z M110.7,31.1c-1.2,1.7-2.2,3.5-3.1,5.4c1,0.3,2,0.7,3,1.1c0.8-2.1,1.9-4,3.2-5.7c2.8-3.6,7.8-7.5,12.9-11c-0.5-0.8-1-1.6-1.6-2.3
      C119.4,22.4,113.7,27,110.7,31.1z M111.2,95.3c0.2-3.8-0.1-8.3-1.1-12.9c-2.5,1.3-4.9,2.4-7.3,3.4
      C104.7,89.4,107.8,92.7,111.2,95.3z M35.2,48.4c-0.2,1.9-0.7,3.7-1.1,5.4c-0.6,2.7-1.2,5.2-0.9,7.5c1.5-0.1,3.1-0.2,4.6-0.2
      c6,0,11.4,0.8,13.8,2c0.2,0.1,1.9,0.6,4.4,1.5c0.3-1,0.4-1.8,0.4-2.6c0-1.7,0.2-3.2,0.3-4.6c0.5-4.2,0.8-7-4-11
      c-2.3-1.9-4.1-3.9-5.6-5.6c-1-1.1-1.8-2-2.7-2.9C39.5,40.2,35.8,43.8,35.2,48.4z M113.8,62.6c0.3,0.6,0.5,1.2,0.8,1.7
      c0.6,1.2,1.6,2.7,3,4.5c3.7-4.5,6.6-9,6.1-14C118.9,57,115.8,59.8,113.8,62.6z M119.7,75.2c-0.1-0.1-0.1-0.1-0.2-0.2
      c-1.2,0.9-2.5,1.7-3.7,2.5c0,0,0.1,0.1,0.1,0.1C117.1,76.8,118.4,76,119.7,75.2z M112.4,75c-0.7,0.4-1.4,0.8-2.2,1.2
      c0.3,1.1,0.6,2.2,0.9,3.4c1.1-0.7,2.3-1.3,3.4-2.1C113.7,76.7,113,75.8,112.4,75z M127.9,23.4c-4.8,3.3-9.4,6.9-11.9,10.2
      c-1.2,1.6-2.2,3.4-2.9,5.4c2.3,1.4,4.4,3.3,6.3,5.6c3.1-1.4,5.8-3.1,6.9-4.5c0.4-0.5,0.8-1,1.2-1.5c2.4-3,4.7-5.7,2.7-9.9
      c-0.7-1.4-1.2-2.7-1.7-3.8C128.2,24.3,128,23.8,127.9,23.4z M113.2,60.9c2.2-2.7,5.5-5.5,10.4-7.6c-0.3-1.1-0.7-2.2-1.3-3.3
      c-0.8-1.4-1.7-2.7-2.6-4c-2.7,1.1-5.6,2-8.4,2.4C111.1,52.8,111.8,57.2,113.2,60.9z M112.7,39.6c-0.8,2.3-1.3,4.8-1.5,7.3
      c2.4-0.4,5-1.1,7.4-2.1C116.9,42.7,114.9,41,112.7,39.6z M60.9,67.5c-1.4-0.6-2.6-1.2-3.8-1.6c-1,3.1-2.9,6.6-5.5,9.7
      c1.3,0.3,2.6,0.6,3.9,0.8C57.1,73.5,59,70.4,60.9,67.5z M148.6,91c-0.7-0.1-1.4-0.2-2.1-0.3c0.2,0.7,0.4,1.5,0.6,2.3
      C147.6,92.3,148.1,91.7,148.6,91z M139.2,76.4c-0.8,1.1-1.5,2.4-1.9,3.8c0.6,0.1,1.2,0.3,1.8,0.6c3.5,1.7,5.2,4,6.4,7
      c1.7,0.4,3.5,0.6,5.2,0.7c1.9-2.5,3.6-5.4,3.8-10.2c0.1-2.6,0.2-4.7,0.2-6.5c0.1-2.1,0.1-3.8,0.2-5.3
      C148.5,67.9,142.9,71.4,139.2,76.4z M138.4,82.1c-0.5-0.3-1-0.4-1.6-0.5c-0.2,0.9-0.4,1.8-0.6,2.8c2.3,1.2,4.8,2.2,7.3,2.9
      C142.6,85.1,141,83.4,138.4,82.1z M48.7,134.2c-2.1,12.2-0.3,16.9,1.2,18.7c1.8,2.2,2.3,3.8,2.7,5.1c0.1,0.2,0.1,0.4,0.2,0.5
      c-0.5-8.3,1.9-19.8,4.6-25.9C54.5,133.5,51.6,134,48.7,134.2z M39.1,120.2c0-0.9-0.1-1.7-0.1-2.6c-1.7,0.2-3.4,0.5-5.1,0.9
      c-0.2,0-0.9,0.2-2,0.6c0.1,1,0.1,2,0.2,3.2c0.1,2.6-0.2,5.1-0.7,7.5c2,0.9,3.9,1.6,5.9,2.2C38.5,128.3,39.2,124.4,39.1,120.2z
       M105.4,141.5c5,3.7,8.6,4.5,11.7,4.7c0.1,0,0.2,0,0.3,0h0c1.6,0,3.2-0.8,4.4-2.3c2.1-2.5,2.9-6.4,2.2-10.5
      c-0.3-1.8-0.5-3.4-0.7-4.8c-0.4-2.7-0.7-4.6-1.4-6.3c-2-1.3-4.2-2.8-6.6-4.2c-2.3,0.6-4.3,2.5-4.3,2.6l-0.1,0.1
      c-2.3,1.5-4.5,2.8-6.4,4.2c0.2,0.4,0.4,0.8,0.6,1.1c1.5,2.8,2,5,1.5,6.4C106.3,133.7,105.9,137.1,105.4,141.5z M156.4,66.2
      c0,1.6-0.1,3.4-0.2,5.7c-0.1,1.8-0.1,3.9-0.2,6.5c-0.1,4.7-1.7,7.8-3.4,10.2c2.9,0,5.7-0.5,8.5-1.3c-0.1-2.2-0.2-4.6-0.1-7.2
      c0.1-5.2,0.7-9.9,1.9-14.3c-0.4,0-0.9,0-1.3,0C159.7,65.7,158.1,65.9,156.4,66.2z M146.3,42.3c-0.5,2.7-1.3,5.3-2.3,7.8
      c1.4,0,2.8,0.1,4.2,0.2c4.8,0.3,7.8,7.5,8.1,11.5c0,0.1,0,0.2,0,0.3c1.7-0.3,3.4-0.4,5.2-0.4c0.9,0,1.7,0,2.6,0.1
      c2.2-6.1,5.6-11.3,10.1-15.6c1.4-1.3,3.1-2.6,5-3.7c-8.7-12-20-22-33.1-29c-0.7,0.1-1.4,0.2-2.1,0.4c0,0-0.1,0-0.2,0.1
      c0.7,1.9,1.2,3.9,1.6,6C147.7,26.1,148,33.6,146.3,42.3z M138.6,113.7c4.4-1.8,11.7-4.8,9.1-13c-0.5-1.4-0.8-2.8-1.2-4.1
      c-1.8,2.1-5.5,3.7-9.5,4.8c0,0.1,0,0.3,0.1,0.4c0.2,1.7,0.4,3.3,0.6,4.7c0.2,2.1,0,4.7-0.5,7.7C137.6,114.1,138,113.9,138.6,113.7
      z M29,61.7C28,53.4,28.2,45,29.5,38c0.2-1.2,0.6-2.4,1-3.5c-1.7,0.2-3.3,0.6-4.9,1.1C20,42.1,15.2,49.4,11.4,57.2
      c-0.3,3.6-0.2,7.3,0.4,10.6C16.2,64.5,22.5,62.6,29,61.7z M164.7,61.8c1.3,0.1,2.5,0.3,3.8,0.6c5.3,1.1,9.3,6.1,12.3,12.4
      c4.5-4.1,7.5-8.1,9.6-12c-2.9-7-6.5-13.6-10.9-19.7c-1.9,1.1-3.6,2.3-5,3.6C170.2,50.8,166.9,55.8,164.7,61.8z M145.4,165.9
      c-2.2-0.7-4.3-1.6-6-2.8c-2,10.8-12.2,21.5-19.3,27c-5.3,4.1-16.4,7-27.1,7c0,0,0,0,0,0c-4.9,0-9.4-0.6-13-1.8
      c-5.8-1.9-10.5-8.6-14.2-15.9c-1.2,0.9-2.5,1.7-3.9,2.4c1.8,4,3.8,7.7,6,11c10,3.4,20.7,5.3,31.9,5.3c10.1,0,19.9-1.5,29.1-4.4
      C134.7,188,144.3,177.5,145.4,165.9z M37,132.7c-1.9-0.6-3.9-1.3-5.8-2.2c-2.1,8.5-7.8,15.6-15.9,19.4c0.5,0.8,1,1.6,1.5,2.4
      c0.1,0,0.1,0,0.2-0.1C26.5,149.1,33.6,141.8,37,132.7z M48,124.8l0.1-0.5c0.5-1.9,3.3-4,6.4-5.8c-3.4-0.8-6.9-1.3-10.5-1.3
      c-1.2,0-2.3,0.1-3.5,0.2c0.1,0.8,0.1,1.7,0.1,2.7c0.1,4.3-0.6,8.4-1.9,12.3c2.5,0.6,5,1,7.4,1.1C46.5,130.9,47.2,128,48,124.8z
       M105.4,111.2c2.9,1.7,6.1,3.7,9.2,5.6c0.7-0.2,1.4-0.4,2.2-0.4c0,0,0,0,0,0c-3.6-2.2-7.2-4.4-10.4-6.2
      C106.1,110.5,105.7,110.9,105.4,111.2z M73.5,107.5C73.6,107.5,73.6,107.5,73.5,107.5C73.6,107.5,73.6,107.5,73.5,107.5
      C73.6,107.5,73.6,107.5,73.5,107.5z M52.5,95.3c-2.8-3.2-1.7-8.9,1.1-15.1c-1.8-0.3-3.5-0.8-5.2-1.3c-3.3,3-7.2,5.3-11.3,6
      c2.1,4.2,4.6,7.6,7.5,9.8c2.7,2,6.3,3.1,10.7,3.3C54.2,97.1,53.2,96.1,52.5,95.3z M74.2,107.9c2.2,1.1,4.3,2,6.3,2.8
      c0.7-1.8,1.3-3.7,1.7-5.5c-3.3,0.2-5.9,1-7.7,2.4C74.4,107.6,74.3,107.7,74.2,107.9z M99.2,105.7c1.6,0.7,3.8,1.8,6.3,3.2
      c1.3-1.5,2.6-3.4,3.8-5.6c0.9-1.6,1.4-3.8,1.7-6.2c-3.9-2.8-7.5-6.5-9.6-10.7c-3.3,1.4-6.4,2.5-9.1,3.5c-1.7,0.6-3.7,1.3-5.9,2.2
      c-0.9,0.4-1.9,0.7-2.9,1.1c0.4,3.5,0.3,7.1-0.3,10.7c0.8-0.1,1.7-0.1,2.6-0.1C91.7,103.7,97.8,105.2,99.2,105.7z M85.8,104.4
      c-0.7,0-1.4,0-2,0.1c0.2,0,0.3,0,0.5,0c5.5,0,10.8,1.4,11.6,1.7c2,0.8,4.9,2.4,8.2,4.3c0.3-0.3,0.7-0.6,1-1
      c-2.4-1.3-4.5-2.4-6.1-3.1C97.6,105.8,91.6,104.4,85.8,104.4z M80.1,113.3c1.1,0,2.3,0,3.5,0.1c-1-0.3-2-0.7-3-1.1
      C80.4,112.6,80.3,113,80.1,113.3z M81.1,110.9c2.4,0.9,4.5,1.6,6.2,1.9c2.2,0.4,4.7,0.8,7.2,0.8c3.5,0,6.4-0.9,8.9-2.9
      c-3.2-1.9-6-3.3-7.9-4.1c-0.7-0.3-6-1.6-11.3-1.6c-0.5,0-0.9,0-1.3,0C82.5,107.1,81.9,109,81.1,110.9z M70.5,113.6
      c2.4-0.2,5.1-0.3,8-0.3c0.3,0,0.6,0,0.9,0c0.2-0.4,0.4-0.8,0.6-1.2c-2.1-0.9-4.4-1.9-6.7-3C72.5,110.2,71.5,111.8,70.5,113.6z
       M54.4,34.2c-3.1,0.1-6.3,0.8-9.3,2c0.8,0.8,2.2,2.8,3,3.7c1.5,1.7,3.3,3.6,5.5,5.5c5.5,4.6,5.1,8,4.6,12.4
      c-0.2,1.4-0.3,2.8-0.3,4.4c0,1-0.2,2-0.5,3.1c1.2,0.5,2.6,1,4,1.7c0.8-1.2,1.7-2.4,2.5-3.6C66.4,59.9,70,57,74.1,55
      c-0.4-1.2-0.8-2.5-1.1-3.7c-0.2-0.6-0.4-1.3-0.6-1.9c-0.3-0.9-0.2-1.9,0.1-2.8C66.3,40.3,58,34.2,54.4,34.2z M121.1,72.9
      c7.4-5.6,14.2-12.7,18.6-21.2c-6.4,0.3-11.3,1.4-15.3,3c0.6,5.3-2.6,10.1-6.3,14.8C119,70.5,120,71.7,121.1,72.9z M40.9,93.3
      c0.8,0.9,1.7,1.8,2.5,2.5c2.3,1.9,5.3,3,8.8,3.5c-3.3-0.5-6.2-1.6-8.5-3.3C42.8,95.2,41.8,94.3,40.9,93.3z M82.7,91.8
      c-0.6-3.7-1.8-7.2-3.4-10.2c-0.8-1.5-1.9-2.8-3.1-4.1c-0.8,0.5-1.6,0.9-2.3,1.4c-2.1,1.5-6.2,2.3-11.1,2.3c0,0,0,0,0,0
      c-2.6,0-5.1-0.2-7.7-0.6c-3.1,6.6-3.6,11.3-1.5,13.8c1,1.1,2.4,2.4,4.2,3.7C65.9,97.7,75.2,94.6,82.7,91.8z M112.8,96.4
      c0-0.2,0.1-0.4,0.1-0.6c0.8-2.5,3.8-3.7,7.3-5c1.9-0.7,3.9-1.5,5.9-2.5c0,0,0.1-0.1,0.1-0.2c-4-2.7-7.5-5.9-10.3-8.9
      c-1.5,0.9-3,1.7-4.5,2.5c1,5,1.5,10.1,1.1,14.6C112.7,96.3,112.8,96.4,112.8,96.4z M116.4,117.9c1.7,1.1,3.4,2.2,4.9,3.2
      c-0.5-0.7-1-1.4-1.8-2.1c-0.8-0.7-1.7-1.1-2.7-1.1C116.6,117.9,116.5,117.9,116.4,117.9z M58.7,162.8c-0.7-1.8-1.3-3.3-1.7-4.2
      c-2.5-5.1-0.2-19.4,2.7-26.8c-0.5,0.2-1,0.3-1.4,0.5c-2.9,6.2-5.7,19.2-4.7,27.7C54.3,160.8,55.7,161.7,58.7,162.8z M148.1,51.7
      c-1.6-0.1-3.2-0.2-4.7-0.2c-4.3,9.5-11.9,17.2-20.4,23.2c2.5,2.4,5.4,4.8,8.6,6.9c0.4-0.3,0.8-0.6,1.3-0.8
      c0.6-2.5,1.6-4.8,3.1-6.9c4.4-6,11.2-10.1,18.9-11.7c0-0.1,0-0.3,0-0.4C154.5,58.6,151.9,52,148.1,51.7z M136,92.3
      c3.1,1.3,6.4,2.2,9.8,2.7c0-0.1,0.1-0.2,0.1-0.2c0-0.1-0.1-0.2-0.1-0.3c-0.4-1.5-0.7-2.9-1.1-4.1c-3.1-0.7-6-1.8-8.8-3.1
      C135.9,88.9,136,90.6,136,92.3z M139.7,158.2c1.6,1.4,3.5,2.5,5.7,3.4c-0.1-1.4-0.4-2.8-0.8-4.3c-1.6-5.1-2.6-9.8-3.5-13.8
      c-1.6-7.2-2.7-12.4-6.3-15.2c-0.1,0-0.1-0.1-0.2-0.2c-0.3,1.6-0.6,3.3-0.9,4.9c0.6,2,1.1,4.4,1.8,7.2c0.9,4.2,1.9,8.9,3.6,14.2
      C139.4,155.6,139.6,156.9,139.7,158.2z M167.6,66.3c-1.4-0.3-2.8-0.5-4.2-0.6c-1.2,4.3-1.8,9.1-1.9,14.2c-0.1,2.5,0,4.9,0.1,7
      c2.4-0.8,4.8-1.9,7.1-3.3c3.3-2,6.2-4.1,8.8-6.1C174.9,71.6,171.5,67.2,167.6,66.3z M145.2,95.9c-3.2-0.5-6.2-1.3-9.1-2.5
      c0.2,2.2,0.4,4.4,0.7,6.5C140.4,99,143.6,97.6,145.2,95.9z M138.3,154.6c-1.7-5.4-2.8-10.3-3.6-14.2c-0.5-2.2-0.9-4.1-1.4-5.8
      c-0.6,4.1-1,7.8-0.6,10.6c0.6,4.4,2.5,8.9,6.1,12.3C138.8,156.5,138.6,155.6,138.3,154.6z M150.3,95.8v-0.5
      c3.9,0,7.8-0.7,11.5-2.1c-0.2-1-0.4-2.1-0.5-3.2c-2.9,0.8-5.9,1.3-8.9,1.3c0,0,0,0,0,0c-0.6,0-1.3,0-1.9-0.1c0,0,0,0,0,0
      c-1.1,1.2-2.1,2.4-2.8,3.7c0,0.1,0,0.1,0.1,0.2c0.9,0.1,1.7,0.1,2.6,0.1L150.3,95.8z M72.9,170.4c-2-0.9-4.2-1.7-6.5-2.3
      c-2.2-0.6-4-1.2-5.6-1.7c1.3,3.4,2.9,7.3,4.8,11.1C68.4,175.5,70.8,173.1,72.9,170.4z M82.5,103.9c0.7-3.6,0.8-7.1,0.4-10.6
      c-3.4,1.3-7.2,2.6-11,3.7c-3.8,1.2-7.8,2.3-11.6,2.8c3.7,2.5,8.2,5.1,12.6,7.4C75.3,105.4,78.6,104.3,82.5,103.9z M60.5,180.7
      c-1.6-3.6-3.1-7.4-4.5-11c-0.8-2.1-1.5-3.9-1.9-4.8c-0.2-0.4-0.4-0.9-0.5-1.4c-2.5-1.5-3-2.8-3.6-4.7c-0.4-1.2-0.7-2.4-2.2-4.2
      c-2.9-3.5-3.5-10.4-1.8-20.4c-2.5-0.1-5-0.5-7.5-1.1c-3.5,9.5-10.9,17.1-20.8,20.6c8.2,12.5,19.2,23,32.1,30.6
      c3.6-0.6,7.2-1.8,10.4-3.4C60.3,180.9,60.4,180.8,60.5,180.7z M20.8,101.8l-0.5-0.5c-5.3-4.8-10-7.1-15-7.1
      c-0.7,0-1.4,0.1-2.2,0.1c0.2,9.3,4.2,19.1,16,28.1c0.7,0.5,1.3,1,2,1.5c3.4-3,6.8-4.7,9.2-5.7C29.5,109.8,26.7,107.2,20.8,101.8z
       M162.5,93c2.2-0.9,4.4-2,6.6-3.4c3.8-2.4,7.3-4.9,10.3-7.5c-0.2-0.6-0.5-1.3-0.7-1.9c-2.5,1.9-5.3,3.9-8.4,5.8
      c-2.7,1.6-5.4,2.9-8.2,3.8C162.1,90.9,162.3,92,162.5,93z M56.7,169.4c1.4,3.7,2.9,7.4,4.5,11c1.4-0.7,2.7-1.6,3.9-2.4
      c-2-4.1-3.7-8.3-5.1-11.8c-2.4-0.8-4.2-1.5-5.5-2.2c0.1,0.2,0.2,0.4,0.3,0.6C55.2,165.5,55.9,167.3,56.7,169.4z M33.6,117.1
      c1.7-0.4,3.5-0.8,5.3-1c-0.9-10.2-4.5-13.9-11.1-20l-0.5-0.5c-6.2-5.7-12-8.4-18.3-8.4c-1.7,0-3.5,0.2-5.4,0.6
      c-0.2,1.6-0.4,3.3-0.4,4.9c0.7-0.1,1.4-0.1,2.2-0.1c5.4,0,10.5,2.4,16,7.5l0.5,0.5c5.9,5.4,9,8.3,10,17
      C32.9,117.3,33.5,117.1,33.6,117.1z M80.1,194.6c3.5,1.2,8,1.8,12.8,1.8h0c10.5,0,21.5-2.8,26.7-6.8c7-5.5,17.3-16.2,19.1-26.9
      c-6-4.2-9.1-10.7-10-16.9c-0.7-4.7,0.3-11,1.6-17.6c-0.1-0.1-0.1-0.1-0.2-0.2c-1-0.7-3.2-2.3-6.2-4.3c0.4,1.4,0.6,2.9,0.8,4.7
      c0.2,1.4,0.4,2.9,0.7,4.7c0.8,4.5-0.1,8.9-2.5,11.7c-1.5,1.8-3.5,2.8-5.6,2.8h0c-0.1,0-0.3,0-0.4,0c-3.3-0.2-6.9-1-11.8-4.5
      c-0.9,7.4-2,17.2-4.1,27.6c-0.9,4.4-2.5,9.5-7.2,9.5c-2.8,0-6.2-1.9-10.4-4.4c-2.7-1.5-5.7-3.3-9.2-4.9c-2.2,2.9-4.9,5.6-8.1,7.9
      C69.9,186.1,74.6,192.8,80.1,194.6z M60.8,182.3c-2.9,1.4-5.9,2.5-9.1,3.2c4.8,2.7,9.8,5,15,6.8c-2-3.1-3.9-6.6-5.6-10.3
      C61.1,182.1,60.9,182.2,60.8,182.3z M169.6,90.5c-2.3,1.4-4.6,2.6-6.9,3.5c1.4,5.8,4.2,9.5,8.7,11.9c1.7,0.9,4.3,1.4,7.8,1.4
      c1.7,0,3.6-0.1,5.5-0.3c-0.6-6.8-2.3-15.9-5-23.8C176.7,85.6,173.3,88.1,169.6,90.5z M29.8,129.9c-2.7-1.3-5.3-2.9-8-4.7
      c-4.3,3.8-8.3,9.7-9.8,18.7c0.8,1.6,1.7,3.3,2.6,4.8C22.4,145,27.9,138.2,29.8,129.9z M20.6,124.4c-0.6-0.5-1.3-0.9-1.9-1.4
      c-12-9.2-16-19.1-16.3-28.5c-0.2,0-0.5,0.1-0.7,0.1c-0.1,1.8-0.1,3.6-0.1,5.4c0,14.9,3.3,29,9.3,41.7
      C12.7,133.6,16.5,128.1,20.6,124.4z M188.9,108.9c0,0.5,2.1,52.6-25.6,57.6c-2.9,0.5-5.6,0.8-8.2,0.8h0c-3.4,0-6.3-0.4-9-1.2
      c-1.2,11.1-9.8,21.2-15.8,27c37.6-12.4,65.2-46.8,67.3-88c-2.9,0.8-5.9,1.5-8.8,2C188.8,107.8,188.8,108.4,188.9,108.9z M192.4,68
      c-2.3,4.2-5.4,8.3-9.4,12.1c3.4,9.5,5.1,20.4,5.7,26.3c3-0.5,6-1.2,8.9-2c0.1-1.5,0.1-3,0.1-4.5C197.7,88.8,195.8,78,192.4,68z
       M35.2,26.1c-2.7,2.3-5.2,4.8-7.6,7.4c1.1-0.3,2.3-0.5,3.6-0.6C32.2,30.6,33.5,28.3,35.2,26.1z M155.1,163.2L155.1,163.2
      c2.3,0,4.9-0.2,7.5-0.7c20.1-3.6,23.5-33.1,22.9-46.7c0-0.5-0.7-7.7-0.8-8.2c-1.9,0.2-3.8,0.3-5.6,0.3c0,0,0,0,0,0
      c-3.6,0-6.3-0.5-8.1-1.4c-4.7-2.5-7.5-6.3-9-12.3c-3.8,1.4-7.8,2.1-11.8,2.1h0c-0.8,0-1.5,0-2.3-0.1c0.3,1.3,0.7,2.6,1.2,4.1
      c3,9.5-5.5,12.9-10,14.8c-0.6,0.2-1.1,0.4-1.5,0.6c-0.2,0.1-0.8,0.2-1,0.3c-0.4,2.6-1,5.4-1.6,8.3c-0.2,1.1-0.2,2-0.4,3.1
      c0.2,0.1,0.3,0.3,0.5,0.4c3.8,2.9,4.9,8.2,6.5,15.6c0.9,4.1,1.9,8.7,3.5,13.8c0.5,1.6,0.8,3.1,0.9,4.7
      C148.7,162.7,151.6,163.2,155.1,163.2z M142,11.8c-0.1-0.1-0.1-0.2-0.2-0.4c-0.9-0.4-1.9-0.9-2.8-1.3c-0.1,0-0.1,0.1-0.2,0.1
      c0.7,0.7,1.4,1.4,2,2.1C141.3,12.1,141.7,11.9,142,11.8z M34,32.8c0.1,0,0.2,0,0.2,0C43.6,19.2,64.5,8.6,64.7,8.5
      c0.8-0.4,1.6-0.7,2.5-1.1c-10.8,3.8-20.7,9.4-29.3,16.4c-2.4,2.9-4.5,5.9-6,9.1C32.6,32.8,33.3,32.8,34,32.8z M182,77.4
      c0.2,0.5,0.4,1.1,0.6,1.6c4-3.9,7.1-8.1,9.3-12.4c-0.1-0.2-0.1-0.4-0.2-0.5C189.4,69.8,186.3,73.6,182,77.4z M2.9,88.1
      c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,1.6-0.3,3.2-0.5,4.8C2,93,2.3,93,2.5,92.9C2.5,91.3,2.6,89.7,2.9,88.1z
       M30.2,38.1c-0.7,3.7-1.1,7.9-1.1,12.2c0.2-3,0.5-6,1.1-8.6c0.5-2.5,1.6-5,3.1-7.4c-0.7,0-1.3,0-2,0.1
      C30.8,35.6,30.4,36.9,30.2,38.1z M6.7,74.2c0.8-2.1,2.1-3.8,3.8-5.4c-0.6-2.5-0.8-5.2-0.8-8c-3.5,8.1-6,16.7-7.2,25.8
      c0.2,0,0.4-0.1,0.6-0.1C3.9,82.2,5.2,78,6.7,74.2z M30.6,122.4c0-1-0.1-1.8-0.1-2.7c-2.2,0.9-5.2,2.5-8.2,5
      c2.6,1.8,5.1,3.3,7.7,4.5C30.4,127,30.7,124.8,30.6,122.4z M64.5,139.9c-0.6,2.8,0.8,5.6,3.8,7.8l0.4,0.3
      c5.6,4.2,8.4,6.3,11.9,7.4c1.5-4.9,2.2-9.8,2.2-14.2c0-3.1-2.2-7-6-10.7C69.9,132.4,65.3,135.9,64.5,139.9z M90.4,126.1
      c-0.6-0.6-1.4-0.8-2.3-0.8c-2.8,0-6.5,2.5-8.5,3.9c-0.5,0.4-0.9,0.6-1.2,0.8c3.7,3.9,5.9,7.9,5.9,11.2c0,4.4-0.7,9.5-2.2,14.5
      c0.8,0.2,1.7,0.3,2.7,0.4c0.1,0,0.2,0,0.3,0c2.1,0,3.8-1.8,4.9-3.4c2.9-4.2,4-10.2,3.1-12.7c-0.7-2-0.7-4.1-0.6-6.2
      C92.5,130.9,92.6,128.1,90.4,126.1z M57.6,158.3c0.5,1.1,1.1,2.7,1.9,4.8c1.9,0.7,4.4,1.4,7.6,2.4c2.7,0.8,5.2,1.7,7.5,2.7
      c2.5-3.6,4.3-7.5,5.6-11.4c-3.7-1.1-6.6-3.3-12.4-7.6l-0.4-0.3c-3.5-2.6-5.1-6-4.4-9.3c0.9-4.4,5.4-8.1,12.5-10.3
      c-1.5-1.4-3.3-2.7-5.2-4c-3.2,2.6-6.5,4.6-9.8,6C57.6,138.5,55.2,153.3,57.6,158.3z M3.8,86.3C5.6,86,7.3,85.8,9,85.8
      c6.7,0,12.8,2.8,19.3,8.8l0.5,0.5c6.9,6.4,10.6,10.3,11.6,21c1.2-0.1,2.4-0.2,3.6-0.2c4.4,0,8.6,0.6,12.5,1.7
      c3.6-1.8,7.3-3.1,8.2-3.3c0.9-0.2,1.9-0.3,3.1-0.4c1.6-2.7,2.7-4.4,3.8-5.6c-4.9-2.5-9.9-5.5-13.7-8.2c-0.9,0.1-1.8,0.1-2.6,0.1
      c0,0,0,0,0,0c-5.1,0-9.2-1.3-12.4-3.8c-3.1-2.5-5.8-6.4-7.9-11c-1.8,0.2-3.3,0.3-4.7,0.3l0,0c-6.4,0-10.4-2.2-16.3-9.1
      c-1.6-1.9-2.7-4.3-3.4-7c-1.5,1.4-2.7,3-3.4,4.9C5.9,78.2,4.6,82.2,3.8,86.3z M93.9,177.6L93.9,177.6c0.9,0,3.1,0,4.5-7.3
      c2.2-10.8,3.4-20.9,4.2-28.3c0-0.2,0.1-0.4,0.1-0.7c-0.9-0.8-1.9-1.6-2.9-2.6c-2.4-2.3-3.4-4.4-3.2-6.5c0.2-2.4,2.1-4.6,5-6.8
      c-2.4-3.7-5.9-7.4-8.5-8.1c-2.5-0.7-8.2-1.2-14.5-1.2c-1.8,3-4.1,5.9-7,8.3c2.1,1.4,4,2.9,5.7,4.5c0,0,0.1,0,0.1,0
      c0.2-0.1,0.8-0.5,1.4-0.9c2.3-1.6,6.1-4.1,9.4-4.1c1.3,0,2.4,0.4,3.3,1.2c2.6,2.4,2.6,5.7,2.5,8.9c0,2-0.1,3.9,0.5,5.6
      c1.2,3.2-0.4,9.9-3.3,14.1c-1.8,2.6-3.9,4-6.1,4c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0c-1.1-0.1-2.1-0.2-3-0.4c-1.3,4-3.2,8-5.7,11.7
      c3.3,1.6,6.3,3.2,8.9,4.7C88.7,175.8,91.8,177.6,93.9,177.6z M113.8,117.1c-3-1.9-6.1-3.8-8.9-5.5c-2.7,2.3-5.8,3.4-9.5,3.5
      c2.9,1.5,6.1,5,8.4,8.5c1.9-1.3,4-2.7,6.3-4.1C110.4,119.2,111.9,117.9,113.8,117.1z M50.7,125l-0.1,0.5c-0.8,3-1.3,5.6-1.7,8
      c3-0.2,6-0.7,8.9-1.7c0.4-0.7,0.7-1.4,1-1.9c1.2-1.8,3-5,4.7-8.2c-1.7-0.7-3.4-1.4-5.1-2C54.7,121.4,51,123.7,50.7,125z
       M64.8,120.6c0.3,0.1,0.5,0.2,0.8,0.4c0.3-0.5,0.5-1,0.8-1.5c0.5-1,1.1-2,1.6-2.9c-0.4,0-0.7,0.1-1,0.1
      C66.4,117.8,65.6,119.1,64.8,120.6z M66.2,121.2c1.7,0.8,3.3,1.8,4.8,2.7c2.8-2.4,5-5.1,6.8-8c-3.3,0-6.4,0.2-9,0.4
      c-0.6,1.1-1.2,2.2-1.8,3.3C66.7,120.2,66.5,120.7,66.2,121.2z M69.6,113.6c1.1-2,2.2-3.7,3-4.9c-0.1-0.1-0.2-0.1-0.3-0.2
      c-1,1-2.1,2.7-3.6,5.2C69,113.7,69.3,113.7,69.6,113.6z M69.7,125c-1.3-0.9-2.8-1.7-4.3-2.4c-1.4,2.5-2.6,4.7-3.6,6.2
      c-0.3,0.5-0.6,1-0.9,1.7C63.9,129.1,66.9,127.3,69.7,125z M59.3,130.2c-0.2,0.3-0.5,0.7-0.7,1.2c0.5-0.2,0.9-0.4,1.4-0.5
      c0.4-1,0.8-1.8,1.3-2.5c1-1.5,2.2-3.7,3.6-6.1c-0.3-0.1-0.5-0.2-0.8-0.4C62.3,125.2,60.5,128.5,59.3,130.2z M60.3,118.7
      c1.3,0.5,2.6,1,3.9,1.6c0.7-1.3,1.4-2.5,1.9-3.5c-0.3,0-0.6,0.1-0.9,0.1C64.3,117.1,62.4,117.8,60.3,118.7z M130.9,125.6
      c-2.3-1.6-5.4-3.6-8.7-5.7c0.7,0.9,1.1,1.8,1.5,2.8c3.3,2.2,5.8,3.9,6.9,4.7C130.6,126.8,130.8,126.2,130.9,125.6z M104.2,131.7
      c0.1-0.4,0-1.8-1.3-4.3c-0.1-0.3-0.3-0.5-0.4-0.8c-2.4,2-4.1,3.8-4.3,5.7c-0.2,1.6,0.7,3.3,2.7,5.3c0.7,0.7,1.4,1.3,2.1,1.9
      C103.4,135.6,103.7,132.9,104.2,131.7z M36,32.8c0.3,0,0.6,0,0.9,0c2.1,0.2,3.7,0.7,5.2,1.6c3.9-1.9,8.3-3,12.6-3.2l0.3,0
      c4.9,0,13.3,5.3,20.1,12.1c4.8-3.7,14.6-6.1,21.7-7.1c-0.1-0.4-0.2-0.8-0.4-1.2l-0.2-0.7c-3.6-11.4,1.2-14.8,12.9-19.7
      c1.7-0.7,4.1-1.1,6.6-1.1c2.1,0,6,0.3,8.7,2.3c0.4,0.3,0.8,0.7,1.2,1c4.1-2.8,8.1-5.2,11-6.7c-1.2-1-2.4-1.8-3.7-2.6
      c-8.4-3-17.3-4.9-26.5-5.6C89,2.2,73,6.2,65.4,9.8C65.1,9.9,45.3,20.1,36,32.8z M127.9,20.1c4.3-2.9,8.6-5.4,11.6-7
      c-0.7-0.8-1.3-1.5-2.1-2.1c-2.8,1.5-6.9,3.9-11.1,6.8C126.9,18.5,127.4,19.2,127.9,20.1z M131.3,123.4c0.5-2.6,1-5.1,1.4-7.4
      c-5.2-0.9-12.3-4.9-17.4-12.5c-1.4-2-2.2-3.8-2.5-5.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,2.3-0.9,4.3-1.8,6c-1.2,2.2-2.4,4.1-3.8,5.7
      c3.6,2,7.7,4.6,11.7,7.1c0.7,0.2,1.3,0.6,1.9,1.2c0,0,0.1,0.1,0.1,0.1c4,2.5,7.7,5,10.4,6.9C131.1,124.4,131.2,123.9,131.3,123.4z
       M12,68.5c0.6,2.8,1.7,5.2,3.3,7.1c5.6,6.5,9.3,8.6,15.2,8.6h0c1.2,0,2.5-0.1,4-0.3C31.8,77.6,30,70.1,29,62.4
      C22.5,63.3,16.2,65.2,12,68.5z M143.1,14.2c-0.2,0.1-0.4,0.2-0.6,0.3c0.5,0.8,1,1.6,1.4,2.4C143.7,16,143.4,15.1,143.1,14.2z
       M35,34.3c-1.6,2.5-2.8,5.1-3.4,7.7c-0.6,2.7-0.9,5.8-1,8.9c0.2-1,0.4-2,0.6-3.1c0.7-5.5,4.5-9.7,9.4-12.6
      c-1.1-0.5-2.3-0.8-3.7-0.9C36.2,34.3,35.6,34.3,35,34.3z M141.2,15.2c-2.8,1.5-7.4,4.2-12.1,7.3c0.2,0.5,0.5,1.1,0.7,1.6
      c0.5,1.1,1,2.4,1.6,3.8c2.4,5-0.4,8.5-2.9,11.5c-0.4,0.5-0.8,1-1.2,1.5c-1.2,1.6-3.9,3.4-7.1,4.9c0.9,1.2,1.8,2.5,2.6,3.9
      c0.6,1.2,1.1,2.3,1.3,3.4c4.2-1.7,9.5-2.8,16.3-3c1.7-3.6,3-7.6,3.8-11.8c0.9-4.9,1.2-9.4,1-13.5C144.4,21.2,143.1,18,141.2,15.2z
       M126.6,101.3c2,0,4.1-0.2,6.2-0.5c-0.4-2.9-0.8-6.1-0.9-9.3c-1.5-0.8-3-1.6-4.4-2.6c-0.1,0.1-0.1,0.2-0.1,0.2l-0.1,0.2l-0.2,0.1
      c-2.1,1.2-4.4,2-6.3,2.7c-3.3,1.2-5.8,2.2-6.4,4c-0.1,0.3-0.1,0.7-0.1,1.1c3.5,2.1,7.1,3.5,9.8,3.9
      C124.8,101.3,125.7,101.3,126.6,101.3z M133.6,107c-0.1-1.4-0.3-2.9-0.6-4.6c0,0,0-0.1,0-0.1c-2.3,0.4-4.5,0.6-6.4,0.6
      c-1,0-2-0.1-2.8-0.2c-2.6-0.3-5.8-1.5-9.1-3.3c0.4,1,1,2,1.8,3.3c5.2,7.7,12,11.1,16.4,11.9C133.5,111.5,133.8,108.9,133.6,107z
       M128,88.1c1.2,0.8,2.5,1.6,3.9,2.3c0-1.8,0-3.5,0.2-5.3c-0.4-0.3-0.9-0.5-1.3-0.8C129.6,85.6,128.6,87.1,128,88.1z M145.8,30.4
      c-0.1,2.6-0.5,5.2-1,8.1c-0.7,4.2-2,8-3.7,11.6c0.4,0,0.8,0,1.2,0c1.1-2.5,1.9-5.2,2.5-8C145.7,37.8,146,34,145.8,30.4z
       M141.7,51.6c-0.4,0-0.8,0-1.2,0c-4.4,8.8-11.3,16-18.9,21.8c0.1,0.1,0.2,0.2,0.3,0.3C130.1,67.9,137.4,60.6,141.7,51.6z
       M116.8,78.7c2.8,2.9,6.1,5.9,9.9,8.6c0.6-1,1.5-2.4,2.7-3.8c-3.3-2.3-6.3-4.8-8.8-7.2C119.4,77.1,118.2,77.9,116.8,78.7z"
        />
      </g>
    </g>
  </svg>
</template>
